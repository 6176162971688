import Swal from 'sweetalert2';
import theme from '../styles/theme';
import { IShipmentReceiverInfo } from '../interfaces';

export const capitalize = (sentence: string): string => {
  if (sentence.trim().length === 0) {
    return sentence;
  }
  return sentence
    .trim()
    .split(' ')
    .filter((word) => !!word)
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(' ');
};

export function formatPrice(price: number | null) {
  if (price !== null) {
    return `$${price.toLocaleString('es-cl')}`;
  }
  return 'Asignar precio';
}

export function formatDate(date: string) {
  const dateObj = new Date(date);
  return dateObj.toLocaleString('es', {
    dateStyle: 'short',
    timeStyle: 'short',
  });
}

export function transformInput(data: {
  [key: string]: string | number | boolean | null;
}) {
  const transformedData: {
    [key: string]: string | number | boolean | null | object;
  } = {};
  Object.entries(data).forEach(([key, value]) => {
    if (typeof value === 'string' && value.trim().length === 0) {
      transformedData[key] = null;
    } else {
      transformedData[key] = value;
    }
  });
  const address = <{ address1: string }>transformedData.shippingAddress;
  if (address && !address.address1) {
    transformedData.shippingAddress = null;
  }
  const receiverInfo = transformedData.receiverInfo as IShipmentReceiverInfo;
  transformedData.receiverInfo = receiverInfo && Object.values(receiverInfo).filter((x) => !!x).length
    ? Object.entries(receiverInfo)
      .filter(([, value]) => !!value)
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {} as { [key: string]: string })
    : null;
  return transformedData;
}

export function alert(
  icon: 'warning' | 'error' | 'success' | 'info' | 'question',
  message: string
) {
  Swal.fire({
    icon,
    html: message,
    confirmButtonColor: theme.palette.primary.main,
  });
}

export function normalize(value: string) {
  return value
    .trim()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/ñ/g, 'n')
    .toLowerCase();
}

export async function confirm(message = '') {
  const { isConfirmed } = await Swal.fire({
    title: 'Confirmar',
    html: `¿Estás seguro? ${message}<br>Esta acción no se puede deshacer.`,
    icon: 'warning',
    showCancelButton: true,
    cancelButtonText: 'Cancelar',
    showConfirmButton: true,
    confirmButtonColor: theme.palette.primary.main,
    showCloseButton: false,
  });
  return isConfirmed;
}

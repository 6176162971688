import Papa from 'papaparse';
import { Dispatch } from 'react';
import languageEncoding from 'detect-file-encoding-and-language';
import { IModel } from '../interfaces/IModel';

function getRequiredColumns(
  model: IModel,
  mode: 'create' | 'update'
): string[] {
  if (mode === 'create') {
    return model.attributes
      .filter((attr) => attr.required)
      .map((attr) => attr.name);
  } else {
    // mode === 'update'
    return ['id'];
  }
}

function getOptionalColumns(
  model: IModel,
  mode: 'create' | 'update'
): string[] {
  if (mode === 'create') {
    // return model.optional;
    return model.attributes
      .filter((attr) => !attr.required)
      .map((attr) => attr.name);
  } else {
    // mode === 'update'
    return model.attributes.map((attr) => attr.name);
  }
}

function loadRows(data: any, columns: string[]) {
  const csvColumns = Object.keys(data[0]);
  const rows: {
    [key: string]: string;
  }[] = [];
  data.forEach((item: { [key: string]: string }) => {
    const row: { [key: string]: string } = {};
    csvColumns.forEach((colName: string) => {
      const modelColumn = columns.find(
        (col) => col.toLowerCase() === colName.toLowerCase()
      );
      if (modelColumn) {
        row[modelColumn as string] = item[colName];
      }
    });
    rows.push(row);
  });
  return rows;
}

export class CsvUploader {
  file: File;

  model: IModel;

  constructor(file: File, model: IModel) {
    this.file = file;
    this.model = model;
  }

  async validate(
    handleModal: (title: string, text: string) => void,
    setRows: Dispatch<
      React.SetStateAction<
        {
          [key: string]: string;
        }[]
      >
    >,
    mode: 'create' | 'update'
  ) {
    const columnsShouldHave = getRequiredColumns(this.model, mode);
    const columnsCanHave = getOptionalColumns(this.model, mode);
    const fileInfo = await languageEncoding(this.file);
    Papa.parse(this.file, {
      header: true,
      encoding: fileInfo.encoding === 'UTF-8' ? 'UTF-8' : 'ISO-8859-1',
      skipEmptyLines: true,
      complete(results) {
        const csvColumns = Object.keys(results.data[0] as string);
        const missingCols: string[] = [];
        columnsShouldHave?.forEach((colName: string, idx: number) => {
          if (
            !csvColumns.find(
              (item: string) => item.toLowerCase() === colName.toLowerCase()
            )
          ) {
            missingCols.push(columnsShouldHave[idx]);
          }
        });
        if (missingCols.length > 0) {
          const strMissingCols = missingCols.join(', ');
          handleModal(
            'Error: the uploaded CSV is incorrect',
            `The following columns are missing: ${strMissingCols}, please check them.`
          );
        } else {
          const extraCols: string[] = [];
          const optionalCsvColumns = csvColumns.filter(
            (colName: string) =>
              !columnsShouldHave.find(
                (item: string) => item.toLowerCase() === colName.toLowerCase()
              )
          );
          optionalCsvColumns.forEach((colName: string) => {
            if (
              !columnsCanHave.find(
                (item: string) => item.toLowerCase() === colName.toLowerCase()
              )
            ) {
              extraCols.push(colName);
            }
          });
          if (extraCols.length > 0) {
            const strExtraCols = extraCols.join(', ');
            handleModal(
              'Error: the uploaded CSV is incorrect',
              `The following columns are not valid ones: ${strExtraCols}, please check them.`
            );
          } else {
            setRows(
              loadRows(results.data, [...columnsShouldHave, ...columnsCanHave])
            );
          }
        }
      },
    });
  }
}

import { IModel } from '../../interfaces';

export const sizes: IModel = {
  name: 'Sizes',
  path: 'api/sizes',
  attributes: [
    {
      name: 'name',
      type: 'string',
      required: true,
    },
    {
      name: 'length',
      type: 'number',
      required: true,
    },
    {
      name: 'depth',
      type: 'number',
      required: true,
    },
    {
      name: 'height',
      type: 'number',
      required: true,
    },
    {
      name: 'weight',
      type: 'number',
      required: true,
    },
  ],
};

import { useContext, useEffect, useState } from 'react';
import { IClient, IPointOfSale } from '../../interfaces';
import { GlobalContext } from '../../store';
import ManagePOSLocals from './ManagePOSLocals';
import { capitalize } from '../../lib/utils';

const POSLocals = () => {
  const { api } = useContext(GlobalContext);

  const [clients, setClients] = useState<IClient[]>([]);
  const [client, setClient] = useState<IClient | null>(null);
  const [pos, setPos] = useState<IPointOfSale[]>([]);
  const [pointOfSale, setPointOfSale] = useState<IPointOfSale | null>(null);

  // Context
  const { context } = useContext(GlobalContext);
  const { startLoading, finishLoading } = context;

  // Load Clients on page load
  useEffect(() => {
    // Set clients and default client
    startLoading();
    api
      .getClients({ active: true })
      .then((response) => {
        setClients(response);
      })
      .finally(() => finishLoading());
  }, []);

  // Load POS on Client selection
  useEffect(() => {
    if (!client) return;
    startLoading();
    api
      .getPOS(client.id)
      .then((response) => {
        setPos(response);
      })
      .finally(() => finishLoading());
  }, [client]);

  const renderForm = () => {
    if (!client || !pointOfSale) return;
    return <ManagePOSLocals client={client} pointOfSale={pointOfSale} />;
  };

  return (
    <div className="Onboarding">
      <div className="Title">POS Locals</div>
      <form className="OnboardingForm">
        <div className="InputWrapper">
          <div className="InputTitle">Cliente</div>
          <select
            name="client"
            onChange={(e) => setClient(clients[parseInt(e.target.value, 10)])}
          >
            <option value="">Seleccionar...</option>
            {clients.map((clientOption, idx) => (
              <option value={idx} key={clientOption.id}>
                {capitalize(clientOption.name)}
              </option>
            ))}
          </select>
        </div>
        {!!client && (
          <div className="InputWrapper">
            <div className="InputTitle">POS</div>
            <select
              name="pos"
              onChange={(e) =>
                setPointOfSale(pos[parseInt(e.target.value, 10)])
              }
            >
              <option value="">Seleccionar...</option>
              {pos.map((pointOfSale, idx) => (
                <option value={idx} key={pointOfSale.id}>
                  {capitalize(pointOfSale.name)}
                </option>
              ))}
            </select>
          </div>
        )}
        {renderForm()}
      </form>
    </div>
  );
};

export default POSLocals;

import {
  DateField,
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from 'react-admin';
import { transformInput } from '../../../lib/utils';
import { JsonInput } from 'react-admin-json-view';

export const ConnectionEdit = () => (
  <Edit transform={transformInput}>
    <SimpleShowLayout>
      <TextField source="id" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </SimpleShowLayout>
    <SimpleForm>
      <TextInput source="model" />
      <TextInput source="postAlias" />
      <ReferenceInput
        source="ConnectionTypeId"
        reference="connection-types"
        sort={{ field: 'name', order: 'ASC' }}
        perPage={10}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <JsonInput
        source="routeToOrigin"
        jsonString={false}
        defaultValue={null}
        reactJsonOptions={{
          name: null,
          collapsed: true,
          enableClipboard: false,
          displayDataTypes: true,
        }}
      />
      <JsonInput
        source="routeToModel"
        jsonString={false}
        defaultValue={null}
        reactJsonOptions={{
          name: null,
          collapsed: true,
          enableClipboard: false,
          displayDataTypes: true,
        }}
      />
      <JsonInput
        source="hasuraRoute"
        jsonString={false}
        defaultValue={null}
        reactJsonOptions={{
          name: null,
          collapsed: true,
          enableClipboard: false,
          displayDataTypes: true,
        }}
      />
    </SimpleForm>
  </Edit>
);

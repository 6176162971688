import { Skeleton } from '@mui/material';
import { useEffect } from 'react';
import { IData } from '../../interfaces';

interface DataFieldProps<T> {
  data: T | null;
  dataKey: keyof T;
  getData: () => Promise<T>;
  setData: (data: any) => void;
}

const DataField = <T extends IData>({
  data,
  dataKey,
  getData,
  setData,
}: DataFieldProps<T>) => {
  useEffect(() => {
    (async () => {
      setData(await getData());
    })();
  }, []);

  if (!data) {
    return (
      <p className="Grow">
        <Skeleton />
      </p>
    );
  }

  return <p>{data[dataKey] as string}</p>;
};

export default DataField;

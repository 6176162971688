import * as React from 'react';
import { Menu, MenuItem, IconButton } from '@mui/material';
import { IOrderDetail } from '../../interfaces/IOrder';
import { Dispatch, SetStateAction } from 'react';
import {
  MoreVert,
  UnfoldLess,
  UnfoldMore,
  Delete,
  Add,
  Edit,
} from '@mui/icons-material';

interface OptionMenuProps {
  item: IOrderDetail;
  onDelete?: (orderDetail: IOrderDetail) => void;
  expand?: () => void;
  expanded?: boolean;
  add?: Dispatch<SetStateAction<boolean>>;
  note?: (id: number) => void;
}

const OptionsMenu = (props: OptionMenuProps) => {
  const { item, expand, expanded, add, note, onDelete } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleDelete() {
    if (!onDelete) {
      return;
    }
    return (
      <MenuItem
        onClick={() => {
          onDelete(item);
          handleClose();
        }}
      >
        <Delete />
        Eliminar
      </MenuItem>
    );
  }

  function handleExpand() {
    if (!expand) {
      return;
    }
    return (
      <MenuItem
        onClick={() => {
          expand();
          handleClose();
        }}
      >
        {expanded ? <UnfoldLess /> : <UnfoldMore />}
        {expanded ? 'Ocultar' : 'Expandir'}
      </MenuItem>
    );
  }

  function handleAdd() {
    if (!add) {
      return;
    }
    return (
      <MenuItem
        onClick={() => {
          add(true);
          handleClose();
        }}
      >
        <Add />
        Agregar producto a pack
      </MenuItem>
    );
  }

  function handleNote() {
    if (!note) {
      return;
    }
    return (
      <MenuItem
        onClick={() => {
          note(item.id as number);
          handleClose();
        }}
      >
        <Edit />
        Nota del producto
      </MenuItem>
    );
  }

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {handleDelete()}
        {handleExpand()}
        {handleAdd()}
        {handleNote()}
      </Menu>
    </div>
  );
};

export default OptionsMenu;

import { Create, NumberInput, SimpleForm } from 'react-admin';
import { transformInput } from '../../../lib/utils';

export const PackComponentCreate = () => (
  <Create transform={transformInput}>
    <SimpleForm>
      <NumberInput source="PackId" label="Pack Id" />
      <NumberInput source="ComponentId" label="Component Id" />
      <NumberInput source="amount" />
    </SimpleForm>
  </Create>
);

import {
  Create,
  DateTimeInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
} from 'react-admin';
import { transformInput } from '../../../lib/utils';
  
export const JourneyCreate = () => {

  return (
    <Create transform={transformInput}>
      <SimpleForm>
        <ReferenceInput source="DriverId" reference="drivers">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <DateTimeInput source="startTime" />
        <DateTimeInput source="finishTime" />
        <ReferenceInput source="LocalId" reference="locals" perPage={10000} filter={{ active: true }}>
          <SelectInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
  
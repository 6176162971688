import {
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { transformInput } from '../../../lib/utils';

export const ClientLocalCreate = () => (
  <Create transform={transformInput}>
    <SimpleForm>
      <ReferenceInput
        source="LocalId"
        reference="locals"
        sort={{ field: 'name', order: 'ASC' }}
        filter={{ active: true }}
        perPage={10000}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        source="ClientId"
        reference="clients"
        sort={{ field: 'name', order: 'ASC' }}
        perPage={10000}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="pickerUrl" />
    </SimpleForm>
  </Create>
);

import { Flex, Link, useAuthenticator, useTheme } from '@aws-amplify/ui-react';

const LoginFooter = () => {
  const { toResetPassword } = useAuthenticator();
  const { tokens } = useTheme();

  return (
    <Flex justifyContent="center" padding={`0 0 ${tokens.space.medium}`}>
      <Link onClick={toResetPassword}>Recuperar contraseña</Link>
    </Flex>
  );
};

export default LoginFooter;

import { capitalize } from '../lib/utils';
import { Link } from 'react-router-dom';

interface CardProps {
  link: string;
  name: string;
  imageUrl: string;
  onKeyDown?: boolean;
}

const LocalCard = ({ name, imageUrl, link, onKeyDown }: CardProps) => {
  function handleKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.key === 'Enter') {
      const link = e.currentTarget.querySelector('a');
      if (link) {
        link.click();
      }
    } else if (['ArrowDown', 'ArrowUp'].includes(e.key)) {
      const sibling =
        e.key === 'ArrowDown'
          ? (e.currentTarget.nextSibling as HTMLDivElement)
          : (e.currentTarget.previousSibling as HTMLDivElement);
      if (sibling) {
        sibling.focus();
      }
      e.preventDefault();
    }
  }

  return (
    <div
      className="LocalCard"
      tabIndex={onKeyDown ? 0 : -1}
      onKeyDown={handleKeyDown}
      onMouseMove={(e) => e.currentTarget.focus()}
    >
      <Link to={link} className="Link" tabIndex={-1}>
        <div className="Row Start">
          <img src={imageUrl} alt={name} className="LocalCardChildren" />
          <div className="LocalCardChildren">{capitalize(name)}</div>
        </div>
      </Link>
    </div>
  );
};

export default LocalCard;

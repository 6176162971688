import { useContext, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import Loading from '../../components/Loading';
import { ILocal, SortProductLocation, ISearch } from '../../interfaces';
import {
  IProductLocation,
  IFetchProductLocationsOptions,
} from '../../interfaces/IProductLocation';
import ProductLocationsTable from '../../components/ProductLocations/ProductLocationsTable';
import Back from '../../components/Back';
import { GlobalContext } from '../../store';
import Search from '../../components/Search';
import ClientSearch from '../../components/Losses/ClientSearch';

const initialSort: SortProductLocation = { by: 'sku', order: 'asc' };
const initialPageSize = 10;
const initialPage = 0;

type ContextType = { local: ILocal };

const ProductLocations = () => {
  const { api } = useContext(GlobalContext);
  const { local } = useOutletContext<ContextType>();
  const [productLocations, setProductLocations] = useState<IProductLocation[]>(
    []
  );
  const [productLocationCount, setProductLocationCount] = useState<number>(0);
  const [productLocationsFetchOptions, setProductLocationsFetchOptions] =
    useState<IFetchProductLocationsOptions>({
      sort: initialSort,
      rowsPerPage: initialPageSize,
      page: initialPage,
      LocalId: local.id,
    });
  const [search, setSearch] = useState<ISearch>({
    input: null,
    type: 'productName',
    fields: ['productName', 'locationName', 'sku'],
  });
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchProductLocationsGraphql();
  }, [productLocationsFetchOptions]);

  async function fetchProductLocationsGraphql() {
    if (!productLocationsFetchOptions) {
      return;
    }
    setLoading(true);
    const result = await api.getProductLocationsGraphql(
      productLocationsFetchOptions
    );
    setProductLocations(result.productLocations);
    setProductLocationCount(result.count);
    setLoading(false);
  }

  function handleUpdateSearch(newValue: { [key: string]: string }) {
    setProductLocationsFetchOptions({
      ...productLocationsFetchOptions,
      search: newValue,
    });
  }

  async function handleChangeRequest(
    sort: SortProductLocation,
    rowsPerPage: number,
    page: number
  ) {
    setProductLocationsFetchOptions({
      ...productLocationsFetchOptions,
      sort,
      rowsPerPage,
      page,
    });
  }

  function handleUpdateClientsFilter(newValue: string[]) {
    setProductLocationsFetchOptions({
      ...productLocationsFetchOptions,
      ClientId: newValue.length === 1 ? newValue : undefined,
    });
  }

  return (
    <>
      <div className="OrdersToolbar">
        <div className="Left">
          <Back home />
        </div>
      </div>
      <ProductLocationsTable
        rows={productLocations}
        totalRowCount={productLocationCount}
        onChangeRequest={handleChangeRequest}
        initialSort={initialSort}
        initialPageSze={initialPageSize}
        initialPage={initialPage}
        local={local}
        onRefresh={fetchProductLocationsGraphql}
      >
        <ClientSearch onChange={handleUpdateClientsFilter} />
        <Search
          onUpdateSearch={handleUpdateSearch}
          search={search}
          setSearch={setSearch}
        />
        <>
          {loading && (
            <div>
              <Loading />
            </div>
          )}
        </>
      </ProductLocationsTable>
    </>
  );
};

export default ProductLocations;

export const ProductLocationSorting = ({
  by = 'sku',
  order = 'desc',
}: {
  by?: string;
  order?: string;
}) => {
  if (by === 'sku') {
    return {
      sorting: [
        {
          Product: {
            sku: order,
          },
        },
      ],
    };
  }
  if (by === 'productName') {
    return {
      sorting: [
        {
          Product: {
            name: order,
          },
        },
      ],
    };
  }
  if (by === 'locationName') {
    return {
      sorting: [
        {
          Location: {
            name: order,
          },
        },
      ],
    };
  }
  if (by === 'onHand') {
    return {
      sorting: [
        {
          onHand: order,
        },
      ],
    };
  }
  if (by === 'expirationDate') {
    return {
      sorting: [
        {
          Lot: {
            expirationDate: order,
          },
        },
      ],
    };
  }
  return { sorting: [] };
};

export const TransferOrdersSorting = ({
  by = 'id',
  order = 'desc',
}: {
  by?: string;
  order?: string;
}) => {
  if (by === 'id') {
    return {
      sorting: [
        {
          id: order,
        },
      ],
    };
  }
  if (by === 'clientName') {
    return {
      sorting: [
        {
          Order: {
            Client: {
              name: order,
            },
          },
        },
      ],
    };
  }
  if (by === 'OrderId') {
    return {
      sorting: [
        {
          Order: {
            id: order,
          },
        },
      ],
    };
  }
  if (by === 'ReceptionId') {
    return {
      sorting: [
        {
          Reception: {
            id: order,
          },
        },
      ],
    };
  }
  if (by === 'type') {
    return {
      sorting: [
        {
          type: order,
        },
      ],
    };
  }
  if (by === 'date') {
    return {
      sorting: [
        {
          Order: {
            date: order,
          },
        },
      ],
    };
  }
  return { sorting: [] };
};

import { Edit, NumberInput, SimpleForm, TextInput } from 'react-admin';
import { transformInput } from '../../../lib/utils';

export const CountryCreate = () => (
  <Edit transform={transformInput}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="phoneCode" />
      <TextInput source="code" />
      <NumberInput source="messageFee" />
      <NumberInput source="uberDirectFeeBasePrice" />
      <NumberInput source="uberDirectRegularMaxPrice" />
    </SimpleForm>
  </Edit>
);

import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
  TextInput,
} from 'react-admin';
import { AdminPagination } from '../AdminLayout';

const regionFilters = [<TextInput label="Name" source="name"></TextInput>];

export const RegionList = () => (
  <List filters={regionFilters} pagination={<AdminPagination />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <ReferenceField source="CountryId" reference="countries">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);

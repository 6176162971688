import {
  ArrayInput,
  BooleanInput,
  Create,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';

export const PaymentCreate = () => (
  <Create>
    <SimpleForm>
      <ReferenceInput
        source="clientId"
        reference="clients"
        perPage={1000}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <SelectInput
        source="status"
        choices={[
          { id: 'pending', name: 'pending' },
          { id: 'validating', name: 'validating' },
          { id: 'validated', name: 'validated' },
        ]}
        defaultValue={'pending'}
      />
      <NumberInput source="totalPrice" />
      <NumberInput source="transferPrice" />
      <TextInput source="emissionDate" />
      <TextInput source="expirationDate" />
      <ArrayInput source="documents" defaultValue={[]}>
        <SimpleFormIterator>
          <BooleanInput source="" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);

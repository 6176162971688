import { ChangeEvent, useContext, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { IOrder } from '../../interfaces';
import EditButtons from '../../components/EditButtons';
import { GlobalContext } from '../../store';
import { alert } from '../../lib/utils';
import { confirm } from '../../lib/utils';
import CustomInputField from '../../components/Order/CustomInputField';

type ContextType = { order: IOrder };

const OrderFinishShipment = () => {
  const { api, context } = useContext(GlobalContext);
  const { order } = useOutletContext<ContextType>();

  const [finishReason, setFinishReason] = useState<string>('');
  const navigate = useNavigate();

  const handleFinishShipment = async () => {
    if (!(await confirm('Vas a <b>finalizar</b> el envío.'))) {
      return;
    }
    if (!order.activeShipment) return;
    context.startLoading();
    try {
      await api.finishShipment(order.activeShipment.id, finishReason);
      navigate('../');
      window.location.reload();
    } catch (error) {
      if (error instanceof Error) {
        alert('error', 'Se ha producido un error al intentar finalizar el envío');
      }
    }
    context.finishLoading();
  };

  return (
    <>
      <div className="FormBorder">
        <h2 className="Row Center">Finalizar Envío</h2>
        <div className="OrderContainer Center">
          <form>
            <CustomInputField
              inputType="select"
              editMode={true}
              value={finishReason}
              label="Selecciona la razón para finalizar el envío"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setFinishReason(e.target.value)
              }
              selectOptions={[
                {
                  value: 'returned',
                  label: 'Devuelto (ya fue enviado)',
                  disabled: false,
                },
                {
                  value: 'cancelled',
                  label: 'Cancelado (no ha sido enviado todavía)',
                  disabled: false,
                },
                {
                  value: 'lost',
                  label: 'Perdido',
                  disabled: false,
                },
                {
                  value: 'broken',
                  label: 'Roto',
                  disabled: false,
                },
              ]}
            />
            <EditButtons
              editMode
              onCancel={() => navigate('../')}
              onSave={handleFinishShipment}
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default OrderFinishShipment;

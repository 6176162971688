import { Box, CircularProgress } from '@mui/material';

const Loading = () => (
  <div className="Container Loading">
    <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
  </div>
);

export default Loading;

import { IModel } from '../../interfaces';

export const orders: IModel = {
  name: 'Orders',
  path: 'api/orders',
  attributes: [
    {
      name: 'sku',
      type: 'string',
      required: true,
    },
    {
      name: 'amount',
      type: 'string',
      required: true,
    },
    {
      name: 'price',
      type: 'number',
      required: true,
    },
    {
      name: 'discount',
      type: 'number',
      required: true,
    },
  ],
};

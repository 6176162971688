import { IModel } from '../../interfaces';

export const paymentOrders: IModel = {
  name: 'PaymentOrders',
  path: 'billing/payments',
  attributes: [
    {
      name: 'status',
      type: 'string',
      required: true,
    },
    {
      name: 'totalPrice',
      type: 'number',
      required: true,
    },
    {
      name: 'transferPrice',
      type: 'number',
      required: true,
    },
    {
      name: 'clientId',
      type: 'number',
      required: true,
    },
    {
      name: 'emissionDate',
      type: 'string',
      required: true,
    },
    {
      name: 'expirationDate',
      type: 'string',
      required: true,
    },
  ],
};

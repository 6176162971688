import {
  BooleanInput,
  DateField,
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from 'react-admin';
import { transformInput } from '../../../lib/utils';

export const PointOfSaleEdit = () => (
  <Edit transform={transformInput}>
    <SimpleShowLayout>
      <TextField source="id" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </SimpleShowLayout>
    <SimpleForm>
      <TextInput source="name" />
      <ReferenceInput source="IntegrationId" reference="integrations">
        <SelectInput optionText="name" />
      </ReferenceInput>

      <ReferenceInput
        source="ClientId"
        reference="clients"
        sort={{ field: 'name', order: 'ASC' }}
        perPage={10000}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput source="CountryId" reference="countries">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <BooleanInput source="hasExpressTab" />
      <ReferenceInput
          source="principalLocalId"
          reference="locals"
          sort={{ field: 'name', order: 'ASC' }}
          filter={{ active: true }}
          perPage={1000}
        >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        source="OrderTypeId"
        reference="order-types"
        filter={{ id: [1,2,3,4,6] }}
        sort={{ field: 'name', order: 'ASC' }}
        perPage={10000}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

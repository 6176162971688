import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';
import { IAttribute } from '../interfaces';

function toDataType(value: string, type: string) {
  if (value.toLowerCase() === 'null') {
    return null;
  }
  switch (type) {
    case 'number':
      return Number(value);
    case 'boolean':
      return value.toLowerCase() === 'true';
    default:
      return value;
  }
}

export function parseAttributes(
  attributes: IAttribute[],
  data: {
    [key: string]: string;
  }[]
) {
  return data.map((item) => {
    const parsedItem: {
      [key: string]: string | number | boolean | null;
    } = {};
    Object.keys(item).forEach((key) => {
      const attribute = attributes.find((attribute) => attribute.name === key);
      if (attribute) {
        parsedItem[key] = toDataType(item[key], attribute.type);
      }
      if (key === 'id') {
        parsedItem[key] = item[key];
      }
    });
    return parsedItem;
  });
}

export async function uploadFileToS3(
  json: string,
  key: string
): Promise<string> {
  const s3 = new S3Client({
    region: process.env.REACT_APP_AWS_REGION,
    credentials: {
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID || '',
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY || '',
    },
  });
  const params = {
    Bucket: process.env.REACT_APP_FUNCTIONS_BUCKET_NAME || '',
    Key: key,
    Body: json,
  };
  try {
    await s3.send(new PutObjectCommand(params));
    return 'Success';
  } catch (error) {
    return `Error: ${error}`;
  }
}

import {
    Datagrid,
    DateField,
    List,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    TextField,
  } from 'react-admin';
  import { AdminPagination } from '../AdminLayout';
  
  const clientCourierFilters = [
    <ReferenceInput
      source="ClientId"
      reference="clients"
      sort={{ field: 'name', order: 'ASC' }}
      perPage={10000}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>,
    <ReferenceInput
      source="CourierId"
      reference="couriers"
      sort={{ field: 'name', order: 'ASC' }}
      perPage={10000}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>,
  ];
  
  export const ClientCourierList = () => (
    <List filters={clientCourierFilters} pagination={<AdminPagination />}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <ReferenceField source="ClientId" reference="clients">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="CourierId" reference="couriers">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="clientBaseFee" />
        <TextField source="clientExtraFee" />
        <TextField source="courierBaseFee" />
        <TextField source="courierExtraFee" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
      </Datagrid>
    </List>
  );
  
import { ChangeEvent, useContext, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { IOrder } from '../../interfaces';
import EditButtons from '../../components/EditButtons';
import { GlobalContext } from '../../store';
import { alert } from '../../lib/utils';
import { confirm } from '../../lib/utils';
import CustomInputField from '../../components/Order/CustomInputField';

type ContextType = { order: IOrder };

const OrderCancel = () => {
  const { api, context } = useContext(GlobalContext);
  const { order } = useOutletContext<ContextType>();

  const [cancelReason, setCancelReason] = useState<string>('');
  const [cancelShipmentReason, setCancelShipmentReason] = useState<string>('');
  const navigate = useNavigate();

  const handleOrderCancel = async () => {
    if (!(await confirm('Vas a cancelar la orden.'))) {
      return;
    }
    context.startLoading();
    try {
      await api.cancelOrder(order.id, cancelReason, cancelShipmentReason);
      navigate('../');
      window.location.reload();
    } catch (error) {
      if (error instanceof Error) {
        alert('error', error.message);
      }
    }
    context.finishLoading();
  };

  return (
    <>
      <div className="FormBorder">
        <h2 className="Row Center">Cancelar Orden</h2>
        <div className="OrderContainer Center">
          <form>
            <CustomInputField
              inputType="select"
              editMode={true}
              value={cancelReason}
              label="Motivo cancelación Pedido"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setCancelReason(e.target.value)
              }
              selectOptions={[
                {
                  value: 'client-request',
                  label: 'Solicitud del Cliente',
                  disabled: false,
                },
                {
                  value: 'pickup-expired',
                  label: 'Pickup expirado',
                  disabled: false,
                },
                {
                  value: 'product-stock',
                  label: 'Falta de stock',
                  disabled: false,
                },
                {
                  value: 'internal-cancel',
                  label: 'Cancelación interna',
                  disabled: false,
                },
              ]}
            />
            <CustomInputField
              inputType="select"
              editMode={true}
              value={cancelShipmentReason}
              label="Motivo cancelación Envío"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setCancelShipmentReason(e.target.value)
              }
              selectOptions={[
                {
                  value: 'returned',
                  label: 'Devuelto',
                  disabled: false,
                },
                {
                  value: 'cancelled',
                  label: 'Cancelado',
                  disabled: false,
                },
              ]}
            />
            <EditButtons
              editMode
              onCancel={() => navigate('../')}
              onSave={handleOrderCancel}
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default OrderCancel;

import { Chip } from '@mui/material';
import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  Pagination,
  PaginationProps,
  TextField,
} from 'react-admin';

const PaymentPagination = (props: PaginationProps) => (
  <Pagination rowsPerPageOptions={[50, 100, 200, 500, 1000]} {...props} />
);

export const PaymentList = () => (
  <List pagination={<PaymentPagination />}>
    <Datagrid rowClick="edit">
      <FunctionField
        label="id"
        render={(r: { id: string }) =>
          r.id.toUpperCase().slice(r.id.length - 5, r.id.length)
        }
      />
      <TextField source="clientId" />
      <TextField source="status" />
      <TextField source="totalPrice" />
      <TextField source="transferPrice" />
      <FunctionField
        label="documents"
        render={(r: { documents: string[] }) => (
          <>
            {r.documents.map((d, i) => (
              <a href={d} target="_blank" key={i}>
                <Chip
                  label={'link'}
                  sx={{ margin: 0.1 }}
                  onClick={(e) => e.stopPropagation()}
                />
              </a>
            ))}
          </>
        )}
      />
      <TextField source="emissionDate" />
      <TextField source="expirationDate" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);

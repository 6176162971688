import { TextField } from '@mui/material';
import { useState } from 'react';

interface IdentifierInputProps {
  label: string;
  onChange: (identifier: string) => void;
}

export default function IdentifierInput({
  label,
  onChange,
}: IdentifierInputProps) {
  const [identifier, setIdentifier] = useState<string>('');

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const newIdentifier = event.target.value;
    setIdentifier(newIdentifier);
    onChange(newIdentifier);
  }

  return (
    <div className="Row Between">
      <p className="Bold InputLabel">{label}:</p>
      <TextField
        label={label}
        className="InputField"
        value={identifier}
        onChange={handleChange}
      />
    </div>
  );
}

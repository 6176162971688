import { Create, ReferenceInput, SelectInput, SimpleForm } from 'react-admin';
import { transformInput } from '../../../lib/utils';

export const RolePermissionCreate = () => (
  <Create transform={transformInput}>
    <SimpleForm>
      <ReferenceInput
        source="RoleId"
        reference="roles"
        sort={{ field: 'name', order: 'ASC' }}
        perPage={10000}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        source="PermissionId"
        reference="permissions"
        sort={{ field: 'name', order: 'ASC' }}
        perPage={10000}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

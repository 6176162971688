import { useState, useContext, useEffect } from 'react';
import { Buffer } from 'buffer';
import { IClient, IPointOfSale } from '../../interfaces';
import { GlobalContext } from '../../store';
import { capitalize } from '../../lib/utils';

const ZapietKey = ({ client }: { client: IClient }) => {
  const [key, setKey] = useState<string | null>(null);
  const { api } = useContext(GlobalContext);
  const [shopifyPos, setShopifyPos] = useState<IPointOfSale[]>([]);
  const [selectedPosId, setSelectedPos] = useState<number | null>(null);

  useEffect(() => {
    (async () => {
      const pos = await api.getPOS(client.id);
      const result = pos.filter((p) => p.name.includes('shopify'));
      setShopifyPos(result);
    })();
  }, [client]);

  const generateKey = (posId: number | null) => {
    if (!posId) return null;
    const key = Buffer.from(`${posId}`).toString('base64');
    return key;
  };
  useEffect(() => {
    setKey(generateKey(selectedPosId));
  }, [selectedPosId]);

  return (
    <>
      <div className="InputWrapper">
        <div className="InputTitle">POS</div>
        <select
          name="pos"
          onChange={(e) => setSelectedPos(Number(e.target.value))}
        >
          <option value="">Seleccionar...</option>
          {shopifyPos.map((opt) => (
            <option value={opt.id} key={opt.id}>
              {capitalize(opt.name)}
            </option>
          ))}
        </select>
      </div>
      {key && <div>Llave generada: {key}</div>}
    </>
  );
};

export default ZapietKey;

import { useContext, useEffect, useState, useRef, ChangeEvent } from 'react';
import { IClient, IPointOfSale, IPointOfSaleLocal } from '../../interfaces';
import { capitalize, alert } from '../../lib/utils';
import { GlobalContext } from '../../store';
import { IClientLocals } from '../../interfaces/IClientLocals';

interface CurrentPointOfSaleLocal {
  LocalId: number;
  externalId: string;
  name: string;
  updatesStock: boolean;
}

const ManagePOSLocals = ({
  client,
  pointOfSale,
}: {
  client: IClient;
  pointOfSale: IPointOfSale;
}) => {
  const { api } = useContext(GlobalContext);
  const [clientLocals, setClientLocals] = useState<IClientLocals[]>([]);
  const [pointOfSaleLocals, setPointOfSaleLocals] = useState<
    IPointOfSaleLocal[]
  >([]);
  const [availableClientLocals, setAvailableClientLocals] = useState<
    IClientLocals[]
  >([]);
  const [currentPointOfSaleLocals, setCurrentPointOfSaleLocals] = useState<
    CurrentPointOfSaleLocal[]
  >([]);

  const resetButtonRef = useRef<HTMLInputElement>(null);

  // Context
  const { context } = useContext(GlobalContext);
  const { startLoading, finishLoading } = context;

  // Load Data on page load
  useEffect(() => {
    async function loadData() {
      startLoading();
      // Get ClientLocals && PointOfSaleLocals
      const clientLocals = await api.getClientLocals(client.id);
      setClientLocals(clientLocals);
      const pointOfSaleLocals = await api.getPointOfSaleLocals(pointOfSale.id);
      setPointOfSaleLocals(pointOfSaleLocals);
      finishLoading();
    }
    loadData();
  }, [pointOfSale]);

  // Load initial currentPointOfSaleLocals
  useEffect(() => {
    setCurrentPointOfSaleLocals([]);
  }, [client]);

  // Clear currentPointOfSaleLocals
  useEffect(() => {
    handleInitialLocals();
  }, [clientLocals, pointOfSaleLocals]);

  // Handle submit based on integration
  async function handleSubmit() {
    if (!client || !pointOfSale) {
      alert('error', 'Formulario incompleto');
      return;
    }
    if (currentPointOfSaleLocals.some((local) => !local.externalId)) {
      alert('error', 'Por favor, rellenar todos los campos externalId');
      return;
    }
    startLoading();
    const deletedPointOfSaleLocals = pointOfSaleLocals.filter(
      (posl) =>
        !currentPointOfSaleLocals.some(
          (cposl) => cposl.LocalId === posl.LocalId
        )
    );
    const addedPointOfSaleLocals = currentPointOfSaleLocals.filter(
      (cposl) =>
        !pointOfSaleLocals.some((posl) => cposl.LocalId === posl.LocalId)
    );
    const updatedPointOfSaleLocals = pointOfSaleLocals
      .filter((posl) =>
        currentPointOfSaleLocals.some(
          (cposl) =>
            cposl.LocalId === posl.LocalId &&
            (cposl.externalId !== posl.externalId ||
              cposl.updatesStock !== posl.updatesStock)
        )
      )
      .map((posl) => {
        const currentPointOfSaleLocal = currentPointOfSaleLocals.find(
          (cposl) => cposl.LocalId === posl.LocalId
        ) as CurrentPointOfSaleLocal;
        return {
          ...posl,
          externalId: currentPointOfSaleLocal.externalId,
          updatesStock: currentPointOfSaleLocal.updatesStock,
        };
      });
    try {
      await Promise.all(
        deletedPointOfSaleLocals.map(async (posl) =>
          api.deletePointOfSaleLocal(posl.id)
        )
      );
      await Promise.all(
        addedPointOfSaleLocals.map(async (posl) =>
          api.createPointOfSaleLocal({
            PointOfSaleId: pointOfSale.id,
            LocalId: posl.LocalId,
            externalId: posl.externalId,
            updatesStock: posl.updatesStock,
          })
        )
      );
      await Promise.all(
        updatedPointOfSaleLocals.map(async (posl) =>
          api.updatePointOfSaleLocal(posl)
        )
      );
    } catch (error) {
      alert('error', 'Error al actualizar los Locales del POS');
    } finally {
      finishLoading();
    }
  }

  // Handle initial currentPointOfSaleLocals load
  function handleInitialLocals() {
    const initialPointOfSaleLocals: CurrentPointOfSaleLocal[] = [];
    const availableClientLocals: IClientLocals[] = [];
    clientLocals.map((cl) => {
      const pointOfSaleLocal = pointOfSaleLocals.find(
        (posl) => posl.LocalId === cl.LocalId
      );
      if (pointOfSaleLocal) {
        initialPointOfSaleLocals.push({
          LocalId: pointOfSaleLocal.LocalId,
          name: cl.Local.name,
          externalId: pointOfSaleLocal.externalId,
          updatesStock: pointOfSaleLocal.updatesStock,
        });
      } else {
        availableClientLocals.push(cl);
      }
    });
    setCurrentPointOfSaleLocals(initialPointOfSaleLocals);
    setAvailableClientLocals(availableClientLocals);
  }

  // Handle add local to POS
  function handleAddLocal(event: ChangeEvent<HTMLSelectElement>) {
    const {
      target: { value },
    } = event;
    const clientLocal = availableClientLocals.find(
      (cl) => cl.id === Number(value)
    );
    if (clientLocal) {
      setCurrentPointOfSaleLocals([
        ...currentPointOfSaleLocals,
        {
          LocalId: clientLocal.LocalId,
          name: clientLocal.Local.name,
          externalId: '',
          updatesStock: true,
        },
      ]);
      setAvailableClientLocals(
        availableClientLocals.filter((cl) => cl.id !== Number(value))
      );
    }
  }

  // Handle delete currentPointOfSaleLocal
  function handleDeleteLocal(idx: number) {
    const deletedPointOfSaleLocal = currentPointOfSaleLocals[idx];
    setAvailableClientLocals([
      ...availableClientLocals,
      clientLocals.find(
        (cl) => cl.LocalId === deletedPointOfSaleLocal.LocalId
      ) as IClientLocals,
    ]);
    const updatedLocals = currentPointOfSaleLocals.filter(
      (_, index) => index !== idx
    );
    setCurrentPointOfSaleLocals(updatedLocals);
  }

  // Handle add local externalId
  function handleAddLocalExternalId(localId: number, newValue: string) {
    setCurrentPointOfSaleLocals((prevLocals) => {
      return prevLocals.map((local) =>
        local.LocalId === localId ? { ...local, externalId: newValue } : local
      );
    });
  }

  // Handle add local externalId
  function handleToggleStockUpdate(localId: number, newValue: boolean) {
    setCurrentPointOfSaleLocals((prevLocals) => {
      return prevLocals.map((local) =>
        local.LocalId === localId ? { ...local, updatesStock: newValue } : local
      );
    });
  }

  return (
    <>
      <div className="InputWrapper">
        <div className="InputTitle">Agregar Locales al POS</div>
        <div className="InlineDisplay">
          <div className="InputWrapper">
            <select name="selectedLocal" onChange={(e) => handleAddLocal(e)}>
              <option value="">Seleccionar...</option>
              {availableClientLocals.map((opt) => (
                <option value={opt.id} key={opt.id}>
                  {capitalize(opt.Local.name)}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      {currentPointOfSaleLocals.length !== 0 && (
        <>
          <div className="MinimizeTitle">
            Locales Agregados (Ingresa los id de los Locales)
          </div>
          <div className="AddedLocals">
            {currentPointOfSaleLocals.map((pointOfSaleLocal, index) => (
              <div key={pointOfSaleLocal.LocalId}>
                <div className="InlineDisplay AllRow">
                  <div>
                    <span>Local:</span> {capitalize(pointOfSaleLocal.name)}
                  </div>
                  <div>
                    <span>External Id:</span>
                    <input
                      type="text"
                      key="externalId"
                      value={pointOfSaleLocal.externalId}
                      onChange={(e) =>
                        handleAddLocalExternalId(
                          pointOfSaleLocal.LocalId,
                          e.target.value
                        )
                      }
                    />
                  </div>
                  <div>
                    <span>Updates Stock:</span>
                    <input
                      type="checkbox"
                      key="updatesStock"
                      checked={pointOfSaleLocal.updatesStock} // Use the appropriate property from your state or data
                      onChange={(e) =>
                        handleToggleStockUpdate(
                          pointOfSaleLocal.LocalId,
                          e.target.checked
                        )
                      }
                    />
                  </div>
                  <div
                    className="Button Secondary"
                    onClick={() => handleDeleteLocal(index)}
                  >
                    Eliminar
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      <input type="reset" ref={resetButtonRef} hidden />
      <div
        className={`Button ${!client || !pointOfSale ? 'disabled' : ''}`}
        onClick={handleSubmit}
      >
        Enviar
      </div>
    </>
  );
};

export default ManagePOSLocals;

import {
  Datagrid,
  DateField,
  List,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
} from 'react-admin';
import { AdminPagination } from '../AdminLayout';
import { JsonField } from 'react-admin-json-view';

const shipmentFilters = [
  <NumberInput source="OrderId"></NumberInput>,
  <ReferenceInput
    source="CourierId"
    reference="couriers"
    sort={{ field: 'name', order: 'ASC' }}
  >
    <SelectInput optionText="name" />
  </ReferenceInput>,
 <NumberInput label="Journey" source="JourneyId"/>,
];

export const ShipmentList = () => (
  <List filters={shipmentFilters} pagination={<AdminPagination />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <NumberField source="OrderId" />
      <ReferenceField source="CourierId" reference="couriers">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="trackingUrl" />
      <TextField source="status" />
      <DateField source="eta" />
      <NumberField source="fee" />
      <NumberField source="messageFee" />
      <JsonField
        source="receiverInfo"
        jsonString={false} // Set to true if the value is a string, default: false
        reactJsonOptions={{
          // Props passed to react-json-view
          name: null,
          collapsed: true,
          enableClipboard: false,
          displayDataTypes: true,
        }}
      />
      <ReferenceField source="JourneyId" reference="journeys">
        <NumberField source="id" />
      </ReferenceField>
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);

import { IModel } from '../../interfaces';

export const transferWithdrawals: IModel = {
  name: 'transferWithdrawals',
  path: '',
  attributes: [
    {
      name: 'sku',
      type: 'string',
      required: true,
    },
    {
      name: 'amount',
      type: 'string',
      required: true,
    },
  ],
};

import {
  BooleanField,
  Datagrid,
  DateField,
  List,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
} from 'react-admin';
import { AdminPagination } from '../AdminLayout';

const stockMoveFilters = [
  <NumberInput source="available" />,
  <NumberInput source="ProductId" label="Product Id" />,
  <ReferenceInput source="LocalId" reference="locals" filter={{ active: true }}>
    <SelectInput optionText="name" />
  </ReferenceInput>,
  <SelectInput
    source="processed"
    choices={[
      { id: 'success', name: 'Success' },
      { id: 'failed', name: 'Failed' },
      { id: 'pending', name: 'Pending' },
    ]}
  />,
];

export const StockMoveList = () => (
  <List filters={stockMoveFilters} pagination={<AdminPagination />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <NumberField source="available" />
      <NumberField source="onHand" />
      <TextField source="processed" />
      <TextField source="reason" />
      <TextField source="source" />
      <BooleanField source="activated" />
      <ReferenceField source="ProductId" reference="products">
        <TextField source="sku" />
      </ReferenceField>
      <ReferenceField source="LocalId" reference="locals">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);

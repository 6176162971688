import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { IClient, IPointOfSale } from '../../interfaces';
import { alert, capitalize } from '../../lib/utils';
import { GlobalContext } from '../../store';

const LoadProducts = ({ client }: { client: IClient }) => {
  const [source, setSource] = useState<'shopify' | ''>('shopify');
  const [posList, setPosList] = useState<IPointOfSale[]>([]);
  const [posId, setPosId] = useState<number>();
  const sources = ['shopify'];

  // Context
  const { context, api, functionsApi } = useContext(GlobalContext);
  const { startLoading, finishLoading } = context;

  useEffect(() => {
    (async () => {
      const integration = (await api.getIntegrations(client.id)).find(
        (integration) => integration.name === source
      );
      const pos = await api.getPOS(client.id);
      const result = pos.filter((p) => p.IntegrationId === integration?.id);
      setPosList(result);
    })();
  }, [source, client]);

  // Handle submit
  async function handleSubmit() {
    if (!client || !posId) {
      alert('error', 'Formulario incompleto');
      return;
    }

    try {
      startLoading();
      await functionsApi.createProducts(posId);
      finishLoading();
      alert('success', 'Se han cargado los productos correctamente');
    } catch (error) {
      finishLoading();
      if (axios.isAxiosError(error)) {
        alert(
          'error',
          `Ocurrió un error: "${
            (error.response?.data as { message: string }).message
          }"`
        );
      } else {
        alert('error', 'Ocurrió un error: error desconocido');
      }
    }
  }

  return (
    <>
      <div className="InputWrapper">
        <div className="InputTitle">Fuente</div>
        <select
          name="integration"
          onChange={(e) => setSource(e.target.value as 'shopify' | '')}
        >
          <option value="" selected={source === ''}>
            Seleccionar...
          </option>
          {sources.map((opt) => (
            <option value={opt} key={opt} selected={source === opt}>
              {capitalize(opt)}
            </option>
          ))}
        </select>
      </div>
      <div className="InputWrapper">
        <div className="InputTitle">Elige el Pos</div>
        <select name="pos" onChange={(e) => setPosId(Number(e.target.value))}>
          <option value="" selected={source === ''}>
            Seleccionar...
          </option>
          {posList.map((opt) => (
            <option value={opt.id} key={opt.id} selected={posId === opt.id}>
              {capitalize(opt.name)}
            </option>
          ))}
        </select>
      </div>
      <div
        className={`Button ${!client || !posId ? 'disabled' : ''}`}
        onClick={handleSubmit}
      >
        Enviar
      </div>
    </>
  );
};

export default LoadProducts;

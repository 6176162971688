import { useState } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  FormDataConsumer,
} from 'react-admin';
import { JsonInput } from 'react-admin-json-view';
import { transformInput } from '../../../lib/utils';

export const LocalCreate = () => {
  const [countryId, setCountryId] = useState<number | null>(null);
  const [regionId, setRegionId] = useState<number | null>(null);

  return (
    <Create transform={transformInput}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="email" />
        <JsonInput
          source="address"
          jsonString={false} // Set to true if the value is a string, default: false
          defaultValue={{
            address1: '',
            address2: '',
            commune: '',
          }}
          reactJsonOptions={{
            // Props passed to react-json-view
            name: null,
            collapsed: false,
            enableClipboard: false,
            displayDataTypes: true,
          }}
        />
        <NumberInput source="latitude" />
        <NumberInput source="longitude" />
        <NumberInput source="pickingRate" />
        <BooleanInput source="allowsStacking" />
        <TextInput source="pedidosYaToken" />
        <TextInput source="stockSourceId" />
        <TextInput source="phone" />
        <ReferenceInput
          source="CountryId"
          reference="countries"
          sort={{ field: 'name', order: 'ASC' }}
          perPage={100}
        >
          <SelectInput
            onChange={(e) => {
              setCountryId(e.target.value);
              setRegionId(null);
            }}
            optionText="name"
          />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.CountryId !== null && (
              <>
                {countryId !== null && (
                  <ReferenceInput
                    source="RegionId"
                    reference="regions"
                    sort={{ field: 'name', order: 'ASC' }}
                    filter={{ CountryId: countryId }}
                    perPage={10000}
                    {...rest}
                  >
                    <SelectInput
                      onChange={(e) => {
                        setRegionId(e.target.value);
                      }}
                      optionText="name"
                    />
                  </ReferenceInput>
                )}
                {regionId && (
                  <ReferenceInput
                    source="CommuneId"
                    reference="communes"
                    filter={{ RegionId: regionId }}
                    sort={{ field: 'name', order: 'ASC' }}
                    perPage={10000}
                    {...rest}
                  >
                    <SelectInput optionText="name" />
                  </ReferenceInput>
                )}
              </>
            )
          }
        </FormDataConsumer>
        <BooleanInput source="active" defaultValue={true} />
      </SimpleForm>
    </Create>
  );
};

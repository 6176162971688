import {
  Create,
  NumberInput,
  PasswordInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  minLength,
} from 'react-admin';
import { transformInput } from '../../../lib/utils';

export const UserCreate = () => (
  <Create transform={transformInput}>
    <SimpleForm>
      <TextInput source="username" />
      <PasswordInput
        source="password"
        initiallyVisible
        validate={minLength(8)}
      />
      <TextInput source="email" />
      <TextInput source="name" />
      <TextInput source="apiKey" />
      <TextInput source="apiSecret" />
      <ReferenceInput
        source="ConnectionTypeId"
        reference="connection-types"
        sort={{ field: 'name', order: 'ASC' }}
        perPage={10}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <NumberInput source="connectableId" />
      <ReferenceInput
        source="RoleId"
        reference="roles"
        sort={{ field: 'name', order: 'ASC' }}
        perPage={10000}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

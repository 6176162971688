import {
  Datagrid,
  DateField,
  List,
  NumberInput,
  TextField,
  TextInput,
} from 'react-admin';
import { AdminPagination } from '../AdminLayout';

const orderTypeFilters = [
  <NumberInput label="Id" source="id" alwaysOn />,
  <TextInput label="Name" source="name" />,
];

export const OrderTypeList = () => (
  <List
    filters={orderTypeFilters}
    sort={{ field: 'id', order: 'DESC' }}
    pagination={<AdminPagination />}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name"/>
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);

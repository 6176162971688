import {
    DateField,
    Edit,
    ReferenceField,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
  } from 'react-admin';
  import { transformInput } from '../../../lib/utils';
  
  export const ClientCourierEdit = () => (
    <Edit transform={transformInput}>
      <SimpleShowLayout>
        <TextField source="id" />
        <ReferenceField source="CourierId" reference="couriers">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="ClientId" reference="clients">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
      </SimpleShowLayout>
      <SimpleForm>
      <TextInput source="clientBaseFee" />
      <TextInput source="clientExtraFee" />
      <TextInput source="courierBaseFee" />
      <TextInput source="courierExtraFee" />
      </SimpleForm>
    </Edit>
  );
  
import { Heading, Flex, useTheme } from '@aws-amplify/ui-react';

const ResetPasswordHeader = () => {
  const { tokens } = useTheme();

  return (
    <Flex justifyContent="flex-start" padding={`0 0 ${tokens.space.medium}`}>
      <Heading level={4}>Ingrese nombre de usuario</Heading>
    </Flex>
  );
};

export default ResetPasswordHeader;

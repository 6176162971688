import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import SignOut from './SignOut';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { GlobalContext } from '../store';

const Navbar = () => {
  const { user } = useContext(GlobalContext);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar color="secondary" className="Navbar" position="static">
        <Toolbar>
          <Box sx={{ flexGrow: 1 }}>
            <Link to="/">
              <img
                className="Logo"
                alt="logo"
                src="https://nomad-assets-1.s3.amazonaws.com/public/NomadLogoWhite.png"
              />
            </Link>
          </Box>
          <Typography variant="h6" component="div" sx={{ mx: 2 }}>
            {user.name}
          </Typography>
          <SignOut />
          <Box sx={{ width: 30 }} />
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Navbar;

import {
  Create,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { transformInput } from '../../../lib/utils';

export const DriverCreate = () => {

  return (
    <Create transform={transformInput}>
      <SimpleForm>
        <TextInput source="name" />
      </SimpleForm>
    </Create>
  );
};

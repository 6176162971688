import { MenuItem, Select } from '@mui/material';
import { useState } from 'react';
import { ICustomChangeEvent } from '../../interfaces';

interface ISelectOption {
  value: string;
  disabled: boolean;
  label?: string;
}

export type ISelectOptions = ISelectOption[];

interface CustomSelectInputProps {
  disabled?: boolean;
  label: string;
  id: string;
  defaultValue: string | undefined;
  onChange: (event: ICustomChangeEvent) => void;
  selectOptions: ISelectOptions;
}

const CustomSelectInput = (props: CustomSelectInputProps) => {
  const {
    disabled = false,
    label,
    id,
    defaultValue,
    onChange,
    selectOptions,
  } = props;
  const [value, setValue] = useState<string | undefined>(defaultValue);

  function handleInputChange(event: any) {
    setValue(event.target.value);
    const customEvent: ICustomChangeEvent = {
      target: {
        id,
        value: event.target.value,
      },
    };
    onChange(customEvent);
  }

  return (
    <Select
      disabled={disabled}
      variant="filled"
      size="small"
      label={label}
      id={id}
      className="InputField"
      value={value}
      onChange={handleInputChange}
    >
      {selectOptions.map((option, idx) => (
        <MenuItem disabled={option.disabled} key={idx} value={option.value}>
          {option.label || option.value}
        </MenuItem>
      ))}
    </Select>
  );
};

export default CustomSelectInput;

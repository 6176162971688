import { IOrder, IOrderDetail } from '../interfaces/IOrder';

const orderCriticFieldsCreate: (keyof IOrder)[] = [
  'PointOfSaleId',
  'code',
  'platformCode',
  'customerName',
  'shippingType',
  'LocalId',
  'date',
];

const internalOrderCriticFieldsCreate: (keyof IOrder)[] = [
  'PointOfSaleId',
  'code',
  'platformCode',
  'LocalId',
  'date',
  'ClientId',
];

interface IOrderTypeFilters {
  all: string[];
  B2C: string[];
  B2B: string[];
  TO: string[];
  WO: string[];
}
export const orderTypeMappingFilters: IOrderTypeFilters = {
  all: ['B2C','B2B','TO','WO'],
  B2C: ['B2C'],
  B2B: ['B2B'],
  TO: ['TO'],
  WO: ['WO'],
};
export type IOrderTypeFiltersKey = keyof IOrderTypeFilters;

export const statusMappingFilters: {
  all: string[];
  pending: string[];
  completed: string[];
} = {
  all: [
    'pending',
    'pat-pending',
    'on-hold',
    'picking',
    'fulfilled',
    'ontheway',
    'delivered',
    'cross-docking',
    'cancelled',
    'Other status: EnProcesoDeDevolucion',
    'Other status: NoEntregado',
    'cornershop-manual',
    'cornershop-automatic',
  ],
  pending: ['pending', 'pat-pending', 'on-hold', 'picking'],
  completed: [
    'fulfilled',
    'ontheway',
    'delivered',
    'cross-docking',
    'cancelled',
    'Other status: EnProcesoDeDevolucion',
    'Other status: NoEntregado',
    'cornershop-manual',
    'cornershop-automatic',
  ],
};

function isOrderValid(order: Partial<IOrder>, criticFields: (keyof IOrder)[]) {
  Object.entries(order).forEach(([key, value]) => {
    if (
      criticFields.includes(key as keyof IOrder) &&
      (value === null || value === undefined || value === '')
    ) {
      throw new Error(`Campo ${key} no puede estar vacío.`);
    }
  });
}

export function isOrderDetailsValid(
  orderDetails: Partial<IOrderDetail>[] | undefined,
  isInternalOrder?: boolean
) {
  if (!orderDetails || orderDetails.length === 0) {
    throw new Error('El pedido debe tener al menos un producto.');
  }
  if (!isInternalOrder) {
    orderDetails.forEach((item) => {
      if (typeof item.price !== 'number') {
        throw new Error('Debe ingresar un precio para todos los productos.');
      }
    });
  }
}

export function isOrderValidCreate(order: Partial<IOrder>, isInternalOrder?: boolean) {
  const criticFields = isInternalOrder ? internalOrderCriticFieldsCreate : orderCriticFieldsCreate;
  isOrderValid(order, criticFields);
}

export function isOrderDetailsValidCreate(order: Partial<IOrder>, isInternalOrder?: boolean) {
  isOrderDetailsValid(order.OrderDetails, isInternalOrder);
}

export function isEqualToOrder(
  newOrderData: Partial<IOrder>,
  order: Partial<IOrder>
): boolean {
  const { OrderDetails, ...orderWithoutDetails } = order;
  const { OrderDetails: newOrderDetails, ...newOrderWithoutDetails } =
    newOrderData;
  return Object.entries(newOrderWithoutDetails).every(([key, value]) => {
    return value === (orderWithoutDetails as IOrder)[key as keyof IOrder];
  });
}

export function isEqualToOrderDetails(
  newOrderDetails: Partial<IOrderDetail>[],
  orderDetails: IOrderDetail[]
): boolean {
  if (newOrderDetails.length !== orderDetails.length) {
    return false;
  }
  return newOrderDetails.every((item, idx) => {
    return Object.entries(item).every(([key, value]) => {
      return value === orderDetails[idx][key as unknown as keyof IOrderDetail];
    });
  });
}

export function updatedOrderDetails(
  newOrderDetails: Partial<IOrderDetail>[],
  orderDetails: IOrderDetail[]
): Partial<IOrderDetail>[] {
  return newOrderDetails.filter((item, idx) => {
    return !Object.entries(item).every(([key, value]) => {
      return value === orderDetails[idx][key as unknown as keyof IOrderDetail];
    });
  });
}

export function createEmptyOrder(localId: number): Partial<IOrder> {
  return {
    shippingType: '',
    LocalId: localId,
    date: undefined,
    shippingCost: 0,
    globalDiscount: 0,
    shippingDiscount: 0,
    total: 0,
    OrderDetails: [],
  };
}

import '@aws-amplify/ui-react/styles.css';
import { useMemo, useReducer } from 'react';

// Store
import {
  defaultState,
  GetGlobalContext,
  GlobalContext,
  reducer,
} from '../store';

// Interfaces
import { ContextInterface, IUser, StateInterface } from '../interfaces';
import PageLoader from '../components/PageLoader';
import { useLocalStorage } from '../hooks';
import Api from '../lib/api';
import FunctionsApi from '../lib/functionsApi';

interface StoreWrapperProps {
  children: JSX.Element | JSX.Element[];
  api: Api;
  functionsApi: FunctionsApi;
  user: IUser;
}

function StoreWrapper({
  children,
  api,
  functionsApi,
  user,
}: StoreWrapperProps) {
  // Load local storage to app state
  const [localStorage] = useLocalStorage('state', defaultState);
  const [state, dispatch] = useReducer(reducer, localStorage);
  const globalContext = GetGlobalContext(dispatch);

  // Provider value
  const providerValue = useMemo<{
    state: StateInterface;
    context: ContextInterface;
    api: Api;
    functionsApi: FunctionsApi;
    user: IUser;
  }>(
    () => ({ state, context: globalContext, api, functionsApi, user }),
    [state]
  );

  return (
    <GlobalContext.Provider value={providerValue}>
      {children}
      {state.loading > 0 && <PageLoader />}
    </GlobalContext.Provider>
  );
}

export default StoreWrapper;

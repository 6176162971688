import { IOrder, IOrderDetail } from '../../interfaces';
import { formatPrice } from '../../lib/utils';
import NumberInputField from './NumberInputField';

const summaryRows: Array<{ label: string; field: keyof IOrder }> = [
  {
    label: 'Costo despacho',
    field: 'shippingCost',
  },
  {
    label: 'Descuento despacho',
    field: 'shippingDiscount',
  },
  {
    label: 'Descuento global',
    field: 'globalDiscount',
  },
];

interface OrderSummaryProps {
  order: Partial<IOrder>;
  setOrderData: (data: Partial<IOrder>) => void;
  editMode?: boolean;
  globalDisabled?: boolean;
}

const OrderSummary = (props: OrderSummaryProps) => {
  const {
    order,
    setOrderData,
    editMode = false,
    globalDisabled = false,
  } = props;

  function handleChange(id: string, value: number) {
    setOrderData({ [id]: value });
  }

  function getOrderTotal() {
    let orderTotal = 0;
    if (!order.OrderDetails || order.OrderDetails.length === 0) {
      return 0;
    }
    for (let idx = 0; idx < order.OrderDetails.length; idx++) {
      const element = order.OrderDetails[idx];
      const get = (id: keyof IOrderDetail) => {
        return Number(element[id]);
      };
      if (get('price') === null) {
        return 0;
      }
      orderTotal += get('amount') * get('price') - get('discount');
    }
    orderTotal += order.shippingCost || 0;
    orderTotal -= order.shippingDiscount || 0;
    orderTotal -= order.globalDiscount || 0;
    return orderTotal;
  }

  return (
    <div className="Border">
      <div id="OrderSummary">
        {summaryRows.map((row, idx) => (
          <div className="Row Start" key={idx}>
            <div className="SummaryLabel Flex6">{row.label}</div>
            <div className="Flex1">
              <NumberInputField
                id={row.field}
                editMode={editMode}
                defaultValue={Number(order[row.field])}
                onChange={handleChange}
                money
                disabled={globalDisabled}
              />
            </div>
          </div>
        ))}
        <div className="Row Start">
          <div className="SummaryLabel Flex6">Total</div>
          <div className="Flex1">{formatPrice(getOrderTotal())}</div>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;

import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { Fragment, SyntheticEvent, useEffect, useState } from 'react';
import { ICustomChangeEvent, IData } from '../../interfaces';

interface AutocompleteInputProps<T> {
  label: string;
  dataKey: keyof T;
  defaultValue?: T | null;
  // onChange: (event: any , newValue: IData | null | undefined) => void;
  onChange: (event: ICustomChangeEvent) => void;
  fetchData: () => Promise<T[]>;
  dynamicFetchData?: (newInputValue: string) => Promise<T[]>;
  disabled?: boolean;
  allWidth?: boolean;
}

const AutocompleteInput = <T extends IData>(
  props: AutocompleteInputProps<T>
) => {
  const {
    onChange,
    fetchData,
    dynamicFetchData,
    label,
    dataKey,
    defaultValue,
    disabled = false,
    allWidth = false,
  } = props;
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly T[]>([]);
  const loading = open && options.length === 0;
  const [value, setValue] = useState<T | null | undefined>(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      if (active) {
        const result = await fetchData();
        setOptions([...result]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  function handleChange(e: SyntheticEvent, newValue: T | null) {
    setValue(newValue);
    const event: ICustomChangeEvent = {
      target: {
        id: `${label}Input`,
        value: newValue?.id,
        item: newValue,
      },
    };
    onChange(event);
  }

  async function handleInputChange(e: SyntheticEvent, newInputValue: string) {
    if (dynamicFetchData) {
      const data = await dynamicFetchData(newInputValue);
      setOptions([...data]);
    }
  }

  return (
    <Autocomplete
      disabled={disabled}
      value={value}
      className={`InputField ${allWidth ? 'FormInputAllWidth' : ''}`}
      onChange={handleChange}
      onInputChange={handleInputChange}
      id={`${label}Input`}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) =>
        option[dataKey] === value[dataKey]
      }
      getOptionLabel={(option) => option[dataKey] as unknown as string}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default AutocompleteInput;

import {
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { transformInput } from '../../../lib/utils';

export const CommuneCreate = () => (
  <Edit transform={transformInput}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="normalName" />
      <ReferenceInput source="RegionId" reference="regions">
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

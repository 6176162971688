import { useContext, useEffect, useState } from 'react';
import LocalCard from '../components/LocalCard';
import { GlobalContext } from '../store';
import { ILocal } from '../interfaces';
import { TextField } from '@mui/material';
import { normalize } from '../lib/utils';

const Home = () => {
  const { api, user, context } = useContext(GlobalContext);
  const [locals, setLocals] = useState<ILocal[]>([]);
  const [localsFilter, setLocalsFilter] = useState('');
  const isAdmin = user?.role === 'admin';

  useEffect(() => {
    (async () => {
      context.startLoading();
      setLocals(await api.getLocals({ filter: { active: true }, rowsPerPage: 1000 }));
      context.finishLoading();
    })();
  }, []);

  function intelligentFilter(item: ILocal) {
    const itemNormalized = normalize(item.name);
    const filterNormalized = normalize(localsFilter);
    return filterNormalized
      .split(' ')
      .every((word) => itemNormalized.includes(word));
  }

  return (
    <div className="Home">
      <div className="HomeTitle">Selecciona una opción</div>
      {locals.length > 1 && (
        <TextField
          label="Filtrar"
          className="LocalsFilter"
          onChange={(e) => setLocalsFilter(e.target.value)}
        />
      )}
      {isAdmin && (
        <LocalCard
          key="admin"
          link="admin"
          name="Admin"
          imageUrl="https://nomad-assets-1.s3.amazonaws.com/public/mfc-small.jpg"
        />
      )}
      {locals.filter(intelligentFilter).map((local) => (
        <LocalCard
          onKeyDown
          key={`local-${local.id}`}
          link={`local/${local.id}/orders`}
          name={local.name}
          imageUrl="https://nomad-assets-1.s3.amazonaws.com/public/mfc2-small.jpg"
        />
      ))}
    </div>
  );
};

export default Home;

import {
  DateField,
  DateInput,
  Edit,
  NumberInput,
  SimpleForm,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import { transformInput } from '../../../lib/utils';

export const ReceptionDetailEdit = () => (
  <Edit transform={transformInput}>
    <SimpleShowLayout>
      <TextField source="id" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </SimpleShowLayout>
    <SimpleForm>
      <NumberInput source="amount" />
      <NumberInput source="price" />
      <NumberInput source="received" />
      <NumberInput source="rejected" />
      <DateInput source="expirationDate" />
      <NumberInput source="ReceptionId" label="Reception Id" />
      <NumberInput source="ProductId" label="Product Id" />
    </SimpleForm>
  </Edit>
);

import {
  Datagrid,
  DateField,
  List,
  NumberField,
  NumberInput,
  ReferenceField,
  TextField,
} from 'react-admin';
import { AdminPagination } from '../AdminLayout';

const receptionDetailFilters = [
  <NumberInput label="Reception Id" source="ReceptionId" alwaysOn />,
  <NumberInput label="Id" source="id" alwaysOn />,
];

export const ReceptionDetailList = () => (
  <List
    filters={receptionDetailFilters}
    sort={{ field: 'id', order: 'DESC' }}
    pagination={<AdminPagination />}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <NumberField source="amount" />
      <NumberField source="price" />
      <NumberField source="received" />
      <NumberField source="rejected" />
      <DateField source="expirationDate" />
      <ReferenceField source="ReceptionId" reference="receptions">
        <TextField source="id" />
      </ReferenceField>
      <ReferenceField source="ProductId" reference="products">
        <TextField source="sku" />
      </ReferenceField>
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);

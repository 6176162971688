import { Datagrid, DateField, List, TextField, TextInput } from 'react-admin';
import { AdminPagination } from '../AdminLayout';

const permissionFilters = [<TextInput source="model"></TextInput>];

export const PermissionList = () => (
  <List filters={permissionFilters} pagination={<AdminPagination />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="model" />
      <TextField source="access" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);

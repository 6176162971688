import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
} from 'react-admin';
import { AdminPagination } from '../AdminLayout';

const rolePermissionFilters = [
  <ReferenceInput
    source="RoleId"
    reference="roles"
    sort={{ field: 'name', order: 'ASC' }}
    perPage={10000}
  >
    <SelectInput optionText="name" />
  </ReferenceInput>,
  <ReferenceInput
    source="PermissionId"
    reference="permissions"
    sort={{ field: 'name', order: 'ASC' }}
    perPage={10000}
  >
    <SelectInput optionText="name" />
  </ReferenceInput>,
];

export const RolePermissionList = () => (
  <List filters={rolePermissionFilters} pagination={<AdminPagination />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField source="RoleId" reference="roles">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="PermissionId" reference="permissions">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);

import {
  DateField,
  Edit,
  FormDataConsumer,
  FunctionField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from 'react-admin';
import { ICommune } from '../../../interfaces/ICommune';
import { transformInput } from '../../../lib/utils';
import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../../store';
import { ICountry } from '../../../interfaces';

export const CommuneEdit = () => {
  const { api } = useContext(GlobalContext);
  const [regionId, setRegionId] = useState<number | null>(null);
  const [countryId, setCountryId] = useState<number | null>(null);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [intialValuesAlreadySet, setIntialValuesAlreadySet] =
    useState<boolean>(true);

  function setInitialValues(newRegionId: number) {
    if (intialValuesAlreadySet && countries.length > 0) {
      for (let i = 0; i < countries.length; i += 1) {
        const checkCountry = countries[i];
        for (let j = 0; j < checkCountry.Regions.length; j += 1) {
          const checkRegion = checkCountry.Regions[j];
          if (checkRegion?.id === newRegionId) {
            setCountryId(checkCountry.id);
          }
        }
      }
      setRegionId(newRegionId);
      setIntialValuesAlreadySet(false);
    }
  }

  useEffect(() => {
    api.getCountries().then((newCountries) => {
      setCountries(newCountries);
    });
  }, []);

  return (
    <Edit transform={transformInput}>
      <SimpleShowLayout>
        <TextField source="id" />
        <FunctionField
          label="Normal Name (generated automatically)"
          render={(x: ICommune) => `${x.name} --> ${x.normalName}`}
        />
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
      </SimpleShowLayout>
      <SimpleForm>
        <TextInput source="name" />
        {countryId !== null && (
          <ReferenceInput
            source="CountryId"
            reference="countries"
            sort={{ field: 'name', order: 'ASC' }}
            perPage={100}
          >
            <SelectInput
              defaultValue={countryId}
              onChange={(e) => {
                setCountryId(e.target.value);
                setRegionId(null);
              }}
              optionText="name"
            />
          </ReferenceInput>
        )}
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.RegionId !== null &&
            (setInitialValues(formData.RegionId),
            (
              <ReferenceInput
                source="RegionId"
                reference="regions"
                sort={{ field: 'name', order: 'ASC' }}
                filter={{ CountryId: countryId }}
                perPage={10000}
                {...rest}
              >
                <SelectInput
                  defaultValue={regionId}
                  onChange={(e) => {
                    setRegionId(e.target.value);
                  }}
                  optionText="name"
                />
              </ReferenceInput>
            ))
          }
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

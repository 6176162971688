import {
  Button,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { ChangeEvent, useState } from 'react';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';

interface SearchProps {
  onUpdateSearch: (newValue: { [key: string]: string }) => void;
  search: {
    input: string | null;
    type: string;
    fields?: string[];
  };
  setSearch: React.Dispatch<
    React.SetStateAction<{
      input: string | null;
      type: string;
      fields?: string[];
    }>
  >;
}

const Search = (props: SearchProps) => {
  const { onUpdateSearch, search, setSearch } = props;
  const inputString = search.input || '';
  const [lastSearch, setLastSearch] = useState(inputString);
  const hasChanged = lastSearch !== inputString;

  function handleUpdateSearch() {
    if (!hasChanged) {
      return;
    }
    onUpdateSearch({ [search.type]: inputString });
    setLastSearch(inputString);
  }

  function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    setSearch({ ...search, input: e.target.value });
  }

  const handleToggleChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: 'name' | 'sku'
  ) => {
    setSearch({
      ...search,
      type: newValue,
    });
  };

  const handleFieldTranslation = (field: string) => {
    switch (field) {
      case 'name':
        return 'nombre';
      case 'sku':
        return 'sku';
      case 'code':
        return 'código';
      case 'status':
        return 'estado';
      case 'reason':
        return 'razón';
      case 'clientName':
        return 'cliente';
      case 'locationName':
        return 'ubicacion';
      case 'productName':
        return 'producto';
      default:
        return '';
    }
  };

  return (
    <>
      <div className="Search" id="ProductLocationSearch">
        <div className="SearchContainer">
          <TextField
            className="SearchInput"
            value={search.input || ''}
            onChange={handleInputChange}
            label="Buscar"
            placeholder={`Ingresa ${handleFieldTranslation(search.type)}...`}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleUpdateSearch();
              }
            }}
          />
          <div
            className={`ReturnIcon ${hasChanged ? 'Active' : ''}`}
            onClick={handleUpdateSearch}
          >
            <KeyboardReturnIcon />
          </div>
        </div>
        <Button
          disabled={!search.input}
          onClick={() => {
            setSearch({
              ...search,
              input: '',
            });
          }}
        >
          <HighlightOffTwoToneIcon />
        </Button>
      </div>
      {search.fields ? (
        <>
          <ToggleButtonGroup
            color="primary"
            exclusive
            value={search.type}
            onChange={handleToggleChange}
            aria-label="Platform"
          >
            {search.fields.map((field) => (
              <ToggleButton key={field} value={field}>
                {handleFieldTranslation(field)}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </>
      ) : null}
    </>
  );
};

export default Search;

import {
  Datagrid,
  DateField,
  List,
  NumberField,
  ReferenceField,
  TextField,
  TextInput,
} from 'react-admin';
import { AdminPagination } from '../AdminLayout';

const userFilters = [
  <TextInput source="id" />,
  <TextInput source="username" />,
  <TextInput source="email" />,
  <TextInput source="name" />,
];

export const UserList = () => (
  <List filters={userFilters} pagination={<AdminPagination />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="username" />
      <TextField source="email" />
      <TextField source="name" />
      <TextField source="apiKey" />
      <TextField source="apiSecret" />
      <ReferenceField source="ConnectionTypeId" reference="connection-types">
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="connectableId" />
      <ReferenceField source="RoleId" reference="roles">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);

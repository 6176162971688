import { Button, Tooltip } from '@mui/material';

interface EditButtonsProps {
  editMode: boolean;
  onEdit?: () => void;
  onCancel: () => void;
  onSave: () => void;
  saveDisabled?: boolean;
  editDisabled?: boolean;
}

const EditButtons = (props: EditButtonsProps) => {
  const {
    editMode,
    onEdit = () => null,
    onSave,
    onCancel,
    saveDisabled = false,
    editDisabled = false,
  } = props;
  return (
    <div className="Row Center EditButtons">
      {editMode ? (
        <>
          <Button variant="contained" color="info" onClick={onCancel}>
            Cancelar
          </Button>
          <Button
            disabled={saveDisabled}
            variant="contained"
            color="primary"
            onClick={onSave}
          >
            Guardar
          </Button>
        </>
      ) : (
        <>
          {editDisabled ? (
            <Tooltip title="Los pedidos completados no pueden editarse.">
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onEdit}
                  disabled
                >
                  Editar
                </Button>
              </div>
            </Tooltip>
          ) : (
            <Button variant="contained" color="primary" onClick={onEdit}>
              Editar
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default EditButtons;

import {
  DateField,
  Edit,
  NumberInput,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from 'react-admin';
import { transformInput } from '../../../lib/utils';

export const CountryEdit = () => (
  <Edit transform={transformInput}>
    <SimpleShowLayout>
      <TextField source="id" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </SimpleShowLayout>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="phoneCode" />
      <TextInput source="code" />
      <NumberInput source="messageFee" />
      <NumberInput source="uberDirectFeeBasePrice" />
      <NumberInput source="uberDirectRegularMaxPrice" />
    </SimpleForm>
  </Edit>
);

import { createTheme } from '@mui/material/styles';
import { defaultTheme } from 'react-admin';

const theme = createTheme({
  ...defaultTheme,
  palette: {
    primary: {
      main: 'rgb(229,75,14)',
    },
    secondary: {
      main: 'rgba(229,75,14,0.9)',
    },
    info: {
      main: '#C0C0C0',
    },
    warning: {
      main: '#d11f1f',
    },
  },
});

export default theme;

import * as React from 'react';
import {
  Table,
  TableBody,
  TablePagination,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  FormControlLabel,
  Switch,
  Checkbox,
} from '@mui/material';
import { IOrder, ITransferOrder, ILocal, } from '../interfaces';
import {
  headCells,
  transferHeadCells,
  OrdersTableHead,
  OrdersTableToolbar,
} from './OrdersTableComponents';
import { Link } from 'react-router-dom';
import { formatDate } from '../lib/utils';
import { GlobalContext } from '../store';

interface OrdersTableProps {
  rows: IOrder[];
  onChangeRequest: (
    sort:  { [key: string]: string }[],
    rowsPerPage: number,
    page: number
  ) => Promise<void>;
  initialSort:  { [key: string]: string }[];
  initialPageSze: number;
  initialPage: number;
  totalRowCount: number;
  local: ILocal;
  children?: JSX.Element | JSX.Element[];
  orderTypeFilter?: string[];
}

export default function OrdersTable({
  rows,
  onChangeRequest,
  initialSort,
  initialPageSze,
  initialPage,
  totalRowCount,
  local,
  children,
  orderTypeFilter = [],
}: OrdersTableProps) {
  const {
    api,
  } = React.useContext(GlobalContext);
  const [sort, setSort] = React.useState<{ [key: string]: string }[]>(initialSort);
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [page, setPage] = React.useState(initialPage);
  const [dense, setDense] = React.useState(false);
  const [transferOrderData, setTransferOrderData] = React.useState<ITransferOrder[]>([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(initialPageSze);
  const showOnlyTransferOrders = React.useMemo<boolean>(
    () => (orderTypeFilter.length === 1 && orderTypeFilter[0] === 'TO'), [orderTypeFilter]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof IOrder | keyof ITransferOrder,
  ) => {
    const sortAlreadySelected = sort[0][property];
    const isPrevAsc = sortAlreadySelected && sortAlreadySelected === 'asc';
    const newOrder = isPrevAsc ? 'desc' : 'asc';
    const newSort = [{ [property]: newOrder }];
    setSort(newSort);
    onChangeRequest(newSort, rowsPerPage, page);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setSelected([]);
    onChangeRequest(sort, rowsPerPage, newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    setSelected([]);
    onChangeRequest(sort, newRowsPerPage, 0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, rowsPerPage - rows.length) : 0;

  React.useEffect(() => {
    (async () => {
      if (showOnlyTransferOrders) {
        const orderIds = rows.map((order) => order.id);
        const transferOrder =  await api.getTransferOrders({ filter: {
          ids: orderIds,
        }});
        setTransferOrderData(transferOrder);
      }
    })();
  }, [orderTypeFilter, rows]);

  return (
    <div className="TableContainer">
      <Paper sx={{ width: '100%', mb: 2 }}>
        <OrdersTableToolbar numSelected={selected.length} local={local}>
          {children}
        </OrdersTableToolbar>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <OrdersTableHead
              numSelected={selected.length}
              sort={sort}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              showOnlyTransferOrders={showOnlyTransferOrders}
            />
        <TableBody>
          {rows.map((row, index) => {
            const isItemSelected = isSelected(row.id);
            const labelId = `enhanced-table-checkbox-${index}`;
            const transferOrder = transferOrderData.find((to) => row.id === to.id);
            const entity = (showOnlyTransferOrders && transferOrder ? transferOrder: row) as IOrder & ITransferOrder;
            const entityHeadCells = showOnlyTransferOrders && transferOrder ? transferHeadCells : headCells;
            return (
              <TableRow
                hover
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={row.id}
                selected={isItemSelected}
                component={Link}
                to={`./${row.id}`}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    onClick={(event) => handleClick(event, row.id)}
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{
                      'aria-labelledby': labelId,
                    }}
                  />
                </TableCell>
                <TableCell component="th" id={labelId} scope="row" align="right">
                  {row.id}
                </TableCell>
                  {entityHeadCells.slice(1).map((headCell, idx) => (
                    <TableCell
                      key={`tc-${idx}`}
                      align={headCell.type === 'number' ? 'right' : 'left'}
                    >
                    {headCell.type === 'function' && headCell.render && headCell.render(entity)}
                    {['string', 'number'].includes(headCell.type) && `${entity[headCell.id]}`}
                    {headCell.type === 'date' && formatDate(entity[headCell.id] as string)}
                    </TableCell>
                  ))}
              </TableRow>
            );
          })}
          {emptyRows > 0 && (
            <TableRow
              style={{
                height: (dense ? 33 : 53) * emptyRows,
              }}
            >
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={totalRowCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Ajustar contenido"
      />
    </div>
  );
}

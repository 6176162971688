import { useContext } from 'react';
import { GlobalContext } from './store';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Layout from './components/Layout';
import NotFound from './pages/NotFound';
import NomadAdmin from './pages/NomadAdmin';
import Local from './pages/Local';
import Orders from './pages/Orders/Orders';
import OrderShow from './pages/Orders/OrderShow';
import OrderCreate from './pages/Orders/OrderCreate';
import OrderPickup from './pages/Orders/OrderPickup';
import ProductLocations from './pages/ProductLocations/ProductLocations';
import Losses from './pages/Losses/Losses';
import LocalButtons from './components/LocalButtons';
import OrderReship from './pages/Orders/OrderReship';
import OrderReallocate from './pages/Orders/OrderReallocate';
import OrderCancel from './pages/Orders/OrderCancel';
import OrderFinishShipment from './pages/Orders/OrderFinishShipment';

const AdminRoutes = () => {
  const { user } = useContext(GlobalContext);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          {user.role === 'admin' && (
            <Route path="admin/*" element={<NomadAdmin />} />
          )}
          <Route element={<Layout />}>
            <Route index element={<Home />} />
            <Route path={`local/:localId`} element={<Local />}>
              <Route index element={<LocalButtons />} />
              <Route path="orders">
                <Route
                  index
                  element={
                    <>
                      <LocalButtons />
                      <Orders />
                    </>
                  }
                />
                <Route path=":orderId" element={<OrderShow />}>
                  <Route path="pickup" element={<OrderPickup />} />
                  <Route path="reship" element={<OrderReship />} />
                  <Route path="reallocate" element={<OrderReallocate />} />
                  <Route path="cancel" element={<OrderCancel />} />
                  <Route path="finishShipment" element={<OrderFinishShipment />} />
                </Route>
                <Route path="new" element={<OrderCreate />} />
              </Route>
              <Route path="productLocations">
                <Route
                  index
                  element={
                    <>
                      <LocalButtons />
                      <ProductLocations />
                    </>
                  }
                />
                {/* <Route index element={<><LocalButtons /><NotFound/></>} /> */}
              </Route>
              <Route path="losses">
                <Route
                  index
                  element={
                    <>
                      <LocalButtons />
                      <Losses />
                    </>
                  }
                />
                {/* <Route index element={<><LocalButtons /><NotFound/></>} /> */}
              </Route>
            </Route>
            <Route path="*" element={<NotFound />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AdminRoutes;

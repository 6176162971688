import { IOrderCreate } from '../interfaces';

function resetOrderDetails(
  setOrder: React.Dispatch<React.SetStateAction<Partial<IOrderCreate>>>
) {
  setOrder((prevOrder) => ({
    ...prevOrder,
    OrderDetails: [],
  }));
}

export default resetOrderDetails;

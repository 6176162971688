import {
  BooleanInput,
  Create,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

export const StockMoveCreate = () => (
  <Create>
    <SimpleForm>
      <NumberInput source="available" />
      <NumberInput source="onHand" />
      <TextInput source="processed" />
      <TextInput source="reason" />
      <TextInput source="source" />
      <BooleanInput source="activated" />
      <ReferenceInput source="ProductId" reference="products">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput source="LocalId" reference="locals" filter={{ active: true }}>
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useContext, useEffect, useRef, useState } from 'react';
import {
  IClient,
  ILocal,
  IUser,
  IRole,
  IConnectionType,
} from '../../interfaces';
import { GlobalContext } from '../../store';
import { alert } from '../../lib/utils';
import { IconButton } from '@mui/material';

const CreateUser = () => {
  // State
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [roles, setRoles] = useState<IRole[]>([]);
  const [role, setRole] = useState<IRole | null>(null);
  const [connectionTypes, setConnectionTypes] = useState<IConnectionType[]>([]);
  const [connectionType, setConnectionType] = useState<IConnectionType | null>(
    null
  );
  const [models, setModels] = useState<ILocal[] | IClient[]>([]);
  const [model, setModel] = useState<ILocal | IClient | null>(null);

  // Input refs
  const usernameInput = useRef<HTMLInputElement>(null);
  const passwordInput = useRef<HTMLInputElement>(null);
  const emailInput = useRef<HTMLInputElement>(null);
  const nameInput = useRef<HTMLInputElement>(null);

  // Context
  const { context, api } = useContext(GlobalContext);
  const { startLoading, finishLoading } = context;

  useEffect(() => {
    (async () => {
      startLoading();
      setRoles(await api.getRoles());
      setConnectionTypes(await api.getConnectionTypes());
      finishLoading();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      startLoading();
      setModel(null);
      if (!connectionType) {
        setModels([]);
      } else if (connectionType.name === 'Client') {
        setModels(await api.getClients({ active: true }));
      } else if (connectionType.name === 'Local') {
        setModels(await api.getLocals({ filter: { active: true }, rowsPerPage: 1000 }));
      }
      finishLoading();
    })();
  }, [connectionType]);

  function reset() {
    (usernameInput.current as HTMLInputElement).value = '';
    (passwordInput.current as HTMLInputElement).value = '';
    (emailInput.current as HTMLInputElement).value = '';
    (nameInput.current as HTMLInputElement).value = '';
    setRole(null);
    setConnectionType(null);
    setModel(null);
  }

  const isButtonEnabled = (): boolean => {
    return !!(role && (role.name === 'admin' || (connectionType && model)));
  };

  const handleSubmit = async () => {
    try {
      if (
        !usernameInput.current?.value ||
        !passwordInput.current?.value ||
        !nameInput.current?.value
      ) {
        throw new Error('Datos inválidos: faltan campos.');
      }

      if (passwordInput.current.value.length < 8) {
        throw new Error(
          'Datos inválidos: la contraseña debe tener al menos 8 caracteres.'
        );
      }

      if (/[A-Z]/.test(usernameInput.current.value)) {
        throw new Error(
          'Datos inválidos: el nombre de usuario no puede tener mayúsculas.'
        );
      }

      startLoading();

      const requestBody: Partial<IUser> = {
        username: usernameInput.current.value,
        password: passwordInput.current.value,
        email: emailInput.current?.value || undefined,
        name: nameInput.current.value,
        RoleId: role?.id,
        ConnectionTypeId: connectionType?.id,
        connectableId: model?.id,
      };
      await api.createUser(requestBody);
      alert('success', 'El usuario se ha cargado correctamente.');
      reset();
    } catch (error) {
      if (error instanceof Error) {
        alert('error', error.message);
      } else {
        alert('error', 'No se pudo cargar el usuario.');
      }
    }
    finishLoading();
  };

  return (
    <>
      <div className="InputWrapper">
        <div className="InputTitle">Nombre de Usuario</div>
        <input type="text" name="username" ref={usernameInput} />
        <div className="InputDescription">
          Sin espacios, todo en minúsculas (como el normalName). Ej: labarra
        </div>
      </div>

      <div className="InputWrapper">
        <div className="InputTitle">Contraseña</div>
        <div className="Row Start">
          <input
            type={passwordVisible ? 'text' : 'password'}
            name="password"
            ref={passwordInput}
          />
          <IconButton onClick={() => setPasswordVisible(!passwordVisible)}>
            {passwordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        </div>
        <div className="InputDescription">Mínimo 8 caracters</div>
      </div>

      <div className="InputWrapper">
        <div className="InputTitle">Email</div>
        <input type="text" name="email" ref={emailInput} />
        <div className="InputDescription">
          Opcional. Es bueno para que el usuario pueda restablecer su
          contraseña.
        </div>
      </div>

      <div className="InputWrapper">
        <div className="InputTitle">Nombre</div>
        <input type="text" name="name" ref={nameInput} />
        <div className="InputDescription">Ej: La Barra</div>
      </div>

      <div className="InputWrapper">
        <div className="InputTitle">Rol</div>
        <select
          name="role"
          onChange={(e) => setRole(roles[parseInt(e.target.value, 10)])}
        >
          <option value="">Selecciona un rol</option>
          {roles.map((roleOption, idx) => (
            <option value={idx} key={roleOption.id}>
              {roleOption.name}
            </option>
          ))}
        </select>
      </div>

      {role && role.name !== 'admin' && (
        <>
          <div className="InputWrapper">
            <div className="InputTitle">Tipo de conexion</div>
            <select
              name="connectionType"
              onChange={(e) =>
                setConnectionType(connectionTypes[parseInt(e.target.value, 10)])
              }
            >
              <option value="">Selecciona un tipo de conexion</option>
              {connectionTypes.map((connectionOption, idx) => (
                <option value={idx} key={connectionOption.id}>
                  {connectionOption.name}
                </option>
              ))}
            </select>
          </div>

          {connectionType && (
            <div className="InputWrapper">
              <div className="InputTitle">{connectionType?.name}</div>
              <select
                name="model"
                onChange={(e) => setModel(models[parseInt(e.target.value, 10)])}
              >
                <option value="">Selecciona un {connectionType?.name}</option>
                {models.map((modelOption, idx) => (
                  <option
                    value={idx}
                    key={modelOption.id}
                    selected={modelOption.id === model?.id}
                  >
                    {modelOption.name}
                  </option>
                ))}
              </select>
            </div>
          )}
        </>
      )}

      <div
        className={`Button ${isButtonEnabled() ? '' : 'disabled'}`}
        onClick={handleSubmit}
      >
        Enviar
      </div>
    </>
  );
};

export default CreateUser;

import * as React from 'react';
import { Menu, MenuItem, Button } from '@mui/material';
import { Add, ContentCopy } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

const CreateOrderMenu = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        variant="text"
        color="primary"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <AddIcon fontSize="small" sx={{ marginRight: 0.2 }} />
        Crear Pedido
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={() => {
            navigate('new', { state: { type: 'new' } });
            handleClose();
          }}
        >
          <Add fontSize="small" sx={{ marginRight: 0.2 }} />
          Nuevo
        </MenuItem>

        <MenuItem
          onClick={() => {
            navigate('new', { state: { type: 'duplicate' } });
            handleClose();
          }}
        >
          <ContentCopy fontSize="small" sx={{ marginRight: 0.2 }} />
          Duplicado
        </MenuItem>
      </Menu>
    </div>
  );
};

export default CreateOrderMenu;

import { Create, NumberInput, SimpleForm, TextInput } from 'react-admin';

export const OrderDetailCreate = () => (
  <Create>
    <SimpleForm>
      <NumberInput source="OrderId" label="Order Id" />
      <NumberInput source="ProductId" label="Product Id" />
      <NumberInput source="amount" />
      <NumberInput source="price" />
      <NumberInput source="discount" />
      <TextInput source="note" />
    </SimpleForm>
  </Create>
);

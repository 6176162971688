import { Amplify, I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui-react';
import LoginHeader from './components/LoginComponents/LoginHeader';
import LoginFooter from './components/LoginComponents/LoginFooter';
import Header from './components/LoginComponents/Header';
import ResetPasswordHeader from './components/LoginComponents/ResetPasswordHeader';

const awsConfig = {
  Auth: {
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
  },
};

Amplify.configure(awsConfig);

I18n.putVocabularies(translations);
I18n.setLanguage('es');

I18n.putVocabularies({
  es: {
    'Enter your username': 'Nombre de usuario',
  },
});

const components = {
  Header,
  SignIn: {
    Header: LoginHeader,
    Footer: LoginFooter,
  },
  ResetPassword: {
    Header: ResetPasswordHeader,
  },
};
const formFields = {
  signIn: {
    username: {
      labelHidden: false,
      isRequired: true,
      label: 'Nombre de usuario:',
    },
    password: {
      labelHidden: false,
      isRequired: true,
      label: 'Contraseña:',
    },
  },
};

export { components, formFields };

import { Datagrid, DateField, List, TextField, TextInput } from 'react-admin';
import { AdminPagination } from '../AdminLayout';

const integrationFilters = [<TextInput label="Name" source="name"></TextInput>];

export const IntegrationList = () => (
  <List filters={integrationFilters} pagination={<AdminPagination />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);

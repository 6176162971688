import { useContext, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { ILocal, IOrder, ISearch, IOrderType, IApiResourceOptions } from '../../interfaces';
import OrdersTable from '../../components/OrdersTable';
import Search from '../../components/Search';
import Back from '../../components/Back';
import StatusToggleButtons from '../../components/Order/StatusToggleButtons';
import OrderTypeToggleButtons from '../../components/Order/OrderTypeToggleButtons';
import { GlobalContext } from '../../store';
import CreateOrderMenu from '../../components/Order/CreateOrderMenu';

const initialSort = [{ id: 'desc' }];
const initialPageSize = 10;
const initialPage = 0;

type ContextType = { local: ILocal };

const Orders = () => {
  const {
    api,
    context: { startLoading, finishLoading },
  } = useContext(GlobalContext);
  const { local } = useOutletContext<ContextType>();
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [orderCount, setOrderCount] = useState<number>(0);
  const [orderTypes, setOrderTypes] = useState<IOrderType[]>([]);
  const [orderTypeFilter, setOrderTypeFilter] = useState<string[]>([]);
  const [fetchOptions, setFetchOptions] = useState<IApiResourceOptions>({
    filter: { LocalId: local.id, process: true },
    sort: initialSort,
    rowsPerPage: initialPageSize,
    page: initialPage,
  });
  const [search, setSearch] = useState<ISearch>({
    input: null,
    type: 'code',
  });

  useEffect(() => {
    fetchOrders();
  }, [fetchOptions]);

  useEffect(() => {
    (async() => {
      startLoading();
      const result = await api.getOrderTypes(fetchOptions);
      setOrderTypes(result);
      finishLoading();
    })();
  }, []);

  async function fetchOrders() {
    startLoading();
    const result = await api.getOrders(fetchOptions);
    setOrders(result.orders);
    setOrderCount(result.count);
    finishLoading();
  }

  function handleUpdateSearch(newValue: { [key: string]: string }) {
    setFetchOptions({
      ...fetchOptions,
      searchValue: newValue[search.type],
    });
  }

  function handleUpdateStatusFilter(newValue: string[]) {
    setFetchOptions({
      ...fetchOptions,
      filter: {
        ...fetchOptions.filter,
        status: newValue,
      },
    });
  }

  async function handleUpdateOrderTypeFilter(newValue: string[]) {
    setOrderTypeFilter(newValue);
    const orderTypeIds = orderTypes
      .filter((orderType) => newValue.includes(orderType.name))
      .map((orderType) => orderType.id);
    setFetchOptions({
      ...fetchOptions,
      filter: {
        ...fetchOptions.filter,
        OrderTypeId: orderTypeIds,
      },
    });
  }

  async function handleChangeRequest(
    sort: { [key: string]: string }[],
    rowsPerPage: number,
    page: number
  ) {
    setFetchOptions({
      ...fetchOptions,
      sort,
      rowsPerPage,
      page,
    });
  }

  return (
    <>
      <div className="OrdersToolbar">
        <div className="Left">
          <Back home />
        </div>
        <div className="Right">
          <CreateOrderMenu />
        </div>
      </div>
      <OrdersTable
        rows={orders}
        totalRowCount={orderCount}
        onChangeRequest={handleChangeRequest}
        initialSort={initialSort}
        initialPageSze={initialPageSize}
        initialPage={initialPage}
        local={local}
        orderTypeFilter={orderTypeFilter}
      >
        <Search
          onUpdateSearch={handleUpdateSearch}
          search={search}
          setSearch={setSearch}
        />
      <div className="OrderFilterContainer">
        <div className="OrderFilterItem">
          <StatusToggleButtons onChange={handleUpdateStatusFilter} />
        </div>
        <div className="OrderFilterItem">
          <OrderTypeToggleButtons onChange={handleUpdateOrderTypeFilter} orderTypes={orderTypes} />
        </div>
      </div>
      </OrdersTable>
    </>
  );
};

export default Orders;

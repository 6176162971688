import axios from 'axios';
import { useContext, useRef } from 'react';
import { IClient } from '../../interfaces';
import { GlobalContext } from '../../store';
import { alert } from '../../lib/utils';

const ClientLogo = ({ client }: { client: IClient }) => {
  // Input refs
  const logoInput = useRef<HTMLInputElement>(null);

  // Context
  const { context, functionsApi } = useContext(GlobalContext);
  const { startLoading, finishLoading } = context;

  async function handleSubmit() {
    startLoading();

    try {
      if (
        !client ||
        !logoInput.current ||
        !logoInput.current.files ||
        logoInput.current.files.length === 0
      ) {
        alert('error', 'Formulario incompleto');
        return;
      }

      // Get header signed URL
      const logoFile = logoInput.current.files[0];
      const logoUrl = await functionsApi.getSignedUrl(
        `public/modal/client-logos/${client.normalName}.png`,
        logoFile.type
      );

      await axios.put(logoUrl, logoFile, {
        headers: {
          'Content-Type': logoFile.type,
        },
      });

      alert('success', 'Imagen del logo agregada correctamente');
    } catch (err) {
      console.log(err);
      alert('error', 'Error inesperado');
    } finally {
      finishLoading();
    }
  }

  return (
    <>
      <div className="InputWrapper">
        <div className="InputTitle">Imagen</div>
        <input type="file" name="header" accept="image/png" ref={logoInput} />
        <div className="InputDescription">Sólo se permiten archivos .png</div>
      </div>
      <div
        className={`Button ${!client ? 'disabled' : ''}`}
        onClick={handleSubmit}
      >
        Enviar
      </div>
    </>
  );
};

export default ClientLogo;

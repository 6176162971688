export const getProductLocations = ({
  LocalId,
  ClientId,
  search,
}: {
  LocalId: number;
  ClientId?: string[];
  search?: { [key: string]: string };
}) => {
  const { locationName, productName, sku } = search || {};
  return {
    where: {
      _and: [
        {
          Location: {
            _and: [
              {
                LocalId: LocalId
                  ? {
                    _eq: LocalId,
                  }
                  : {},
              },
              {
                name: {
                  _ilike: `%${locationName ? locationName : ''}%`,
                },
              },
            ],
          },
        },
        {
          Product: {
            _and: [
              {
                name: {
                  _ilike: `%${productName ? productName : ''}%`,
                },
              },
              {
                sku: {
                  _ilike: `%${sku ? sku : ''}%`,
                },
              },
              {
                ClientId: ClientId
                  ? {
                    _in: ClientId,
                  }
                  : {},
              },
            ],
          },
        },
      ],
    },
  };
};

export const getClientLocals = ({ ClientId }: { ClientId?: number }) => {
  return {
    where: {
      _and: [
        {
          ClientId: ClientId
            ? {
              _in: ClientId,
            }
            : {},
        },
      ],
    },
  };
};

export const getPOS = ({
  clientId,
  integrationId,
}: {
  clientId?: number;
  countryId?: number;
  integrationId?: number;
}) => {
  return {
    where: {
      _and: [
        {
          ClientId: clientId
            ? {
              _eq: clientId,
            }
            : {},
          IntegrationId: integrationId
            ? {
              _eq: integrationId,
            }
            : {},
        },
      ],
    },
  };
};

export const getProductsBySku = ({
  pointOfSaleId,
  productsSku,
  clientId,
}: {
  productsSku?: string[];
  clientId?: number;
  pointOfSaleId: number;
}) => {
  return {
    where: {
      _and: [
        {
          PointOfSaleId: pointOfSaleId
            ? {
              _eq: pointOfSaleId,
            }
            : {},
        },
        {
          Product: {
            _and: [
              {
                _or: [
                  {
                    rawSku: productsSku
                      ? {
                        _in: productsSku,
                      }
                      : {},
                  },
                  {
                    sku: productsSku
                      ? {
                        _in: productsSku,
                      }
                      : {},
                  },
                ],
              },
              {
                ClientId: clientId
                  ? {
                    _eq: clientId,
                  }
                  : {},
              },
            ],
          },
        },
      ],
    },
  };
};

export const getLocalLosses = ({
  filter,
  sort,
}: {
  filter?: {
    [key: string]:
    | string
    | number
    | string[]
    | number[]
    | boolean
    | { [key: string]: string | string[] };
  };
  sort:
  | { [key: string]: string }[]
  | { [key: string]: { [key: string]: string } }[];
}) => {
  const { LocalId, ClientId, status } = filter || {};
  return {
    where: {
      _and: [    
          LocalId
            ? { ProductLocationMove: { Location: { LocalId: { _eq: LocalId }}}}
            : {},
          ClientId
            ? { ProductLocationMove: { ClientId: { _in: ClientId} }}
            : {},
          status ? { status: { _in: status}}
            : {},
      ],
    },
    sorting: sort,
  };
};

export const getOrderTypesVariables = ({
  filter,
}: {
  filter?: {
    [key: string]:
    | string
    | number
    | string[]
    | number[]
    | boolean
    | { [key: string]: string | string[] };
  };
}) => {
  const { id, name } = filter || {};
  return {
    where: {
      _and: [
        {
          name: name ? {
            _in: name,
          } : {},
        },
        {
          id: id ? {
            _eq: id,
          } : {},
        },
      ],
    },
  };
};

export const getTransferOrdersVariables = ({
  filter,
  sort,
}: {
  filter?: {
    [key: string]:
    | string
    | number
    | string[]
    | number[]
    | boolean
    | { [key: string]: string | string[]; };
  };
  sort:
  | { [key: string]: string }[]
  | { [key: string]: { [key: string]: string } }[];
}) => {
  const { ids } = filter || {};
  return {
    where: {
      _and: [
        {
          id: ids ? {
            _in: ids,
          } : {},
        },
      ],
    },
    sorting: sort,
  };
};

export const getOrdersVariables = ({
  filter,
  sort,
  searchValue,
}: {
  filter?: { [key: string]: string | number | string[] | number[] | boolean | { [key: string]: string | string[] } };
  sort: { [key: string]: string }[] | { [key: string]: { [key: string]: string } }[];
  searchValue?: string;
}) => {
  const {
    code, customerName, shippingType, status, OrderTypeId, LocalId, pat, ids, process, ClientId, internalCode
  } = filter || {};
  const variables: {
    where: {
      _and: object[]; _or?: object[];
    };
    sorting: { [key: string]: string }[] | { [key: string]: { [key: string]: string } }[];
  } = {
    where: {
      _and: [
        {
          ClientId: ClientId ? {
            _eq: ClientId,
          } : {},
        },
        {
          LocalId: LocalId ? {
            _eq: LocalId,
          } : {},
        },
        {
          id: ids ? {
            _in: ids,
          } : {},
        },
        {
          code: code ? {
            _eq: code,
          } : {},
        },
        {
          customerName: customerName ? {
            _eq: customerName,
          } : {},
        },
        {
          shippingType: shippingType ? {
            _eq: shippingType,
          } : {},
        },
        {
          status: status ? {
            _in: status,
          } : {},
        },
        {
          pat: pat || {},
        },
        {
          OrderTypeId: OrderTypeId ? {
            _in: OrderTypeId,
          } : {},
        },
        {
          process: process ? {
            _eq: process,
          } : {},
        },
        {
          internalCode: internalCode ? {
            _ilike: `%${internalCode}%`,
          } : {},
        },
      ],
    },
    sorting: sort,
  };
  if (searchValue && searchValue.length > 0) {
    variables.where._or = [
      {
        customerName: {
          _ilike: `%${searchValue}%`,
        },
      },
      {
        code: {
          _ilike: `%${searchValue}%`,
        },
      },
      {
        internalCode: {
          _ilike: `%${searchValue}%`,
        },
      },
    ];
    if (searchValue.match(/^[0-9]+$/)) {
      variables.where._or.push({
        id: {
          _eq: searchValue,
        },
      });
    }
  }
  return variables;
};
import { createContext } from 'react';
import { ContextInterface, IUser, StateInterface } from '../interfaces';
import Api from '../lib/api';
import { defaultState } from './reducer';
import FunctionsApi from '../lib/functionsApi';

interface DefaultContextProps {
  state: StateInterface;
  context: ContextInterface;
  api: Api;
  functionsApi: FunctionsApi;
  user: IUser;
}

const defaultContext: DefaultContextProps = {
  state: defaultState,
  context: null as unknown as ContextInterface,
  api: null as unknown as Api,
  functionsApi: null as unknown as FunctionsApi,

  user: null as unknown as IUser,
};

export default createContext(defaultContext);

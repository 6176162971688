import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { INotFoundProduct } from '../../interfaces';
import { formatPrice } from '../../lib/utils';
import { useState } from 'react';
import EditButtons from '../EditButtons';

interface NotFoundProductProps {
  data: INotFoundProduct[];
  onChange: (data: INotFoundProduct[]) => Promise<boolean>;
}

export default function NotFoundProducts(props: NotFoundProductProps) {
  const { data, onChange } = props;
  const [items, setItems] = useState<INotFoundProduct[]>(data);
  const [editMode, setEditMode] = useState<boolean>(false);

  function handleRemove(idx: number) {
    const newItems = [...items];
    newItems.splice(idx, 1);
    setItems(newItems);
  }

  function handleCancel() {
    setItems(data);
    setEditMode(false);
  }

  async function handleSave() {
    const success = await onChange(items);
    if (success) {
      setEditMode(false);
    }
  }

  if (!data.length) {
    return <></>;
  }

  return (
    <div className="Border" id="NotFoundProducts">
      <h3>Productos no encontrados</h3>
      {items.map((item, idx) => (
        <div className="Row OrderDetail" key={idx}>
          <div className="Flex2">{item.amount}</div>
          <div className="Flex6">
            <div>
              <p className="Sku ProductName">{item.sku}</p>
              <p className="ProductName">{item.name}</p>
            </div>
          </div>
          <div className="Flex2">{formatPrice(item.price || 0)}</div>
          <div className="Flex2">{formatPrice(item.discount || 0)}</div>

          <div className="Flex2">
            {formatPrice(
              (item.price || 0) * item.amount - (item.discount || 0)
            )}
          </div>

          <div className="Flex1">
            {editMode && (
              <Button onClick={() => handleRemove(idx)}>
                <DeleteIcon />
              </Button>
            )}
          </div>
        </div>
      ))}
      <EditButtons
        editMode={editMode}
        onCancel={handleCancel}
        onSave={handleSave}
        onEdit={() => setEditMode(true)}
        saveDisabled={data.length === items.length}
      />
    </div>
  );
}

import { Create, NumberInput, SimpleForm } from 'react-admin';

export const PointOfSaleProductCreate = () => (
  <Create>
    <SimpleForm>
      <NumberInput source="PointOfSaleId" label="PointOfSale Id" />
      <NumberInput source="ProductId" label="Product Id" />
    </SimpleForm>
  </Create>
);

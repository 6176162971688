import { useEffect, useState } from 'react';
import { NumericFormat, OnValueChange } from 'react-number-format';

interface NumberInputFieldProps {
  id: string | number | symbol;
  editMode?: boolean;
  defaultValue?: number | null;
  money?: boolean;
  onChange: (id: string, value: number) => void;
  disabled?: boolean;
  allowedDecimals?: number;
}

const NumberInputField = (props: NumberInputFieldProps) => {
  const {
    id,
    onChange,
    editMode = false,
    defaultValue = 0,
    money = false,
    disabled = false,
    allowedDecimals = 0,
  } = props;
  const [value, setValue] = useState<number | null>(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [editMode, defaultValue]);

  const handleChange: OnValueChange = (values) => {
    const newValue = !values.value ? 0 : values.floatValue as number;
    setValue(newValue);
    onChange(String(id), newValue);
  };
  return (
    <NumericFormat
      onFocus={(e) => e.target.select()}
      className={editMode ? 'NumberInput' : ''}
      value={value}
      onValueChange={handleChange}
      allowNegative={false}
      decimalSeparator=","
      thousandSeparator="."
      displayType={editMode ? 'input' : 'text'}
      prefix={money ? '$' : ''}
      disabled={disabled}
      decimalScale={allowedDecimals}
    />
  );
};

export default NumberInputField;

import { useState, useContext, useEffect, useRef } from 'react';
import { IClient, IPointOfSale } from '../../interfaces';
import { GlobalContext } from '../../store';
import { capitalize } from '../../lib/utils';
import { Switch } from '@mui/material';
import { alert } from '../../lib/utils';
import { IOrderReprocess } from '../../interfaces/IOrderReprocess';

const OrderReprocess = ({ client }: { client: IClient }) => {
  const { api, functionsApi, context } = useContext(GlobalContext);
  const { startLoading, finishLoading } = context;
  const [shopifyPos, setShopifyPos] = useState<IPointOfSale[]>([]);
  const [selectedPosId, setSelectedPos] = useState<number | null>(null);
  const [forceNomadShipping, setForceNomadShipping] = useState<boolean>(false);
  const orderCodeInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    (async () => {
      const pos = await api.getPOS(client.id);
      const result = pos.filter((p) => p.name.includes('shopify'));
      setShopifyPos(result);
    })();
  }, [client]);

  // Handle submit
  async function handleSubmit() {
    if (!orderCodeInput.current?.value || !selectedPosId) {
      alert('error', 'Formulario incompleto');
      return;
    }

    const requestBody: IOrderReprocess = {
      forceNomadShipping,
      pointOfSaleId: selectedPosId,
      orderCode: orderCodeInput.current.value,
    };

    try {
      startLoading();
      await functionsApi.orderReprocess(requestBody);
      finishLoading();
      alert('success', 'La Orden fue reprocesada! Favor revisar que haya sido creada correctamente');
    } catch (error) {
      finishLoading();
      alert('error', `El token no pudo ser agregado: ${error}`);
    }
  }

  return (
    <>
      <div className="InputWrapper">
        <div className="InputTitle">POS</div>
        <select
          name="pos"
          onChange={(e) => setSelectedPos(Number(e.target.value))}
        >
          <option value="">Seleccionar...</option>
          {shopifyPos.map((opt) => (
            <option value={opt.id} key={opt.id}>
              {capitalize(opt.name)}
            </option>
          ))}
        </select>
      </div>
      <div className="InputWrapper">
        <div className="InputTitle">Order Code</div>
        <input type="text" key="basale-token" ref={orderCodeInput} placeholder='NM#1234'/>
      </div>
      <div className="InputText">
        Forzar Nomad Process
        <Switch
          checked={forceNomadShipping}
          onClick={() => setForceNomadShipping(!forceNomadShipping)}
        />
      </div>
      <div
        className={`Button ${!client || !selectedPosId ? 'disabled' : ''}`}
        onClick={handleSubmit}
      >
        Enviar
      </div>
    </>
  );
};

export default OrderReprocess;

import { Box, Typography } from '@mui/material';
import {
  AppBar,
  Menu,
  AppBarProps,
  Layout,
  LayoutProps,
  useResourceDefinitions,
  Pagination,
  PaginationProps,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { capitalize } from '../../lib/utils';
import SignOut from '../SignOut';
import ViewListIcon from '@mui/icons-material/ViewList';
import { useContext } from 'react';
import { GlobalContext } from '../../store';

export const AdminPagination = (props: PaginationProps) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100, 200]} {...props} />
);

const AdminMenu = () => {
  const resources = useResourceDefinitions();

  return (
    <Menu>
      {/* Default Menu */}
      <Menu.DashboardItem />
      {Object.keys(resources).map((name) => (
        <Menu.Item
          to={`/admin/${name}`}
          key={name}
          primaryText={capitalize(name)}
          leftIcon={<ViewListIcon />}
        />
      ))}

      {/* Custom Menu */}
      <Menu.Item
        to="/admin/onboarding"
        key="onboarding"
        primaryText="Onboarding"
        leftIcon={<ViewListIcon />}
      />
      <Menu.Item
        to="/admin/massive"
        key="massive"
        primaryText="Massive"
        leftIcon={<ViewListIcon />}
      />
      <Menu.Item
        to="/admin/webhooks"
        key="webhooks"
        primaryText="Shopify Webhooks"
        leftIcon={<ViewListIcon />}
      />
      <Menu.Item
        to="/admin/pos-locals"
        key="pos-locals"
        primaryText="POS Locals"
        leftIcon={<ViewListIcon />}
      />
    </Menu>
  );
};

const MyAppBar = (props: AppBarProps) => {
  const { user } = useContext(GlobalContext);

  return (
    <AppBar {...props} color="secondary">
      <Box sx={{ flexGrow: 1 }}>
        <Link to="/">
          <img
            className="Logo"
            alt="logo"
            src="https://nomad-assets-1.s3.amazonaws.com/public/NomadLogoWhite.png"
          />
        </Link>
      </Box>
      <Typography variant="h6" component="div" sx={{ mx: 2 }}>
        {user.name}
      </Typography>
      <SignOut />
    </AppBar>
  );
};

const AdminLayout = (props: LayoutProps) => (
  <>
    <Box sx={{ height: 30 }} />
    <Layout {...props} menu={AdminMenu} appBar={MyAppBar} />
  </>
);

export default AdminLayout;

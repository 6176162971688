interface Props {
  switchButton: boolean;
  handleSwitchButton: () => void;
}

function SwitchButton(props: Props) {
  const { switchButton, handleSwitchButton } = props;
  return (
    <div className="SwitchButtonContainer">
      <span className="checkbox">
        <input
          type="checkbox"
          checked={switchButton}
          onChange={handleSwitchButton}
        />
        <span className="wrapper" onClick={handleSwitchButton}>
          <span className="knob" />
        </span>
      </span>
    </div>
  );
}

export default SwitchButton;

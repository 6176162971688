import {
  Accordion,
  AccordionDetails,
  Modal,
  Skeleton,
  Tooltip,
} from '@mui/material';
import {
  IOrderBackorders,
  IOrderDetail,
  IProduct,
  IOrderDetailCreate,
  IProductWithStock,
} from '../../interfaces';
import { formatPrice } from '../../lib/utils';
import ProductSearch from './ProductSearch';
import OptionsMenu from './OptionsMenu';
import { useContext, useEffect, useState } from 'react';
import NumberInputField from './NumberInputField';
import { GlobalContext } from '../../store';
import { StickyNote2 } from '@mui/icons-material';
import { useParams } from 'react-router-dom';

interface OrderDetailProps {
  idx: string;
  item: IOrderDetail;
  pointOfSaleId: number | null;
  isOnHold?: boolean;
  backorders?: IOrderBackorders;
  packs: IOrderDetail[];
  onChange: (key: string, id: string | number | symbol, value: number) => void;
  onDelete: (item: IOrderDetail) => void;
  onNote: (id: number) => void;
  handlePackChildren: (
    packParentId: number | undefined
  ) => IOrderDetail[] | undefined;
  onAddPackChildren?: (orderDetail: IOrderDetailCreate) => void;
  showPrice?: boolean;
  editMode?: boolean;
  disabled?: boolean;
}

export default function OrderDetail(props: OrderDetailProps) {
  const { api } = useContext(GlobalContext);
  const { localId } = useParams();
  const {
    idx,
    item,
    pointOfSaleId,
    isOnHold,
    backorders,
    packs,
    onChange,
    onDelete,
    onNote,
    handlePackChildren,
    onAddPackChildren,
    showPrice = true,
    editMode = false,
    disabled = false,
  } = props;

  const [product, setProduct] = useState<IProductWithStock>();
  // change this to products an then in the ProductSearch component map the ones that are packs and the ones that are present in the order
  const [expand, setExpand] = useState(false);
  const [open, setOpen] = useState(false);

  const toggleAcordion = () => {
    setExpand((prev) => !prev);
  };

  useEffect(() => {
    (async () => {
      if (localId) {
        setProduct(
          await api.getProductWithStock(item.ProductId, Number(localId))
        );
      }
    })();
  }, [item]);

  async function handleAddOrderDetail(orderDetail: IOrderDetailCreate) {
    if (onAddPackChildren) {
      orderDetail.PackParentId = item.id;
      orderDetail.price = 0;
      onAddPackChildren(orderDetail);
    }
  }

  function handleNote(id: number) {
    onNote(id);
  }

  function handleInputChange(id: string, value: number) {
    onChange(idx, id, value);
  }

  function handleComponentChange(key: string, id: string, value: number) {
    onChange(key, id, value);
  }

  function handleDelete(orderDetail: IOrderDetail) {
    onDelete(orderDetail);
  }

  function handlePrice(item: IOrderDetail) {
    if (item.ProductId && showPrice) {
      return (
        <>
          <div className="Flex2">
            <NumberInputField
              id="price"
              defaultValue={item.price}
              onChange={handleInputChange}
              editMode={editMode}
              money
              disabled={disabled}
              allowedDecimals={2}
            />
          </div>
          <div className="Flex2">
            <NumberInputField
              id="discount"
              defaultValue={item.discount}
              onChange={handleInputChange}
              editMode={editMode}
              money
              disabled={disabled}
            />
          </div>

          <div className="Flex2">
            {typeof item.price == 'number'
              ? formatPrice(item.price * item.amount - item.discount)
              : 'Asignar precio'}
          </div>
        </>
      );
    }
  }

  if (!product) {
    return (
      <div className="Grow">
        <Skeleton />
      </div>
    );
  }

  if (!item.isPack) {
    return (
      <div className="Border">
        <div className="Row OrderDetail">
          <div className="Flex2">
            <NumberInputField
              id="amount"
              defaultValue={item.amount}
              onChange={handleInputChange}
              editMode={editMode}
              disabled={disabled}
            />
          </div>
          {isOnHold && backorders && item.wmsId && (
            <div className="Flex2">
              <NumberInputField
                id="backorder"
                defaultValue={backorders[item.wmsId] || 0}
                onChange={handleInputChange}
                disabled
              />
            </div>
          )}
          <div className="Flex6">
            <div>
              <div className="DetailHeader">
                <p className="Sku ProductName">{product.sku}</p>
                {item.note && (
                  <Tooltip title={item.note as string}>
                    <StickyNote2 />
                  </Tooltip>
                )}
              </div>
              <p className="ProductName">
                {product.name} (stock: {product.Stock.available})
              </p>
            </div>
          </div>
          {handlePrice(item)}

          <div className="Flex1">
            <OptionsMenu
              item={item}
              onDelete={handleDelete}
              note={editMode ? handleNote : undefined}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="Row Between">
            <div className="ModalBox">
              <ProductSearch
                actualProductIds={packs
                  .map((p) => p.ProductId)
                  .concat(
                    handlePackChildren(item.id)?.map((p) => p.ProductId) || []
                  )}
                pointOfSaleId={pointOfSaleId}
                onAddOrderDetail={handleAddOrderDetail}
                disabled={!open}
              />
            </div>
          </div>
        </Modal>
        <Accordion expanded={expand}>
          <div className="Border">
            <div className="Row OrderDetail">
              <div className="Flex2">
                <NumberInputField
                  id="amount"
                  defaultValue={item.amount}
                  onChange={handleInputChange}
                  editMode={editMode}
                  disabled={disabled}
                />
              </div>
              {isOnHold && backorders && <div className="Flex2"></div>}
              <div className="Flex6">
                <div>
                  <div className="DetailHeader">
                    <p className="Sku ProductName">{product.sku}</p>
                    <div className="PackLabel">PACK</div>
                    {item.note && (
                      <div>
                        <Tooltip title={item.note as string}>
                          <StickyNote2 />
                        </Tooltip>
                      </div>
                    )}
                  </div>
                  <p className="ProductName">{product.name}</p>
                </div>
              </div>
              {handlePrice(item)}

              <div className="Flex1">
                <OptionsMenu
                  item={item}
                  onDelete={handleDelete}
                  expand={toggleAcordion}
                  expanded={expand}
                  add={editMode ? setOpen : undefined}
                  note={editMode ? handleNote : undefined}
                />
              </div>
            </div>
          </div>
          {(handlePackChildren(item.id) || item.PackComponents)?.map(
            (children) => (
              <AccordionDetails>
                <div className="Border">
                  <div className="Row OrderDetail">
                    <div className="Flex2">
                      <NumberInputField
                        id="amount"
                        defaultValue={
                          (children as IOrderDetail).amount ||
                          (children as IProduct).PackComponent?.amount ||
                          0
                        }
                        onChange={(id, value) => {
                          handleComponentChange(
                            (children as IOrderDetail).ReactComponentId,
                            id,
                            value
                          );
                        }}
                        editMode={editMode}
                        disabled={disabled}
                      />
                    </div>
                    {isOnHold &&
                      backorders &&
                      (children as IOrderDetail).wmsId && (
                        <div className="Flex2">
                          <NumberInputField
                            id="backorder"
                            defaultValue={
                              backorders[
                                (children as IOrderDetail).wmsId || ''
                              ] | 0
                            }
                            onChange={(id, value) => {
                              handleComponentChange(
                                (children as IOrderDetail).ReactComponentId,
                                id,
                                value
                              );
                            }}
                            disabled
                          />
                        </div>
                      )}
                    <div className="Flex6">
                      <div>
                        <p className="Sku ProductName">{children.sku}</p>
                        <p className="ProductName">{children.name}</p>
                      </div>
                    </div>
                    <div className="Flex2"></div>
                    <div className="Flex2"></div>
                    <div className="Flex2"></div>
                    <div className="Flex1"></div>
                    <div className="Flex1">
                      <OptionsMenu
                        item={children as IOrderDetail}
                        onDelete={handleDelete}
                      />
                    </div>
                  </div>
                </div>
              </AccordionDetails>
            )
          )}
        </Accordion>
      </>
    );
  }
}

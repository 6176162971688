import { useContext, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import {
  IAddress,
  ICommune,
  ICourier,
  ICustomChangeEvent,
  IOrder,
} from '../../interfaces';
import EditButtons from '../../components/EditButtons';
import { GlobalContext } from '../../store';
import AutocompleteInput from '../../components/Order/AutocompleteInput';
import { alert } from '../../lib/utils';
import { Button, TextField } from '@mui/material';

type ContextType = { order: IOrder };

const OrderReship = () => {
  const { api, context } = useContext(GlobalContext);
  const { order } = useOutletContext<ContextType>();

  const lastShipmentIdx = order.Shipments.map((sh, idx) => [sh.id, idx]).reduce(
    (r, a) => (a[0] > r[0] ? a : r)
  )[1];
  const lastShipment = order.Shipments[lastShipmentIdx];
  const [courier, setCourier] = useState<ICourier>(lastShipment.Courier);
  const [commune, setCommune] = useState<string>('');
  const [address1Input, setAddress1Input] = useState<string>('');
  const [address2Input, setAddress2Input] = useState<string>('');
  const [changeShippingAddress, setChangeShippingAddress] =
    useState<boolean>(false);
  const navigate = useNavigate();

  async function handleSubmit() {
    if (changeShippingAddress && (!address1Input || !commune)) {
      alert(
        'error',
        'La comuna y dirección son obligatorios. Por favor, rellena los datos.'
      );
      return;
    }
    context.startLoading();
    try {
      if (changeShippingAddress) {
        const newAddress: IAddress = {
          address1: address1Input,
          address2: address2Input,
          commune: commune,
        };
        await api.reshipOrder(order.id, { courierId: courier.id, newAddress });
      } else {
        await api.reshipOrder(order.id, { courierId: courier.id });
      }
      navigate('../');
      window.location.reload();
    } catch (error) {
      if (error instanceof Error) {
        alert('error', error.message);
      }
    }
    context.finishLoading();
  }

  function handleCourierChange(event: ICustomChangeEvent) {
    setCourier(event.target.item as ICourier);
  }

  return (
    <>
      <div className="FormBorder">
        <h2 className="Row Center">Re-pedir Envío</h2>
        <div className="OrderContainer Center">
          <form>
            <AutocompleteInput
              label="Courier"
              dataKey="name"
              allWidth={true}
              fetchData={async () => api.getCouriers()}
              onChange={handleCourierChange}
              defaultValue={lastShipment.Courier}
            />
            {changeShippingAddress && (
              <>
                <AutocompleteInput
                  label="Comuna"
                  dataKey="name"
                  allWidth={true}
                  fetchData={async () => api.getCommunes()}
                  onChange={(event) =>
                    setCommune(
                      event.target.item
                        ? (event.target.item as ICommune).name
                        : ''
                    )
                  }
                />
                <div>
                  <TextField
                    className="FormInputAllWidth"
                    placeholder="Dirección"
                    onChange={(e) => setAddress1Input(e.target.value)}
                  />
                </div>
                <div>
                  <TextField
                    className="FormInputAllWidth"
                    placeholder="Nro, dpto, etc. (opcional)"
                    onChange={(e) => setAddress2Input(e.target.value)}
                  />
                </div>
                <Button
                  variant="contained"
                  color="info"
                  onClick={() =>
                    setChangeShippingAddress(!changeShippingAddress)
                  }
                >
                  Dejar dirección actual
                </Button>
              </>
            )}
            {!changeShippingAddress && (
              <Button
                variant="contained"
                color="info"
                onClick={() => setChangeShippingAddress(!changeShippingAddress)}
              >
                Cambiar dirección envío
              </Button>
            )}
            <EditButtons
              editMode
              onCancel={() => navigate('../')}
              onSave={handleSubmit}
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default OrderReship;

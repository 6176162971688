import { IModel } from '../../interfaces';

export const deliveryRates: IModel = {
  name: 'DeliveryRates',
  path: 'api/delivery-rates',
  attributes: [
    {
      name: 'price',
      type: 'number',
      required: true,
    },
    {
      name: 'activateAt',
      type: 'number',
      required: true,
    },
    {
      name: 'title',
      type: 'string',
      required: true,
    },
    {
      name: 'subtitle',
      type: 'string',
      required: true,
    },
    {
      name: 'PointOfSaleId',
      type: 'number',
      required: true,
    },
    {
      name: 'CourierId',
      type: 'number',
      required: true,
    },
    {
      name: 'shippingType',
      type: 'string',
      required: true,
    },
    {
      name: 'LocalId',
      type: 'number',
      required: true,
    },
    {
      name: 'CommuneId',
      type: 'number',
      required: true,
    },
    {
      name: 'checkStock',
      type: 'boolean',
      required: false,
    },
    {
      name: 'scheduled',
      type: 'boolean',
      required: false,
    },
    {
      name: 'priority',
      type: 'boolean',
      required: false,
    },
    {
      name: 'active',
      type: 'boolean',
      required: false,
    },
    {
      name: 'daysDelay',
      type: 'number',
      required: false,
    },
  ],
};

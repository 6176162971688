import SignOut from './SignOut';

export default function Unauthorized() {
  return (
    <div className="Container">
      <h2>No Autorizado</h2>
      <p>Estimado usuario, no tienes acceso a esta página.</p>
      <p>Si se trata de un error, por favor contacta a tu administrador.</p>
      <div className="MarginTop">
        <SignOut />
      </div>
    </div>
  );
}

import { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { GlobalContext } from '../store';
import Navbar from './Navbar';
import Unauthorized from './Unauthorized';

const Layout = () => {
  const { user } = useContext(GlobalContext);

  return (
    <div className="Layout">
      <Navbar />
      <div className="Main">
        {user.role !== 'client' ? <Outlet /> : <Unauthorized />}
      </div>
    </div>
  );
};

export default Layout;

import {
  Datagrid,
  DateField,
  List,
  NumberInput,
  ReferenceField,
  TextField,
} from 'react-admin';
import { AdminPagination } from '../AdminLayout';

const pointOfSaleProductFilters = [
  <NumberInput source="PointOfSaleId" />,
  <NumberInput source="ProductId" />,
];

export const PointOfSaleProductList = () => (
  <List filters={pointOfSaleProductFilters} pagination={<AdminPagination />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField source="PointOfSaleId" reference="pos">
        <TextField source="name" />
      </ReferenceField>

      <ReferenceField source="ProductId" reference="products">
        <TextField source="name" />
      </ReferenceField>

      <DateField source="createdAt" />

      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);

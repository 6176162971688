import axios from 'axios';
import { useContext, useRef } from 'react';
import { IClient } from '../../interfaces';
import { GlobalContext } from '../../store';
import { alert } from '../../lib/utils';

const Files = ({ client }: { client: IClient }) => {
  // Input refs
  const headerInput = useRef<HTMLInputElement>(null);
  const footerInput = useRef<HTMLInputElement>(null);

  // Context
  const { context, functionsApi } = useContext(GlobalContext);
  const { startLoading, finishLoading } = context;

  async function handleSubmit() {
    startLoading();

    try {
      if (
        !client ||
        !headerInput.current ||
        !footerInput.current ||
        !headerInput.current.files ||
        !footerInput.current.files ||
        headerInput.current.files.length === 0 ||
        footerInput.current.files.length === 0
      ) {
        alert('error', 'Formulario incompleto');
        return;
      }

      // Get header signed URL
      const headerFile = headerInput.current.files[0];
      const headerUrl = await functionsApi.getSignedUrl(
        `public/platform/mailing/headers/${client.normalName}.png`,
        headerFile.type
      );

      await axios.put(headerUrl, headerFile, {
        headers: {
          'Content-Type': headerFile.type,
        },
      });

      // Get footer signed URL
      const footerFile = footerInput.current.files[0];
      const footerUrl = await functionsApi.getSignedUrl(
        `public/platform/mailing/footers/${client.normalName}.png`,
        footerFile.type
      );

      await axios.put(footerUrl, footerFile, {
        headers: {
          'Content-Type': footerFile.type,
        },
      });
      alert('success', 'Header y Footer agregados correctamente');
    } catch (err) {
      console.log(err);
      alert('error', 'Error inesperado');
    } finally {
      finishLoading();
    }
  }

  return (
    <>
      <div className="InputWrapper">
        <div className="InputTitle">Header</div>
        <input type="file" name="header" accept="image/png" ref={headerInput} />
        <div className="InputDescription">Sólo se permiten archivos .png</div>
      </div>
      <div className="InputWrapper">
        <div className="InputTitle">Footer</div>
        <input type="file" name="footer" accept="image/png" ref={footerInput} />
        <div className="InputDescription">Sólo se permiten archivos .png</div>
      </div>
      <div
        className={`Button ${!client ? 'disabled' : ''}`}
        onClick={handleSubmit}
      >
        Enviar
      </div>
    </>
  );
};

export default Files;

import {
  BooleanInput,
  DateField,
  Edit,
  FunctionField,
  NumberInput,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from 'react-admin';
import { IClient } from '../../../interfaces';
import { transformInput } from '../../../lib/utils';

export const ClientEdit = () => (
  <Edit transform={transformInput}>
    <SimpleShowLayout>
      <TextField source="id" />
      <FunctionField
        label="Normal Name (generated automatically)"
        render={(x: IClient) => `${x.name} --> ${x.normalName}`}
      />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </SimpleShowLayout>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="prefix" />
      <TextInput source="email" />
      <BooleanInput source="canChangeStock" />
      <BooleanInput source="makesDocument" />
      <BooleanInput source="hasNomadFulfillment" />
      <NumberInput source="targetDaysSales" />
      <TextInput source="wmsId" label="WMS ID" />
      <NumberInput source="withdrawalLimit" defaultValue={5} />
      <span>Maximum withdrawals per month</span>
      <TextInput source="nid" label="NID" />
      <BooleanInput source="usesWhatsapp" />
      <BooleanInput source="hasB2B" />
      <NumberInput source="tarifierFee" />
      <NumberInput source="centralizationFee" />
      <NumberInput source="packagingFee" />
      <NumberInput source="freeShipmentPrice" />
      <BooleanInput source="hasDynamicPromise" />
      <BooleanInput source="active" />
    </SimpleForm>
  </Edit>
);

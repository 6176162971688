import {
  DateField,
  Edit,
  NumberInput,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from 'react-admin';
import { transformInput } from '../../../lib/utils';

export const OrderDetailEdit = () => (
  <Edit transform={transformInput}>
    <SimpleShowLayout>
      <TextField source="id" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </SimpleShowLayout>
    <SimpleForm>
      <NumberInput source="OrderId" label="Order Id" />
      <NumberInput source="ProductId" label="Product Id" />
      <NumberInput source="amount" />
      <NumberInput source="price" />
      <NumberInput source="discount" />
      <TextInput source="note" />
    </SimpleForm>
  </Edit>
);

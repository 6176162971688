export const statusMappingFilters: {
  all: string[];
  pending: string[];
  withdrawn: string[];
  validated: string[];
  cancelled: string[];
} = {
  all: [
    'pending',
    'validated',
    'withdrawn',
    'cancelled',
  ],
  pending: ['pending'],
  withdrawn: ['withdrawn'],
  validated: ['validated'],
  cancelled: ['cancelled'],
};

import { Create, SimpleForm, TextInput } from 'react-admin';
import { transformInput } from '../../../lib/utils';

export const RoleCreate = () => (
  <Create transform={transformInput}>
    <SimpleForm>
      <TextInput source="name" />
    </SimpleForm>
  </Create>
);

import {
    Create,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput,
  } from 'react-admin';
  import { transformInput } from '../../../lib/utils';
  
  export const ClientCourierCreate = () => (
    <Create transform={transformInput}>
      <SimpleForm>
        <ReferenceInput
          source="CourierId"
          reference="couriers"
          sort={{ field: 'name', order: 'ASC' }}
          perPage={10000}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          source="ClientId"
          reference="clients"
          sort={{ field: 'name', order: 'ASC' }}
          perPage={10000}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="clientBaseFee" />
        <TextInput source="clientExtraFee" />
        <TextInput source="courierBaseFee" />
        <TextInput source="courierExtraFee" />
      </SimpleForm>
    </Create>
  );
  
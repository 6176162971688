export default function AccessDenied() {
  return (
    <div className="AccessDeniedPage">
      <h2>No Autorizado</h2>
      <p>Estimado usuario, no tienes permiso para acceder a esta página.</p>
      <p>Si se trata de un error, por favor contacta a tu administrador.</p>
      <p>Si lo deseas puedes dirigirte a la página de Stores</p>
      <div
        className="Button"
        onClick={(e) => {
          window.open(
            'https://stores.getnomad.cl',
            '_blank',
            'noopener,noreferrer'
          );
          e.preventDefault();
        }}
      >
        Dirigirse a Stores
      </div>
    </div>
  );
}

import {
  ArrayInput,
  BooleanInput,
  DateField,
  Edit,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  SimpleShowLayout,
  TextField,
} from 'react-admin';

export const PaymentEdit = () => (
  <Edit>
    <SimpleShowLayout>
      <TextField source="id" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </SimpleShowLayout>
    <SimpleForm>
      <ReferenceInput
        source="clientId"
        reference="clients"
        perPage={1000}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <SelectInput
        source="status"
        choices={[
          { id: 'pending', name: 'pending' },
          { id: 'validating', name: 'validating' },
          { id: 'validated', name: 'validated' },
        ]}
        defaultValue={'pending'}
      />
      <NumberInput source="totalPrice" />
      <NumberInput source="transferPrice" />
      <ArrayInput source="documents" defaultValue={[]}>
        <SimpleFormIterator>
          <BooleanInput source="" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);

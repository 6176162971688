import { useContext, useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import Loading from '../components/Loading';
import { ILocal } from '../interfaces';
import { GlobalContext } from '../store';
import { alert } from '../lib/utils';

const Local = () => {
  const { api } = useContext(GlobalContext);
  const params = useParams();
  const [local, setLocal] = useState<ILocal>();
  const isLoading = !local;

  useEffect(() => {
    const localId = params.localId || '';
    api
      .getLocalById(localId)
      .then((newLocal) => setLocal(newLocal))
      .catch((error) => alert('error', error.message));
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <Outlet context={{ local }} />
    </div>
  );
};

export default Local;

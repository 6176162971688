export function rutFormatter(value: string | number): string {
  let rut = String(value).replace(/[^kK\d]/g, '');
  rut = rut.replace(/k/g, 'K');

  if (/^(\d{1,3})(\w{1})$/.exec(rut)) {
    return rut.replace(/^(\d{1,3})(\w{1})$/, '$1-$2');
  } else if (/^(\d{1,3})(\d{3})(\w{1})$/.exec(rut)) {
    return rut.replace(/^(\d{1,3})(\d{3})(\w{1})$/, '$1.$2-$3');
  } else if (/^(\d{1,3})(\d{3})(\d{3})(\w{1})$/.exec(rut)) {
    return rut.replace(/^(\d{1,3})(\d{3})(\d{3})(\w{1})$/, '$1.$2.$3-$4');
  } else {
    return rut.substring(0, 10).replace(/^(\d{1,3})(\d{3})(\d{3})(\w{1})$/, '$1.$2.$3-$4');
  }
}

import {
  DateField,
  Edit,
  NumberInput,
  SimpleForm,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import { transformInput } from '../../../lib/utils';

export const PackComponentEdit = () => (
  <Edit transform={transformInput}>
    <SimpleShowLayout>
      <TextField source="id" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </SimpleShowLayout>
    <SimpleForm>
      <NumberInput source="PackId" label="Pack Id" />
      <NumberInput source="ComponentId" label="Component Id" />
      <NumberInput source="amount" />
    </SimpleForm>
  </Edit>
);

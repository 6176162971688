import { ChangeEvent, useContext, useEffect, useState, useMemo } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Back from '../../components/Back';
import EditButtons from '../../components/EditButtons';
import OrderData from '../../components/Order/OrderData';
import OrderDetails from '../../components/Order/OrderDetails';
import OrderSummary from '../../components/Order/OrderSummary';
import generateKey from '../../helpers/generateKey';
import resetOrderDetails from '../../helpers/resetOrderDetails';
import {
  IClient,
  ICustomChangeEvent,
  ILocal,
  IOrder,
  IOrderCreate,
  IOrderDetail,
  IOrderDetailCreate,
  IOrderType,
} from '../../interfaces';
import { GlobalContext } from '../../store';
import {
  createEmptyOrder,
  isOrderValidCreate,
  isOrderDetailsValidCreate,
} from '../../lib/orders';
import { alert } from '../../lib/utils';
import { useLocation } from 'react-router-dom';

type ContextType = { local: ILocal };

const OrderCreate = () => {
  const {
    api,
    context: { startLoading, finishLoading },
  } = useContext(GlobalContext);
  const { local } = useOutletContext<ContextType>();
  const [order, setOrder] = useState<Partial<IOrderCreate>>(
    createEmptyOrder(local.id)
  );
  const navigate = useNavigate();
  const location = useLocation() as { state?: { type?: 'new' | 'duplicate' } };
  const [client, setClient] = useState<IClient | null>(null);
  const [destinationLocal, setDestinationLocal] = useState<ILocal | null>(null);
  const [globalDisabled, setGlobalDisabled] = useState<boolean>(!client);
  const [selectedOrderType, setSelectedOrderType] = useState<IOrderType | null>(order.OrderType || null);
  const isInternalOrder = useMemo<boolean>(
    () => (['TO', 'WO'].includes(selectedOrderType?.name || '')),
    [selectedOrderType]
  );
  async function handleOrderSave() {
    startLoading();
    const now = new Date();
    const finalOrder = { ...order, date: now.toISOString() };
    try {
      isOrderValidCreate(finalOrder, isInternalOrder);
      isOrderDetailsValidCreate(finalOrder, isInternalOrder);
      if (selectedOrderType?.name === 'TO') {
        const createdTransfer = await api.createTransferOrder(
          finalOrder,
          destinationLocal?.id
        );
        navigate(`../${createdTransfer.OrderId}`);
      } else {
        const createdOrderId = await api.createOrder(finalOrder);
        navigate(`../${createdOrderId}`);
      }
    } catch (error) {
      if (error instanceof Error) {
        alert('error', error.message);
      }
    }
    finishLoading();
  }

  function isSaveDisabled() {
    return false;
  }

  function handleInputChange(
    event: ChangeEvent<HTMLInputElement> | ICustomChangeEvent
  ) {
    if (event.target.id === 'ClienteInput') {
      setClient((event as ICustomChangeEvent).target.item as IClient);
    } else if (event.target.id === 'DestinoInput') {
      setDestinationLocal((event as ICustomChangeEvent).target.item as ILocal);
    }
  }

  function handleDeleteOrderDetail(item: IOrderDetail) {
    setOrder((prevOrder) => ({
      ...prevOrder,
      OrderDetails: (prevOrder.OrderDetails as IOrderDetail[]).filter(
        (x) => x.ProductId !== item.ProductId
      ),
    }));
  }

  async function handleAddCsvOrderDetails(
    data: IOrderDetail[],
    pointOfSaleId: number
  ) {
    if (!client) return;
    const skus = data.map((data) => data.sku);
    const response = await api.getProductsBySku(
      skus as string[],
      pointOfSaleId,
      client.id
    );
    const products = response.map((resData) => resData.Product);
    const newOrderDetails = products.map((prod) => {
      const csvData = data.find((p) => [prod.rawSku, prod.sku].includes(p.sku as string));
      return {
        ProductId: prod.id,
        amount: csvData ? Number(csvData.amount) : 1,
        price: csvData ? Number(csvData.price) : null,
        discount: csvData ? Number(csvData.discount) : 0,
        sku: prod.sku,
        name: prod.name,
        isPack: prod.isPack,
        ReactComponentId: generateKey(prod.id),
        note: null,
      };
    });
    setOrder((prevOrder) => ({
      ...prevOrder,
      OrderDetails: [...newOrderDetails],
    }));
  }

  async function handleAddOrderDetail(orderDetail: IOrderDetailCreate) {
    const product = await api.getProductById(orderDetail.ProductId);
    orderDetail.sku = product?.sku;
    orderDetail.name = product?.name;
    orderDetail.price = isInternalOrder ? 0 : product?.pvp;
    orderDetail.ReactComponentId = generateKey(orderDetail.ProductId);
    setOrder((prevOrder) => ({
      ...prevOrder,
      OrderDetails: [
        ...(prevOrder.OrderDetails as IOrderDetail[]),
        orderDetail,
      ],
    }));
  }

  function handleUpdateOrderDetail(
    key: string,
    id: string | number | symbol,
    value: number | string
  ) {
    setOrder((prevOrder) => ({
      ...prevOrder,
      OrderDetails: (prevOrder.OrderDetails as IOrderDetail[]).map((item) => {
        if (key === item.ReactComponentId) {
          return {
            ...item,
            [id]: value,
          };
        }
        return item;
      }),
    }));
  }

  function handleOrderDataChange(orderData: Partial<IOrder>) {
    setOrder((prevOrder) => ({ ...prevOrder, ...orderData }));
  }

  useEffect(() => {
    if (!client || !selectedOrderType) {
      return;
    }
    (async () => {
      startLoading();
      const adminIntegration = (await api.getIntegrations()).find(
        (integration) => integration.name === 'admin-platform'
      );
      const results = await api.getPOS(client.id);
      const result = results.find(
        (x) => x.IntegrationId === adminIntegration?.id
      );
      setOrder((prevOrder) => ({
        ...prevOrder,
        PointOfSaleId: result?.id || undefined,
      }));
      if (!result) {
        alert('error', 'El cliente no tiene configurado la integración de la plataforma admin.');
      }
      finishLoading();
    })();
  }, [client, selectedOrderType]);

  return (
    <>
      <Back />
      <div className="OrderContainer">
        <OrderData
          operation="create"
          type={location.state?.type || 'new'}
          order={order}
          setOrderData={handleOrderDataChange}
          client={client}
          setClient={setClient}
          editMode
          onInputChange={handleInputChange}
          setGlobalDisabled={setGlobalDisabled}
          globalDisabled={globalDisabled}
          destinationLocal={destinationLocal}
          setDestinationLocal={setDestinationLocal}
          selectedOrderType={selectedOrderType}
          setSelectedOrderType={setSelectedOrderType}
          isInternalOrder={isInternalOrder}
        />
        <OrderDetails
          order={order}
          editMode
          onAddOrderDetail={handleAddOrderDetail}
          onUpdateOrderDetail={handleUpdateOrderDetail}
          globalDisabled={globalDisabled}
          pointOfSaleId={order.PointOfSaleId || null}
          onDeleteOrderDetail={handleDeleteOrderDetail}
          handleAddCsvOrderDetails={handleAddCsvOrderDetails}
          resetOrderDetails={() => resetOrderDetails(setOrder)}
          isInternalOrder={isInternalOrder}
        >
          {!isInternalOrder ? (
            <OrderSummary
              order={order}
              setOrderData={handleOrderDataChange}
              editMode
              globalDisabled={globalDisabled}
            />
          ) : (<></>)}

          <EditButtons
            saveDisabled={isSaveDisabled()}
            editMode
            onCancel={() => navigate('../')}
            onSave={handleOrderSave}
          />
        </OrderDetails>
      </div>
    </>
  );
};

export default OrderCreate;

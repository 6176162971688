import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import {
  ChangeEvent,
  Fragment,
  SyntheticEvent,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  ILocal,
  IOrderDetailCreate,
  IProduct,
  IProductWithStock,
} from '../../interfaces';
import { GlobalContext } from '../../store';
import { useOutletContext } from 'react-router-dom';

interface ProductSearchProps {
  pointOfSaleId: number | null;
  onAddOrderDetail: (orderDetail: IOrderDetailCreate) => void;
  disabled?: boolean;
  actualProductIds?: number[];
}

const ProductSearch = (props: ProductSearchProps) => {
  const { api } = useContext(GlobalContext);
  const { local } = useOutletContext<{ local: ILocal }>();
  const {
    pointOfSaleId,
    onAddOrderDetail,
    disabled = false,
    actualProductIds = [],
  } = props;
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly IProductWithStock[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false);
  const loading = open && isRequestLoading;
  const [lastTimeout, setLastTimeout] =
    useState<ReturnType<typeof setTimeout>>();

  const generateKey = (ProductId: number) => {
    return `${ProductId}_${new Date().getTime()}`;
  };

  useEffect(() => {
    let active = true;

    if (!open) {
      return undefined;
    }

    (async () => {
      if (lastTimeout) {
        clearTimeout(lastTimeout);
      }
      const timeoutId = setTimeout(async () => {
        setIsRequestLoading(true);
        const products = await api.searchProductsWithStock(
          pointOfSaleId as number,
          local.id,
          inputValue
        );
        setIsRequestLoading(false);
        if (active) {
          setOptions([...products]);
          setLastTimeout(undefined);
        }
      }, 500);
      setLastTimeout(timeoutId);
    })();

    return () => {
      active = false;
    };
  }, [open, inputValue]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
      setInputValue('');
    }
  }, [open]);

  function handleChange(e: SyntheticEvent, newValue: IProduct | null) {
    if (!newValue) {
      return;
    }
    const orderDetail: IOrderDetailCreate = {
      ProductId: newValue.id,
      amount: 1,
      price: null,
      discount: 0,
      note: null,
      isPack: newValue.isPack,
      ReactComponentId: generateKey(newValue.id),
    };
    onAddOrderDetail(orderDetail);
  }

  function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    setInputValue(e.target.value);
  }

  // Barcode scanner functionality
  useEffect(() => {
    let barcode = '';
    let barcodeTimeout: NodeJS.Timeout;

    const handleKeyPress = (e: KeyboardEvent) => {
      if (barcodeTimeout) {
        clearTimeout(barcodeTimeout);
      }
      if (e.key === 'Enter') {
        if (barcode.length > 0) {
          setOpen(true);
          setInputValue(barcode);
          barcode = '';
        }
      } else {
        barcode += e.key;
        barcodeTimeout = setTimeout(() => {
          barcode = '';
        }, 300);
      }
    };

    window.addEventListener('keypress', handleKeyPress);

    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, [inputValue]);

  return (
    <div className="Search">
      <Autocomplete
        disabled={disabled || pointOfSaleId === null}
        className="SearchInput"
        filterOptions={(options) =>
          options.filter((option) => !actualProductIds.includes(option.id))
        }
        onChange={handleChange}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        isOptionEqualToValue={(option, value) => option.sku === value.sku}
        getOptionLabel={(option) =>
          `${option.sku} - ${option.name} (stock: ${option.Stock.available})`
        }
        options={options}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Agregar productos"
            placeholder="Escribe el nombre o el SKU..."
            value={inputValue}
            onChange={handleInputChange}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            }}
          />
        )}
      />
    </div>
  );
};

export default ProductSearch;

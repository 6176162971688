import {
  Datagrid,
  DateField,
  List,
  NumberField,
  NumberInput,
  ReferenceField,
  TextField,
} from 'react-admin';
import { AdminPagination } from '../AdminLayout';

const shipmentFilters = [
  <NumberInput source="PackId" label="Pack Id" />,
  <NumberInput source="ComponentId" label="Component Id" />,
];

export const PackComponentList = () => (
  <List filters={shipmentFilters} pagination={<AdminPagination />}>
    <Datagrid rowClick="edit">
      <NumberField source="id" />
      <ReferenceField source="PackId" reference="products">
        <TextField source="sku" />
      </ReferenceField>
      <ReferenceField source="ComponentId" reference="products">
        <TextField source="sku" />
      </ReferenceField>
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);

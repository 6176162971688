import {
    Datagrid,
    DateField,
    List,
    TextField,
  } from 'react-admin';
  import { AdminPagination } from '../AdminLayout';
  
  export const DriverList = () => (
    <List pagination={<AdminPagination />}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
      </Datagrid>
    </List>
  );
  
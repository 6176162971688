import { IModel } from '../../interfaces';

export const stockMoves: IModel = {
  name: 'StockMoves',
  path: 'api/stock-moves',
  attributes: [
    {
      name: 'available',
      type: 'number',
      required: true,
    },
    {
      name: 'onHand',
      type: 'number',
      required: true,
    },
    {
      name: 'reason',
      type: 'string',
      required: true,
    },
    {
      name: 'ProductId',
      type: 'number',
      required: true,
    },
    {
      name: 'LocalId',
      type: 'number',
      required: true,
    },
    {
      name: 'ClientId',
      type: 'number',
      required: true,
    },
  ],
};

import {
    DateField,
    Edit,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
  } from 'react-admin';
  import { transformInput } from '../../../lib/utils';
  
  export const DriverEdit = () => (
    <Edit transform={transformInput}>
      <SimpleShowLayout>
        <TextField source="id" />
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
      </SimpleShowLayout>
      <SimpleForm>
        <TextInput source="name" />
      </SimpleForm>
    </Edit>
  );
  
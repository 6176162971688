import * as React from 'react';
import {
  Table,
  TableBody,
  TablePagination,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  FormControlLabel,
  Switch,
} from '@mui/material';
import {
  SortProductLocation,
  ILocal,
  IProductLocation,
} from '../../interfaces';
import {
  headCells,
  ProductLocationsTableHead,
  ProductLocationsTableToolbar,
} from '../ProductLocations/ProductLocationsTableComponents';
import { formatDate } from '../../lib/utils';
import GenerateMove from './GenerateMove';
import { GlobalContext } from '../../store';

interface ProductLocationsTableProps {
  rows: IProductLocation[];
  onChangeRequest: (
    sort: SortProductLocation,
    rowsPerPage: number,
    page: number
  ) => Promise<void>;
  initialSort: SortProductLocation;
  initialPageSze: number;
  initialPage: number;
  totalRowCount: number;
  local: ILocal;
  children?: JSX.Element | JSX.Element[];
  onRefresh: () => void;
}

export default function ProductLocationsTable({
  rows,
  onChangeRequest,
  initialSort,
  initialPageSze,
  initialPage,
  totalRowCount,
  local,
  children,
  onRefresh,
}: ProductLocationsTableProps) {
  const { api } = React.useContext(GlobalContext);
  const [sort, setSort] = React.useState<SortProductLocation>(initialSort);
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [page, setPage] = React.useState(initialPage);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(initialPageSze);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [selectedProductLocation, setSelectedProductLocation] =
    React.useState<IProductLocation | null>(null);
  const [inactiveClientIds, setInactiveClientIds] = React.useState<number[]>(
    []
  );

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof IProductLocation
  ) => {
    const isAsc = sort.by === property && sort.order === 'asc';
    const newSort: SortProductLocation = {
      order: isAsc ? 'desc' : 'asc',
      by: property,
    };
    setSort(newSort);
    onChangeRequest(newSort, rowsPerPage, page);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setSelected([]);
    onChangeRequest(sort, rowsPerPage, newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    setSelected([]);
    onChangeRequest(sort, newRowsPerPage, 0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, rowsPerPage - rows.length) : 0;

  async function handleGenerateMove(id: number) {
    const productLocation = rows.find((row) => row.id === id);
    if (productLocation) {
      setSelectedProductLocation(productLocation);
      setModalOpen(true);
    }
  }

  interface HeadCell {
    disablePadding: boolean;
    id: keyof IProductLocation;
    label: string;
    type: 'string' | 'date' | 'number';
  }

  const handleCellFormat = (headCell: HeadCell, row: IProductLocation) => {
    if (!row[headCell.id]) {
      return 'N/A';
    }
    if (headCell.type === 'date') {
      return formatDate(row[headCell.id] as string);
    }
    return `${row[headCell.id]}`;
  };

  React.useEffect(() => {
    (async () => {
      const response = await api.getInactiveClients();
      setInactiveClientIds(response.map((element) => element.id));
    })();
  }, []);

  return (
    <>
      <GenerateMove
        open={modalOpen}
        setOpen={setModalOpen}
        productLocation={selectedProductLocation}
        onRefresh={onRefresh}
      />
      <div className="TableContainer">
        <Paper sx={{ width: '100%', mb: 2 }}>
          <ProductLocationsTableToolbar
            numSelected={selected.length}
            local={local}
          >
            {children}
          </ProductLocationsTableToolbar>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <ProductLocationsTableHead
                sort={sort}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {rows.map((row) => {
                  const isItemSelected = isSelected(row.id);
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      onClick={() => {
                        if (!inactiveClientIds.includes(row.ClientId)) {
                          handleGenerateMove(row.id);
                        }
                      }}
                    >
                      {headCells.slice(0).map((headCell, idx) => (
                        <TableCell
                          key={`tc-${idx}`}
                          align={headCell.type === 'number' ? 'right' : 'left'}
                        >
                          {handleCellFormat(headCell, row)}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={totalRowCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Ajustar contenido"
        />
      </div>
    </>
  );
}

import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { visuallyHidden } from '@mui/utils';
import { SortProductLocation, ILocal } from '../../interfaces';
import { capitalize } from '../../lib/utils';
import { IProductLocation } from '../../interfaces/IProductLocation';

interface HeadCell {
  disablePadding: boolean;
  id: keyof IProductLocation;
  label: string;
  type: 'string' | 'date' | 'number';
}

export const headCells: readonly HeadCell[] = [
  {
    id: 'sku',
    type: 'number',
    disablePadding: false,
    label: 'SKU',
  },
  {
    id: 'productName',
    type: 'string',
    disablePadding: false,
    label: 'Producto',
  },
  {
    id: 'locationName',
    type: 'string',
    disablePadding: false,
    label: 'Ubicacion',
  },
  {
    id: 'onHand',
    type: 'number',
    disablePadding: false,
    label: 'Cantidad',
  },
  {
    id: 'expirationDate',
    type: 'date',
    disablePadding: false,
    label: 'Fecha de Vencimiento',
  },
];

interface ProductLocationsTableHeadProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof IProductLocation
  ) => void;
  sort: SortProductLocation;
}

export function ProductLocationsTableHead(
  props: ProductLocationsTableHeadProps
) {
  const { sort, onRequestSort } = props;
  const createSortHandler =
    (property: keyof IProductLocation) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.type === 'number' ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={sort.by === headCell.id ? sort.order : false}
          >
            <TableSortLabel
              active={sort.by === headCell.id}
              direction={sort.by === headCell.id ? sort.order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {sort.by === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {sort.order === 'desc'
                    ? 'sorted descending'
                    : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface ProductLocationsTableToolbarProps {
  numSelected: number;
  local: ILocal;
  children?: JSX.Element | JSX.Element[];
}

export const ProductLocationsTableToolbar = (
  props: ProductLocationsTableToolbarProps
) => {
  const { numSelected, local, children } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <div className="OrdersTableToolbar">
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <div className="Left">
            <Typography variant="h6" id="tableTitle" component="div">
              Ubicaciones de productos de {capitalize(local.name)}
            </Typography>
            {children}
          </div>
        )}
      </div>
    </Toolbar>
  );
};

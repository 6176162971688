import { useState } from 'react';
import {
  BooleanInput,
  Create,
  DateTimeInput,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
} from 'react-admin';

export const ChargeRateValueCreate = () => {
  const [clientId, setClientId] = useState<number | null>(null);

  return (
    <Create>
      <SimpleForm>
        <ReferenceInput
          source="ClientId"
          reference="clients"
          sort={{ field: 'name', order: 'ASC' }}
          perPage={10000}
        >
          <SelectInput
            onChange={(e) => {
              setClientId(e.target.value);
            }}
            optionText="name"
          />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            !!formData.ClientId && (
              <>
                {!!clientId && (
                  <ReferenceInput
                    source="ChargeRateId"
                    reference="charge-rates"
                    filter={{ ClientId: clientId }}
                    sort={{ field: 'OrderTypeId', order: 'DESC' }}
                    perPage={10000}
                    {...rest}
                  >
                    <SelectInput optionText="name" />
                  </ReferenceInput>
                )}
              </>
            )
          }
        </FormDataConsumer>
        <NumberInput source="value" />
        <DateTimeInput source="startDate" />
        <DateTimeInput source="endDate" />
        <BooleanInput source="isScalonated" />
      </SimpleForm>
    </Create>
  );
};

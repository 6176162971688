import * as React from 'react';
import {
  Checkbox,
  Tooltip,
  alpha,
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Button,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { ILocal, ILossTableHead, SortLoss } from '../../interfaces';
import { capitalize } from '../../lib/utils';

export const headCells: readonly ILossTableHead[] = [
  {
    id: 'Product.sku',
    type: 'string',
    disablePadding: false,
    label: 'SKU',
  },
  {
    id: 'Product.name',
    type: 'string',
    disablePadding: false,
    label: 'Producto',
  },
  {
    id: 'reason',
    type: 'string',
    disablePadding: false,
    label: 'Razon',
  },
  {
    id: 'status',
    type: 'string',
    disablePadding: false,
    label: 'Estado',
  },
  {
    id: 'quantity',
    type: 'number',
    disablePadding: false,
    label: 'Cantidad',
  },
  {
    id: 'createdAt',
    type: 'date',
    disablePadding: false,
    label: 'Fecha de Creación',
  },
  {
    id: 'Lot.expirationDate',
    type: 'date',
    disablePadding: false,
    label: 'Fecha de Vencimiento',
  },
  {
    id: 'Withdrawal.createdAt',
    type: 'date',
    disablePadding: false,
    label: 'Fecha de Documento',
  },
];

interface LossesTableHeadProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  sort: SortLoss;
  rowCount: number;
}

export function LossesTableHead(props: LossesTableHeadProps) {
  const { numSelected, onRequestSort, onSelectAllClick, sort, rowCount } =
    props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.type === 'number' ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={sort.by === headCell.id ? sort.order : false}
          >
            <TableSortLabel
              active={sort.by === headCell.id}
              direction={sort.by === headCell.id ? sort.order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {sort.by === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {sort.order === 'desc'
                    ? 'sorted descending'
                    : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface LossesTableToolbarProps {
  numSelected: number;
  local: ILocal;
  children?: JSX.Element | JSX.Element[];
  makeWithdrawal: (type: string) => void;
  changeLossesStatus: (status: string) => void;
  selectedStatus: string;
}

export const LossesTableToolbar = (props: LossesTableToolbarProps) => {
  const { numSelected, local, children, makeWithdrawal, changeLossesStatus, selectedStatus } = props;

  return (
    <>
      <div className="Left">
        <Typography variant="h6" id="tableTitle" component="div">
          Mermas de {capitalize(local.name)}
        </Typography>
        {children}
      </div>
      {numSelected > 0 && (
        
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        
        <div className="OrdersTableToolbar">
          
          {numSelected > 0 && (
            <Typography
              sx={{ flex: '1 1 100%' }}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {numSelected} selected
            </Typography>
          )}
          {numSelected > 0 && selectedStatus === 'validated' && (
            <>
              <Tooltip title="Withdraw">
                <Button onClick={() => makeWithdrawal('nomad')}>
                  <Typography>Generar Retiro Nomad</Typography>
                </Button>
              </Tooltip>
              <Tooltip title="Withdraw">
                <Button onClick={() => makeWithdrawal('client')}>
                  <Typography>Generar Retiro Cliente</Typography>
                </Button>
              </Tooltip>
            </>
          )}
          {numSelected > 0 && selectedStatus === 'pending' && (
            <>
              <Tooltip title="Validate">
                <Button onClick={() => changeLossesStatus('validated')}>
                  <Typography>Validar</Typography>
                </Button>
              </Tooltip>
              <Tooltip title="Cancel">
                <Button onClick={() => changeLossesStatus('cancelled')}>
                  <Typography>Cancelar</Typography>
                </Button>
              </Tooltip>
            </>
          )}
        </div>
      </Toolbar>
      )}
    </>
  );
};

import Papa from 'papaparse';
import { useContext, useState, useEffect } from 'react';
import { GlobalContext } from '../../store';
import { IModel } from '../../interfaces/IModel';
import DropFile from '../../components/DropFile';
import { CsvUploader } from '../../lib/csvUploader';
import AlertDialog from '../../components/AlertDialog';
import IGetTaskStatus from '../../interfaces/IGetTaskStatus';
import { v4 as uuid } from 'uuid';
import {
  products,
  deliveryRates,
  paymentOrders,
  sizes,
  posProducts,
  stockMoves,
  shipments,
} from '../../lib/models';
import { Button } from '@mui/material';
import { capitalize } from '../../lib/utils';
import { parseAttributes, uploadFileToS3 } from '../../lib/massive';

const Massive = () => {
  const models = [
    products,
    deliveryRates,
    paymentOrders,
    sizes,
    posProducts,
    stockMoves,
    shipments,
  ];
  const { context, functionsApi, api } = useContext(GlobalContext);
  const [action, setAction] = useState<'create' | 'update'>('create');
  const [file, setFile] = useState<File | null>(null);
  const [selectedModel, setSelectedModel] = useState<IModel>(models[0]);
  const [rows, setRows] = useState<
    {
      [key: string]: string;
    }[]
  >([]);
  const [openModalAlert, setOpenModalAlert] = useState<boolean>(false);
  const [modalAlertTitle, setModalAlertTitle] = useState<string>('');
  const [modalAlertText, setModalAlertText] = useState<string>('');
  const [createItemsSubmitted, setCreateItemsSubmitted] =
    useState<boolean>(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] =
    useState<boolean>(true);
  const [inactiveClientIds, setInactiveClientIds] = useState<number[]>([]);

  const { startLoading, finishLoading } = context;

  let taskId: string | null = null;
  let retryTime = 20;
  let delayTime = 3000;

  function clearData() {
    setFile(null);
    setRows([]);
    setSubmitButtonDisabled(true);
    setCreateItemsSubmitted(true);
  }

  function handleModal(title: string, text: string) {
    setOpenModalAlert(true);
    setModalAlertTitle(title);
    setModalAlertText(text);
  }

  async function handleUploader(file: File) {
    setSubmitButtonDisabled(true);
    const uploader = new CsvUploader(file, selectedModel);
    await uploader.validate(handleModal, setRows, action);
  }

  async function getTaskResponse() {
    if (retryTime > 0) {
      const res: IGetTaskStatus = await functionsApi.getTasksStatus(
        taskId as string
      );
      if (res.status === 'FAILED') {
        const responseMessage = res.executions.message;
        handleModal('Error', responseMessage);
      } else if (res.status === 'SUCCESS') {
        handleModal(
          'Success',
          `${selectedModel?.name} Massive ${action} was succesful`
        );
      } else if (res.status === 'ERROR') {
        handleModal(
          'Error',
          `${selectedModel?.name} Massive ${action} was not found in the queue`
        );
      } else {
        setTimeout(getTaskResponse, delayTime);
        delayTime = 15000;
      }
      retryTime -= 1;
    } else {
      const res: IGetTaskStatus = await functionsApi.getTasksStatus(
        taskId as string
      );
      if (res.status === 'FAILED' || res.status === 'ERROR') {
        const responseMessage = res.executions?.message || 'Unexpected error';
        handleModal('Error', responseMessage);
      } else if (res.status === 'SUCCESS') {
        handleModal(
          'Success',
          `${selectedModel?.name} Massive ${action} was succesful`
        );
      } else {
        handleModal(
          'Notice',
          'Your request is still in process because you have uploaded a large number of items and it may take a few more minutes. You can continue to use the page while you wait and then check that the execution was successful. Thank you for your patience.'
        );
      }
    }
  }

  async function submitItems() {
    // Upload data to s3
    startLoading();
    const fileName = uuid();
    if (
      selectedModel.attributes.find((attr) => attr.name === 'ClientId') &&
      inactiveClientIds.includes(Number(rows[0].ClientId))
    ) {
      handleModal(
        'Error',
        `The client is inactive, you cannot create or edit ${selectedModel?.name}`
      );
      finishLoading();
      return;
    }
    const fileToUpload = JSON.stringify(
      parseAttributes(selectedModel.attributes, rows)
    );
    const res = await uploadFileToS3(fileToUpload, `massive/input/${fileName}`);
    if (res.includes('Error')) {
      finishLoading();
      console.log(
        `Error uploading file: An error occurred while uploading the file: ${res}, please retry`
      );
      handleModal(
        'Error uploading file',
        `An error occurred while uploading the file: ${res}, please retry.`
      );
    } else {
      const body = {
        queue: `models/${selectedModel?.name.toLowerCase()}`,
        event: action,
        pathName: selectedModel?.path,
        processedItemsIds: [],
        cursor: 0,
        lambdasAlreadyProcessed: 0,
        fileName: fileName,
      };
      clearData();
      try {
        taskId = await functionsApi.postEnqueueTask(body);
        finishLoading();
        if (taskId === null) {
          handleModal(
            'Error',
            `${selectedModel?.name} Massive ${action} could not be sent because of an unexpected error`
          );
        } else {
          getTaskResponse();
          handleModal(
            'Sent',
            'The task was sent correctly, you will be notified when it finishes in the next few minutes'
          );
        }
      } catch (error) {
        console.log('error', error);
        handleModal(
          'Error',
          `${selectedModel?.name} Massive ${action} could not be sent because of an unexpected error`
        );
      }
    }
  }

  function downloadCsvTemplate() {
    if (!selectedModel) return;
    let csvHeaders: string[] = [];
    if (action === 'create') {
      csvHeaders = selectedModel.attributes.map((attribute) => attribute.name);
    } else if (action === 'update') {
      csvHeaders = [
        'id',
        ...selectedModel.attributes.map((attribute) => attribute.name),
      ];
    }
    const csv = Papa.unparse({
      fields: csvHeaders,
      data: [],
    });
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `${selectedModel?.name}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  useEffect(() => {
    if (file) {
      handleUploader(file);
    }
  }, [file]);

  useEffect(() => {
    if (rows.length > 0) {
      setSubmitButtonDisabled(false);
    }
  }, [rows]);

  useEffect(() => {
    if (openModalAlert) {
      clearData();
    }
  }, [openModalAlert]);

  useEffect(() => {
    (async () => {
      const response = await api.getInactiveClients();
      setInactiveClientIds(response.map((element) => element.id));
    })();
  }, []);

  return (
    <div className="Onboarding">
      <AlertDialog
        title={modalAlertTitle}
        text={modalAlertText}
        open={openModalAlert}
        setOpen={setOpenModalAlert}
      />
      <div className="Title">Massive</div>
      <form className="OnboardingForm">
        <div className="InputWrapper">
          <div className="InputTitle">Elige el modelo</div>
          <select
            name="model"
            onChange={(e) => {
              setSelectedModel(
                models.find((model) => model.name === e.target.value)!
              );
              clearData();
            }}
          >
            {models.map((model) => (
              <option value={model.name}>{model.name}</option>
            ))}
          </select>
        </div>
        <div className="InputWrapper">
          <div className="InputTitle">Elige la accion</div>
          <select
            name="action"
            onChange={(e) => {
              setAction(e.target.value as 'create' | 'update');
              clearData();
            }}
          >
            <option value="create">Crear</option>
            <option value="update">Editar</option>
          </select>
        </div>
        <h3>
          {capitalize(action)} {selectedModel.name}
        </h3>
        <div className="InputWrapper">
          <DropFile
            changeHandler={setFile}
            createItemsSubmitted={createItemsSubmitted}
            setCreateItemsSubmitted={setCreateItemsSubmitted}
          />
        </div>
        <div className="InputWrapper">
          <Button variant="contained" onClick={downloadCsvTemplate}>
            Descargar plantilla
          </Button>
        </div>
        <div className="InputWrapper">
          <Button
            variant="contained"
            onClick={submitItems}
            disabled={submitButtonDisabled}
          >
            Enviar
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Massive;

import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useState } from 'react';
import { statusMappingFilters } from '../../lib/losses';

interface IStatusToggleButtonsProps {
  onChange: (newValue: string[]) => void;
}

export default function StatusToggleButtons({
  onChange,
}: IStatusToggleButtonsProps) {
  const [value, setValue] = useState<string>('all');

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: 'all' | 'pending' | 'validated' | 'withdrawn' | 'cancelled'
  ) => {
    const finalValue = newValue || 'all';
    setValue(finalValue);
    onChange(statusMappingFilters[finalValue]);
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={value}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
    >
      <ToggleButton value="all">Todas</ToggleButton>
      <ToggleButton value="pending">Pendientes</ToggleButton>
      <ToggleButton value="validated">Validadas</ToggleButton>
      <ToggleButton value="withdrawn">Retiradas</ToggleButton>
      <ToggleButton value="cancelled">Canceladas</ToggleButton>
    </ToggleButtonGroup>
  );
}

import {
  BooleanField,
  Datagrid,
  DateField,
  EmailField,
  List,
  NumberField,
  TextField,
  TextInput,
} from 'react-admin';
import { AdminPagination } from '../AdminLayout';

const clientFilters = [
  <TextInput label="Name" source="name" />,
  <TextInput label="Prefix" source="prefix" />,
];

export const ClientList = () => (
  <List filters={clientFilters} pagination={<AdminPagination />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="prefix" />
      <EmailField source="email" />
      <BooleanField source="canChangeStock" />
      <BooleanField source="makesDocument" />
      <TextField source="normalName" />
      <NumberField source="targetDaysSales" />
      <NumberField source="pickupWithdrawalWindow" />
      <NumberField source="pickupNotificationWindow" />
      <TextField source="nid" />
      <BooleanField source="usesWhatsapp" />
      <BooleanField source="hasB2B" />
      <NumberField source="tarifierFee" />
      <NumberField source="centralizationFee" />
      <NumberField source="packagingFee" />
      <NumberField source="freeShipmentPrice" />
      <BooleanField source="hasDynamicPromise" />
      <BooleanField source="active" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);

import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin';
import { AdminPagination } from '../AdminLayout';

const communeFilters = [
  <TextInput label="Name" source="name"></TextInput>,
  <ReferenceInput
    source="RegionId"
    reference="regions"
    sort={{ field: 'name', order: 'ASC' }}
  >
    <SelectInput optionText="name" />
  </ReferenceInput>,
];

export const CommuneList = () => (
  <List filters={communeFilters} pagination={<AdminPagination />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="normalName" />
      <ReferenceField source="RegionId" reference="regions">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);

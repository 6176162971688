import { useContext, useRef } from 'react';
import { IClient } from '../../interfaces';
import { alert } from '../../lib/utils';
import { GlobalContext } from '../../store';
import {
  IManageCredentials,
  IUpdateTokenDynamo,
} from '../../interfaces/IManageCredentials';

const Tokens = ({ client }: { client: IClient }) => {
  // Inputs
  const bsaleTokenRef = useRef<HTMLInputElement>(null);

  // Context
  const { context, functionsApi } = useContext(GlobalContext);
  const { startLoading, finishLoading } = context;

  // Handle submit
  async function handleSubmit() {
    if (!bsaleTokenRef.current?.value) {
      alert('error', 'Formulario incompleto');
      return;
    }
    const attributesToUpdate: IUpdateTokenDynamo = {};
    attributesToUpdate.bsaleToken = bsaleTokenRef.current?.value;
    const requestBody: IManageCredentials = {
      action: 'update',
      SK: 'credential',
      clientId: client.id,
      attributesToUpdate,
    };
    try {
      startLoading();
      await functionsApi.manageCredentials(requestBody);
      finishLoading();
      alert('success', 'El token se agregó correctamente');
    } catch (error) {
      finishLoading();
      alert('error', `El token no pudo ser agregado: ${error}`);
    }
  }

  return (
    <>
      <div className="InputWrapper">
        <div className="InputTitle">Token de Bsale</div>
        <input type="text" key="basale-token" ref={bsaleTokenRef} />
      </div>
      <div className="Button" onClick={handleSubmit}>
        Enviar
      </div>
    </>
  );
};

export default Tokens;

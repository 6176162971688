import { useContext, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import Loading from '../../components/Loading';
import { IExtendedLoss } from '../../interfaces/ILoss';
import { IApiResourceOptions, ILocal, SortLoss } from '../../interfaces';
import Back from '../../components/Back';
import { GlobalContext } from '../../store';
import LossesTable from '../../components/Losses/LossesTable';
import StatusToggleButtons from '../../components/Losses/StatusToggleButtons';
import ClientSearch from '../../components/Losses/ClientSearch';

const initialSort: SortLoss = { by: 'id', order: 'desc' };
const initialPageSize = 5;
const initialPage = 0;
type ContextType = { local: ILocal };

interface IFetchOptions {
  filter: { [key: string]: string | number | boolean | string[] };
  rowsPerPage: number;
  page: number;
  sort: {
    by: string;
    order: 'asc' | 'desc';
    model?: string | undefined;
  };
}

const Losses = () => {
  const { api } = useContext(GlobalContext);
  const { local } = useOutletContext<ContextType>();
  const [loading, setLoading] = useState<boolean>(false);
  const [losses, setLosses] = useState<IExtendedLoss[]>([]);
  const [lossesCount, setLossesCount] = useState<number>(0);
  const [fetchOptions, setFetchOptions] = useState<IFetchOptions>({
    filter: { LocalId: local.id },
    rowsPerPage: initialPageSize,
    page: initialPage,
    sort: initialSort,
  });
  const [filteredStatus, setFilteredStatus] = useState<string>('pending');

  useEffect(() => {
    fetchLosses();
  }, [fetchOptions]);

  async function fetchLosses() {
    setLoading(true);
    const sortParam = fetchOptions.sort.model
      ? [
          {
            [fetchOptions.sort.model]: {
              [fetchOptions.sort.by.split('.')[1]]: fetchOptions.sort.order,
            },
          },
        ]
      : [{ [fetchOptions.sort.by]: fetchOptions.sort.order }];
    const fetchParams: IApiResourceOptions = {
      filter: fetchOptions.filter,
      rowsPerPage: fetchOptions.rowsPerPage,
      page: fetchOptions.page,
      sort: sortParam,
    };
    const result = await api.getLosses(fetchParams);
    setLosses(result.losses);
    setLossesCount(result.count);
    setLoading(false);
  }

  function handleUpdateStatusFilter(newValue: string[]) {
    setFetchOptions({
      ...fetchOptions,
      filter: {
        ...fetchOptions.filter,
        status: newValue,
      },
    });
    setFilteredStatus(newValue[0]);
  }

  function handleUpdateClientsFilter(newValue: string[]) {
    setFetchOptions({
      ...fetchOptions,
      filter: {
        ...fetchOptions.filter,
        ClientId: newValue,
      },
    });
  }

  async function handleChangeRequest(
    sort: SortLoss,
    rowsPerPage: number,
    page: number
  ) {
    setFetchOptions({
      ...fetchOptions,
      sort,
      rowsPerPage,
      page,
    });
  }

  return (
    <>
      <div className="OrdersToolbar">
        <div className="Left">
          <Back home />
        </div>
      </div>
      <div>
        <LossesTable
          rows={losses}
          totalRowCount={lossesCount}
          onChangeRequest={handleChangeRequest}
          initialSort={initialSort}
          initialPageSze={initialPageSize}
          initialPage={initialPage}
          local={local}
          filteredStatus={filteredStatus}
        >
          <ClientSearch onChange={handleUpdateClientsFilter} />
          <StatusToggleButtons onChange={handleUpdateStatusFilter} />

          <>
            {loading && (
              <div>
                <Loading />
              </div>
            )}
          </>
        </LossesTable>
      </div>
    </>
  );
};

export default Losses;

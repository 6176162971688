import { useContext, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Loading from '../../components/Loading';
import { IOrder, IShipmentReceiverInfo } from '../../interfaces';
import CustomInputField from '../../components/Order/CustomInputField';
import EditButtons from '../../components/EditButtons';
import IdentifierInput from '../../components/Order/IdentifierInput';
import { GlobalContext } from '../../store';
import { alert } from '../../lib/utils';

type ContextType = { order: IOrder };

const OrderPickupDelivery = () => {
  const { api } = useContext(GlobalContext);
  const { order } = useOutletContext<ContextType>();
  const [receiverInfo, setReceiverInfo] = useState<
    Partial<IShipmentReceiverInfo>
  >({});
  const navigate = useNavigate();
  const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false);

  function isSaveDisabled(): boolean {
    return !(receiverInfo.name && receiverInfo.identifier);
  }

  async function handlePickupDeliverySave() {
    setIsRequestLoading(true);
    try {
      if (receiverInfo.name && receiverInfo.identifier) {
        const data = {
          name: receiverInfo.name,
          identifier: receiverInfo.identifier,
        };
        await api.createPickupDelivery(order.id, data);
        navigate('../');
        window.location.reload();
      }
    } catch (error) {
      if (error instanceof Error) {
        alert('error', error.message);
      }
    }
    setIsRequestLoading(false);
  }

  function setName(event: React.ChangeEvent<HTMLInputElement>) {
    setReceiverInfo({
      ...receiverInfo,
      name: event.target.value,
    });
  }

  function setIdentifier(identifier: string) {
    setReceiverInfo({
      ...receiverInfo,
      identifier,
    });
  }

  if (isRequestLoading) {
    return (
      <div>
        <br></br>
        <Loading />
      </div>
    );
  }

  return (
    <>
      <div className="FormBorder">
        <h2 className="Row Center">Ingresar datos del receptor</h2>
        <div className="OrderContainer Center">
          <form>
            <CustomInputField
              label="Nombre"
              editMode={true}
              onChange={setName}
              value={receiverInfo.name || ''}
            />
            <IdentifierInput label="Identifier" onChange={setIdentifier} />
            <EditButtons
              saveDisabled={isSaveDisabled()}
              editMode
              onCancel={() => navigate('../')}
              onSave={handlePickupDeliverySave}
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default OrderPickupDelivery;

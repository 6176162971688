import { Paper } from '@mui/material';
import { ChangeEvent, Dispatch, useContext, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import {
  IClient,
  ICommune,
  ICustomChangeEvent,
  ILocal,
  IOrder,
  IAddress,
  IPointOfSale,
  ICountry,
  IOrderType,
  ICourier,
} from '../../interfaces';
import { GlobalContext } from '../../store';
import { capitalize, formatDate } from '../../lib/utils';
import CustomInputField from './CustomInputField';
import { ISelectOptions } from './CustomSelectInput';
import { IRegion } from '../../interfaces/IRegion';

const orderInputKeys: { [key: string]: keyof (IOrder & IAddress) } = {
  ClienteInput: 'ClientId',
  TiendaInput: 'PointOfSaleId',
  OriginalIDInput: 'OriginalOrderId',
  TipoOrdenInput: 'OrderTypeId',
  MotivoDuplicadoInput: 'creationReason',
  CodeInput: 'code',
  'Plat.CodeInput': 'platformCode',
  NombreInput: 'customerName',
  TelefonoInput: 'customerPhone',
  EmailInput: 'customerEmail',
  TipoInput: 'shippingType',
  Direccion1Input: 'address1',
  Direccion2Input: 'address2',
  ComunaInput: 'CommuneId',
  Input: 'note',
  PrioridadInput: 'pickingPriority',
  CourierInput: 'CourierId',
};

const shippingTypeAvailableCouriers: { [key: string]: string[] } = {
  pickup: ['pickup'],
  express: ['pedidos ya', 'uber direct'],
  'same-day': ['cabify', 'fazt', 'flota nomad'],
  courier: ['alas express', 'blue express', 'qs logistics'],
  shopper: ['external'],
};

const creationReasonOptions: { [key: string]: ISelectOptions } = {
  nomad: [
    {
      value: 'picking-error',
      label: 'Error de pickeo',
      disabled: false,
    },
    {
      value: 'missing-products',
      label: 'Productos faltantes',
      disabled: false,
    },
    {
      value: 'nomad-broken-products',
      label: 'Productos rotos',
      disabled: false,
    },
    {
      value: 'nomad-fast-shipping',
      label: 'Envío rápido',
      disabled: false,
    },
    {
      value: 'internal-reason',
      label: 'Motivo interno',
      disabled: false,
    },
  ],
  client: [
    {
      value: 'client-fast-shipping',
      label: 'Envío rápido',
      disabled: false,
    },
  ],
  courier: [
    {
      value: 'courier-broken-products',
      label: 'Productos rotos',
      disabled: false,
    },
    {
      value: 'lost-products',
      label: 'Productos robados',
      disabled: false,
    },
  ],
}

type ContextType = { local: ILocal };

interface OrderDataProps {
  order: Partial<IOrder>;
  setOrderData: (orderData: Partial<IOrder>) => void;
  client: IClient | null;
  setClient: Dispatch<React.SetStateAction<IClient | null>>;
  editMode: boolean;
  children?: JSX.Element | JSX.Element[];
  operation: 'create' | 'edit';
  type?: 'new' | 'duplicate';
  onInputChange?: (
    event: ChangeEvent<HTMLInputElement> | ICustomChangeEvent
  ) => void;
  setGlobalDisabled?: Dispatch<React.SetStateAction<boolean>>;
  globalDisabled?: boolean;
  destinationLocal?: ILocal | null;
  setDestinationLocal?: Dispatch<React.SetStateAction<ILocal | null>>;
  selectedOrderType?: IOrderType | null;
  setSelectedOrderType?: Dispatch<React.SetStateAction<IOrderType | null>>;
  isInternalOrder: boolean;
}

const OrderData = (props: OrderDataProps) => {
  const { api } = useContext(GlobalContext);
  const {
    order,
    setOrderData,
    client,
    setClient,
    destinationLocal,
    setDestinationLocal,
    editMode,
    children,
    operation,
    type,
    onInputChange = () => undefined,
    globalDisabled = false,
    setGlobalDisabled,
    selectedOrderType = null,
    setSelectedOrderType = () => null,
    isInternalOrder,
  } = props;
  const { local } = useOutletContext<ContextType>();
  const inputsDisabled = operation === 'edit';
  const [pointOfSale, setPointOfSale] = useState<IPointOfSale | null>(null);
  const lastShipmentIdx = order.Shipments?.map((sh, idx) => [sh.id, idx]).reduce(
    (r, a) => (a[0] > r[0] ? a : r)
  )[1];
  const lastShipment = order.Shipments?.[lastShipmentIdx as number];
  const [courier, setCourier] = useState<ICourier | null>(null);
  const [country, setCountry] = useState<ICountry | null>(null);
  const [region, setRegion] = useState<IRegion | null>(null);
  const [commune, setCommune] = useState<ICommune | null>(null);
  const shippingType = order.shippingType;
  const [disabledRegions, setDisabledRegions] = useState<boolean>(true);
  const [disabledCommunes, setDisabledCommunes] = useState<boolean>(true);
  const regions = country?.Regions || [];
  const communes = region?.Communes || [];
  const [originalOrder, setOriginalOrder] = useState<IOrder | null>(null);
  const [code, setCode] = useState<string>(order.code || '');
  const [platformCode, setPlatformCode] = useState<string>(
    order.platformCode || ''
  );
  const [selectedClientId, setSelectedClientId] = useState<number | null>(null);
  const [creationReasons, setCreationReasons] = useState<ISelectOptions>([]);

  async function handleInputChange(
    event: ChangeEvent<HTMLInputElement> | ICustomChangeEvent
  ) {
    const dataToSet = {
      [orderInputKeys[event.target.id]]: event.target.value || null,
    };
    if (event.target.id === 'TipoInput') {
      // set commune if shipping type is not delivery
      setCourier(null);
      const selectedShippingType = String(event.target.value);
      if (
        selectedShippingType === 'pickup' ||
        selectedShippingType === 'shopper'
      ) {
        delete order.shippingAddress;
        delete order.CourierId; 
      }
      
      setOrderData(dataToSet);
    } else if (event.target.id === 'ComunaInput') {
      // set commune with commune id (specific case)
      const selectedCommune = (event as ICustomChangeEvent).target
        .item as ICommune;
      setOrderData({
        shippingAddress: {
          ...order.shippingAddress,
          commune: selectedCommune?.name,
        },
        CommuneId: selectedCommune?.id,
      });
      setCommune(selectedCommune as ICommune);
    } else if (event.target.id.includes('CourierInput')) {
      const selectedCourier = (event as ICustomChangeEvent).target.item as ICourier;
      setOrderData({
        CourierId: selectedCourier?.id,
      });
      setCourier(selectedCourier as ICourier);
    } else if (event.target.id.includes('Direccion')) {
      setOrderData({
        shippingAddress: {
          ...order.shippingAddress,
          ...dataToSet,
        },
      });
    } else if (event.target.id.includes('País')) {
      const selectedCountry = (event as ICustomChangeEvent).target
        .item as ICountry;
      setCountry(selectedCountry);
      setCommune(null);
      setRegion(null);
      if (selectedCountry) {
        setDisabledRegions(false);
      } else {
        setDisabledRegions(true);
        setDisabledCommunes(true);
      }
    } else if (event.target.id.includes('Región')) {
      const selectedRegion = (event as ICustomChangeEvent).target
        .item as IRegion;
      setRegion(selectedRegion);
      setCommune(null);
      selectedRegion ? setDisabledCommunes(false) : setDisabledCommunes(true);
    } else if (event.target.id === 'OriginalIDInput') {
      const selectedOrder = (event as ICustomChangeEvent).target.item as IOrder;
      setCode(selectedOrder.code);
      setPlatformCode(selectedOrder.platformCode);
      dataToSet.code = selectedOrder.code;
      dataToSet.platformCode = selectedOrder.platformCode;
      dataToSet.PointOfSaleId = selectedOrder.PointOfSaleId;
      setOrderData(dataToSet);
    } else if (event.target.id === 'ResponsableDuplicadoInput') {
      setCreationReasons(creationReasonOptions[event.target.value as string])
    } else if (event.target.id === 'ClienteInput') {
      setSelectedClientId(Number(dataToSet.ClientId));
      setOrderData(dataToSet);
    } else if (event.target.id === 'TipoOrdenInput' && setSelectedOrderType) {
      setSelectedOrderType((event as ICustomChangeEvent).target.item as IOrderType);
      setOrderData(dataToSet);
    } else {
      // general case
      setOrderData(dataToSet);
    }
    // trigger event
    onInputChange(event);
  }

  async function getClientPOS(): Promise<IPointOfSale[]> {
    if (client) {
      return api.getPOS(client.id);
    }
    return [];
  }

  async function getLocalById(id: number | string): Promise<ILocal> {
    const locals = await api.getLocals();
    return locals.find((local) => local.id === id) as ILocal;
  }

  function selectOptions(): ISelectOptions {
    if (selectedOrderType?.name === 'WO') {
      return [
        {
          value: 'pickup',
          disabled: false,
        },
        {
          value: 'same-day',
          disabled: false,
        },
      ];
    }
    return ['pickup', 'express', 'same-day', 'courier', 'shopper']
      .map((x) => ({ value: x, disabled: false }))
  }
  useEffect(() => {
    if (editMode) {
      setCountry(null);
      setRegion(null);
      setCommune(null);
    }
  }, [editMode]);


  // Define global disable according to required data by order type
  useEffect(() => {
    if (!selectedOrderType || !setGlobalDisabled) return;
    if (selectedOrderType.name === 'TO' && selectedClientId) {
      setGlobalDisabled(false);
    } else if (selectedOrderType.name === 'WO' && selectedClientId) {
      setGlobalDisabled(false);
    } else if (selectedClientId) {
      setGlobalDisabled(false);
    } else {
      setGlobalDisabled(true);
    }
  }, [selectedOrderType, selectedClientId]);

  // Set platform code and code
  useEffect(() => {
    // reset values when changes orderType
    const resetData = {
      shippingType: '',
      date: undefined,
      shippingCost: 0,
      globalDiscount: 0,
      shippingDiscount: 0,
      total: 0,
      OrderDetails: [],
      customerEmail: '',
      customerName: '',
      customerPhone: '',
    };
    setCommune(null);
    setRegion(null);
    setCourier(null);

    if ((code && platformCode) || !selectedOrderType) return;
    let prefix = 'NOMAD-';
    switch (selectedOrderType.name) {
      case 'TO':
        prefix = 'TO-';
        setOrderData({ ...resetData, code: prefix, platformCode: prefix });
        break;
      case 'WO':
        prefix = 'WO-';
        setOrderData({ ...resetData, shippingType: 'pickup', code: prefix, platformCode: prefix });
        break;
      default:
        setOrderData({ ...resetData, code: prefix, platformCode: prefix });
        break;
    }
  }, [selectedOrderType]);

  return (
    <div id="OrderData">
      <Paper className="Paper" elevation={2}>
        {operation === 'create' ? (
          <h2>Crear pedido</h2>
        ) : (
          <h2>{order.internalCode || order.id}</h2>
        )}
        <CustomInputField
          disabled
          label="MFC"
          value={capitalize(local.name)}
          editMode={false}
          onChange={handleInputChange}
        />
        {operation === 'edit' && (
          <>
            <CustomInputField
              disabled
              label="Fecha"
              value={formatDate(order.date as string)}
              editMode={false}
              onChange={handleInputChange}
              upperCase
            />
            <CustomInputField
              disabled
              label="Status"
              value={order.status}
              editMode={false}
              onChange={handleInputChange}
              upperCase
            />
          </>
        )}
        <CustomInputField
          disabled={inputsDisabled}
          label="Tipo Orden"
          dataKey="name"
          editMode={editMode}
          onChange={handleInputChange}
          inputType="autocomplete"
          autocompleteDefaultValue={selectedOrderType}
          value={selectedOrderType?.name}
          fetchData={async () => api.getOrderTypes({ filter: { name: ['B2C', 'B2B', 'TO', 'WO'] } })}
          dataFieldProps={{
            data: selectedOrderType,
            getData: () => api.getOrderTypeById(order.OrderTypeId),
            setData: setSelectedOrderType,
          }}
        />
        <CustomInputField
          disabled={inputsDisabled}
          label="Cliente"
          dataKey="name"
          editMode={editMode}
          onChange={handleInputChange}
          inputType="autocomplete"
          autocompleteDefaultValue={client}
          value={client?.name}
          fetchData={async () => api.getClients({ active: true })}
          dataFieldProps={{
            data: client,
            getData: async () => api.getClientById(order.ClientId),
            setData: setClient,
          }}
        />
        <CustomInputField
          inputType="checkbox"
          label="Prioridad"
          value={String(order.pickingPriority)}
          editMode={editMode}
          onChange={handleInputChange}
          disabled={globalDisabled}
        />
        {selectedOrderType && selectedOrderType.name === 'TO' && operation === 'create' && (
          <>
            <h3>Destino</h3>
            <CustomInputField
              disabled={inputsDisabled || globalDisabled}
              label="Destino"
              dataKey="name"
              editMode={editMode}
              onChange={handleInputChange}
              inputType="autocomplete"
              autocompleteDefaultValue={destinationLocal as ILocal}
              fetchData={async () =>
                (await api.getLocals({ filter: { active: true } })).filter((x) => x.id !== local.id)
              }
              dataFieldProps={{
                data: destinationLocal as ILocal,
                getData: async () => getLocalById(destinationLocal?.id || ''),
                setData: setDestinationLocal as Dispatch<React.SetStateAction<ILocal | null>>,
              }}
            />
          </>
        )}

        {operation === 'create' && type === 'duplicate' && (
          <>
            <h3>Interno</h3>
            <CustomInputField
              label="Original ID"
              dataKey="referenceCode"
              editMode={editMode}
              onChange={handleInputChange}
              inputType="autocomplete"
              autocompleteDefaultValue={originalOrder}
              value={originalOrder?.internalCode}
              fetchData={async () => {
                if (!selectedOrderType) return [];
                const data = await api.getOrders({
                  filter: { ClientId: order.ClientId as number, OrderTypeId: selectedOrderType.id },
                });
                return data.orders.map((o) => ({
                  ...o,
                  referenceCode: o.internalCode || o.id,
                }));
              }}
              dynamicFetchData={async (referenceCode: string) => {
                if (!selectedOrderType) return [];
                const data = await api.getOrders({
                  searchValue: referenceCode,
                  filter: { ClientId: order.ClientId as number, OrderTypeId: selectedOrderType.id },
                });
                return data.orders.map((o) => ({
                  ...o,
                  referenceCode: o.internalCode || o.id,
                }));
              }}
              dataFieldProps={{
                data: null,
                getData: null as unknown as () => Promise<IOrder>,
                setData: setOriginalOrder,
              }}
              disabled={globalDisabled}
            />
            <CustomInputField
              inputType="select"
              editMode={editMode}
              disabled={globalDisabled}
              value=''
              label="Responsable Duplicado"
              onChange={handleInputChange}
              selectOptions={[
                {
                  value: 'nomad',
                  label: 'Nomad',
                  disabled: false,
                },
                {
                  value: 'client',
                  label: 'Cliente',
                  disabled: false,
                },
                {
                  value: 'courier',
                  label: 'Courier',
                  disabled: false,
                },
              ]}
            />
            <CustomInputField
              inputType="select"
              editMode={editMode}
              disabled={!creationReasons.length}
              value={order.creationReason || ''}
              label="Motivo Duplicado"
              onChange={handleInputChange}
              selectOptions={creationReasons}
            />
          </>
        )}

        {selectedOrderType && !isInternalOrder && type !== 'duplicate' && (
          <>
            <h3>Tienda</h3>
            <CustomInputField
              disabled={globalDisabled}
              label="Tienda"
              dataKey="name"
              editMode={editMode}
              onChange={handleInputChange}
              inputType="autocomplete"
              autocompleteDefaultValue={pointOfSale}
              fetchData={getClientPOS}
              dataFieldProps={{
                data: pointOfSale,
                getData: () => api.getPointOfSaleById(order.PointOfSaleId),
                setData: setPointOfSale,
              }}
            />
          </>
        )}

        {selectedOrderType && selectedOrderType.name !== 'TO' && (
          <>
            <h3>Consumidor Final</h3>
            <CustomInputField
              label="Nombre"
              value={order.customerName}
              editMode={editMode}
              onChange={handleInputChange}
              disabled={globalDisabled}
            />
            <CustomInputField
              label="Telefono"
              value={order.customerPhone}
              editMode={editMode}
              onChange={handleInputChange}
              disabled={globalDisabled}
            />
            <CustomInputField
              label="Email"
              value={order.customerEmail}
              editMode={editMode}
              onChange={handleInputChange}
              disabled={globalDisabled}
            />
          </>
        )}
        {(operation === 'edit' || (operation === 'create' && selectedOrderType && selectedOrderType.name !== 'TO')) && (
          <>
            <h3>Envío</h3>
            <CustomInputField
              disabled={inputsDisabled || globalDisabled}
              label="Tipo"
              inputType="select"
              value={order.shippingType || ''}
              editMode={editMode}
              onChange={handleInputChange}
              selectOptions={selectOptions()}
            />
            {selectedOrderType && ['B2C', 'B2B'].includes(selectedOrderType.name) && shippingType && (
              <CustomInputField
                disabled={inputsDisabled}
                label="Courier"
                dataKey="name"
                editMode={editMode}
                onChange={handleInputChange}
                inputType="autocomplete"
                autocompleteDefaultValue={courier}
                value={courier?.name}
                fetchData={async () => api.getCouriers({name: shippingTypeAvailableCouriers[shippingType]})}
                dataFieldProps={{
                  data: courier,
                  getData: () => api.getCourierById(lastShipment?.CourierId),
                  setData: setCourier,
                }}
              />
            )}
            {shippingType !== 'pickup' && shippingType !== 'shopper' && (
              <>
                <CustomInputField
                  label="Direccion 1"
                  value={order.shippingAddress?.address1 || ''}
                  editMode={editMode}
                  onChange={handleInputChange}
                  disabled={globalDisabled}
                />
                <CustomInputField
                  label="Direccion 2"
                  value={order.shippingAddress?.address2 || ''}
                  editMode={editMode}
                  onChange={handleInputChange}
                  disabled={globalDisabled}
                />
                {editMode && (
                  <>
                    <CustomInputField
                      label="País"
                      dataKey="name"
                      editMode={editMode}
                      onChange={handleInputChange}
                      inputType="autocomplete"
                      autocompleteDefaultValue={country}
                      value={country?.name}
                      fetchData={async () => api.getCountries()}
                      dataFieldProps={{
                        data: null,
                        getData: null as unknown as () => Promise<ICountry>,
                        setData: setCountry,
                      }}
                      disabled={globalDisabled}
                    />
                    <CustomInputField
                      label="Región"
                      dataKey="name"
                      editMode={editMode}
                      onChange={handleInputChange}
                      inputType="autocomplete"
                      autocompleteDefaultValue={region}
                      fetchData={async () => regions}
                      dataFieldProps={{
                        data: null,
                        getData: null as unknown as () => Promise<IRegion>,
                        setData: setRegion,
                      }}
                      disabled={disabledRegions}
                    />
                  </>
                )}

                <CustomInputField
                  label="Comuna"
                  dataKey="name"
                  editMode={editMode}
                  onChange={handleInputChange}
                  inputType="autocomplete"
                  autocompleteDefaultValue={commune}
                  fetchData={async () => communes}
                  dataFieldProps={{
                    data: editMode ? null : commune,
                    getData: () => api.getCommuneById(order.CommuneId),
                    setData: setCommune,
                  }}
                  disabled={disabledCommunes}
                />
              </>
            )}
          </>
        )}
        <h3>Nota</h3>
        <CustomInputField
          inputType="textarea"
          label=""
          editMode={editMode}
          onChange={handleInputChange}
          value={order.note}
          disabled={globalDisabled}
        />
        {children}
      </Paper>
    </div>
  );
};

export default OrderData;

import {
  DateField,
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from 'react-admin';
import { transformInput } from '../../../lib/utils';

export const RegionEdit = () => (
  <Edit transform={transformInput}>
    <SimpleShowLayout>
      <TextField source="id" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </SimpleShowLayout>
    <SimpleForm>
      <TextInput source="name" />
      <ReferenceInput source="CountryId" reference="countries">
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

import {
    Datagrid,
    List,
    ReferenceField,
    TextField,
  } from 'react-admin';

  import { AdminPagination } from '../AdminLayout';

export const JourneyList = () => (
    <List pagination={<AdminPagination />}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <ReferenceField source="DriverId" reference="drivers">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="startTime" />
        <TextField source="finishTime" />
        <TextField source="status" />
        <ReferenceField source="LocalId" reference="locals">
          <TextField source="name" />
        </ReferenceField>

      </Datagrid>
    </List>
  );
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import {
  IClient,
  ILocal,
  IPointOfSale,
  IVTEXSalesChannel,
  IVTEXWarehouse,
} from '../../interfaces';
import { alert, capitalize } from '../../lib/utils';
import { GlobalContext } from '../../store';
import { Switch } from '@mui/material';

const VTEXLocal = ({ client }: { client: IClient }) => {
  const [pos, setPos] = useState<IPointOfSale[]>([]);
  const [pointOfSale, setPointOfSale] = useState<IPointOfSale | null>(null);
  const [locals, setLocals] = useState<ILocal[]>([]);
  const [local, setLocal] = useState<ILocal>();
  const [newWarehouse, setNewWarehouse] = useState<boolean>(false);
  const [warehouses, setWarehouses] = useState<IVTEXWarehouse[]>([]);
  const [warehouseId, setWarehouseId] = useState<string | null>(null);
  const [salesChannels, setSalesChannels] = useState<IVTEXSalesChannel[]>([]);
  const [salesChannel, setSalesChannel] = useState<IVTEXSalesChannel | null>(
    null
  );

  // Context
  const { context, api } = useContext(GlobalContext);
  const { startLoading, finishLoading } = context;

  // Load Resources
  useEffect(() => {
    (async () => {
      startLoading();
      const pos = await api.getPOS(client.id);
      setPos(pos);
      const clientLocals = await api.getClientLocals(client.id);
      setLocals(clientLocals.map((clientLocal) => clientLocal.Local));
      finishLoading();
    })();
  }, [client]);

  useEffect(() => {
    (async () => {
      startLoading();
      if (pointOfSale) {
        const warehouses = await api.getVTEXWarehouses(pointOfSale.id);
        setWarehouses(warehouses);
        const salesChannels = await api.getVTEXSalesChannels(pointOfSale.id);
        setSalesChannels(salesChannels);
      }
      finishLoading();
    })();
  }, [pointOfSale]);

  // Handle submit
  async function handleSubmit() {
    if (!client || !local || !pointOfSale || !warehouseId) {
      alert('error', 'Formulario incompleto');
      return;
    }

    try {
      startLoading();
      const data = {
        localId: local.id,
        externalId: warehouseId || '',
        salesChannelId: salesChannel?.Id || '',
      };
      await api.configureVTEXLocal(pointOfSale.id, data);
      finishLoading();
      alert('success', 'Se ha configurado el local correctamente');
    } catch (error) {
      finishLoading();
      if (axios.isAxiosError(error)) {
        alert(
          'error',
          `Ocurrió un error: "${
            (error.response?.data as { message: string }).message
          }"`
        );
      } else {
        alert('error', 'Ocurrió un error: error desconocido');
      }
    }
  }

  return (
    <>
      <div className="InputWrapper">
        <div className="InputTitle">POS</div>
        <select
          name="pos"
          onChange={(e) => setPointOfSale(pos[parseInt(e.target.value, 10)])}
        >
          <option value="">Selecciona un POS</option>
          {pos.map((pos, idx) => (
            <option value={idx} key={pos.id}>
              {capitalize(pos.name)}
            </option>
          ))}
        </select>
      </div>
      {!!pointOfSale && (
        <>
          <div className="InputWrapper">
            <div className="InputTitle">Local</div>
            <select
              name="client"
              onChange={(e) => setLocal(locals[parseInt(e.target.value, 10)])}
            >
              <option value={local?.name || ''}>Selecciona un local</option>
              {locals.map((local, idx) => (
                <option value={idx} key={local.id}>
                  {capitalize(local.name)}
                </option>
              ))}
            </select>
          </div>
          <div className="InputWrapper">
            <div className="InputTitle">Canal de Venta</div>
            <select
              name="salesChannel"
              onChange={(e) =>
                setSalesChannel(salesChannels[parseInt(e.target.value, 10)])
              }
            >
              <option value={salesChannel?.Name || ''}>
                Selecciona un canal de venta
              </option>
              {salesChannels.map((saleChannel, idx) => (
                <option value={idx} key={saleChannel.Id}>
                  {capitalize(saleChannel.Name)}
                </option>
              ))}
            </select>
          </div>
          <div className="InputWrapper">
            <div className="InputTitle">Warehouse</div>
            <div className="InputText">
              Crear nuevo
              <Switch
                checked={newWarehouse}
                onClick={() => {
                  if (!newWarehouse) {
                    setWarehouseId(null);
                  }
                  setNewWarehouse(!newWarehouse);
                }}
              />
            </div>
            {!newWarehouse ? (
              <select
                name="warehouse"
                onChange={(e) =>
                  setWarehouseId(warehouses[parseInt(e.target.value, 10)].id)
                }
              >
                <option value="">Seleccionar Warehouse</option>
                {warehouses.map((warehouse, idx) => (
                  <option value={idx} key={warehouse.id}>
                    {capitalize(warehouse.name)}
                  </option>
                ))}
              </select>
            ) : (
              <div className="InputWrapper">
                <div className="InputTitle">External Warehouse ID</div>
                <input
                  type="text"
                  onChange={(e) => setWarehouseId(e.target.value)}
                />
              </div>
            )}
          </div>
          <div
            className={`Button ${
              !client || !local || !warehouseId ? 'disabled' : ''
            }`}
            onClick={handleSubmit}
          >
            Enviar
          </div>
        </>
      )}
    </>
  );
};

export default VTEXLocal;

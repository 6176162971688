import { Create, SimpleForm, TextInput } from 'react-admin';
import { transformInput } from '../../../lib/utils';

export const PermissionCreate = () => (
  <Create transform={transformInput}>
    <SimpleForm>
      <TextInput source="model" />
      <TextInput source="access" />
    </SimpleForm>
  </Create>
);

import * as React from 'react';
import {
  Table,
  TableBody,
  TablePagination,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  FormControlLabel,
  Switch,
  Button,
  Checkbox,
} from '@mui/material';
import {
  ILocal,
  IExtendedLoss,
  ILossTableHead,
  IProduct,
  SortLoss,
} from '../../interfaces';
import {
  headCells,
  LossesTableHead,
  LossesTableToolbar,
} from './LossesComponents';
import { useContext, useMemo } from 'react';
import { GlobalContext } from '../../store';
import { alert, formatDate } from '../../lib/utils';
import { IWithdrawal } from '../../interfaces/IWithdrawal';
import { ILot } from '../../interfaces/ILot';

interface ProductLocationsTableProps {
  rows: IExtendedLoss[];
  onChangeRequest: (
    sort: SortLoss,
    rowsPerPage: number,
    page: number
  ) => Promise<void>;
  initialSort: SortLoss;
  initialPageSze: number;
  initialPage: number;
  totalRowCount: number;
  local: ILocal;
  filteredStatus: string;
  children?: JSX.Element | JSX.Element[];
}

export default function LossesTable({
  rows,
  onChangeRequest,
  initialSort,
  initialPageSze,
  initialPage,
  totalRowCount,
  local,
  filteredStatus,
  children,
}: ProductLocationsTableProps) {
  const { api } = useContext(GlobalContext);
  const [sort, setSort] = React.useState<SortLoss>(initialSort);
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [selectedStatus, setSelectedStatus] = React.useState<string>('');
  const [page, setPage] = React.useState(initialPage);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(initialPageSze);
  const [inactiveClientIds, setInactiveClientIds] = React.useState<number[]>(
    []
  );

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = sort.by === property && sort.order === 'asc';
    let model;
    if (property === 'Product.sku' || property === 'Product.name') {
      model = 'Product';
    } else if (property === 'Lot.expirationDate') {
      model = 'Lot';
    } else if (property === 'Withdrawal.createdAt') {
      model = 'Withdrawal';
    }
    const newSort: SortLoss = {
      order: isAsc ? 'desc' : 'asc',
      by: property,
      model,
    };
    setSort(newSort);
    onChangeRequest(newSort, rowsPerPage, page);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setSelected([]);
    setSelectedStatus('');
    onChangeRequest(sort, rowsPerPage, newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    setSelected([]);
    setSelectedStatus('');
    onChangeRequest(sort, newRowsPerPage, 0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = useMemo<number>(
    () => (page > 0 ? Math.max(0, rowsPerPage - rows.length) : 0),
    [page, rowsPerPage]
  );

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const statusToFilter = selectedStatus || filteredStatus;
    const rowsOfType = rows.filter(row => row.status === statusToFilter && !['withdrawn', 'cancelled'].includes(row.status));
  
    if (event.target.checked) {
      const allSelected = rowsOfType.every(row => selected.includes(row.id));
      
      if (allSelected) {
        // Deselect all rows of the current type
        const newSelected = selected.filter(id => !rowsOfType.some(row => row.id === id));
        setSelected(newSelected);
        setSelectedStatus('');
      } else {
        // Select all rows of the current type
        const newSelected = rowsOfType.map(row => row.id);
        setSelected(newSelected);
        if (newSelected.length === 0) {
          setSelectedStatus('');
        } else {
          setSelectedStatus(statusToFilter);
        }
      }
    } else {
      // Deselect all
      setSelected([]);
      setSelectedStatus('');
    }
  };
  

  const handleWithdrawal = async (type: string) => {
    try {
      await api.makeWithdrawal({
        losses: selected,
        type,
      });
      window.location.reload();
    } catch (error) {
      if (error instanceof Error) {
        alert('error', error.message);
      }
    }
  };

  const handleChangeLossesStatus = async (status: string) => {
    try {
      await api.changeLossesStatus({ losses: selected, status });
      window.location.reload();
    } catch (error) {
      if (error instanceof Error) {
        alert('error', error.message);
      }
    }
  }

  const handleClick = (event: React.MouseEvent<unknown>, id: number, status: string) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    if (newSelected.length === 0) {
      setSelectedStatus('');
    } else {
      setSelectedStatus(status);
    }
  };

  const getDocument = async (id: number) => {
    const loss = rows.find((row) => row.id === id);
    if (loss?.Withdrawal?.url) {
      window.open(loss?.Withdrawal?.url);
    }
  };

  const handleCellFormat = (headCell: ILossTableHead, row: IExtendedLoss) => {
    const key = headCell.id;
    if (key.includes('.')) {
      const [model, attr] = key.split('.');
      const rowData = row[model as keyof IExtendedLoss] as keyof IWithdrawal &
        ILot &
        IProduct;
      if (rowData && typeof rowData === 'object') {
        if (attr in rowData) {
          const value = rowData[attr];
          return headCell.type === 'date'
            ? formatDate(value as string)
            : String(value);
        }
      }
    } else {
      const value = row[key as keyof IExtendedLoss];
      if (value != null) {
        return headCell.type === 'date'
          ? formatDate(value as string)
          : String(value);
      }
    }
    return 'N/A';
  };

  React.useEffect(() => {
    (async () => {
      const response = await api.getInactiveClients();
      setInactiveClientIds(response.map((element) => element.id));
    })();
  }, []);

  return (
    <>
      <div className="TableContainer">
        
        <Paper sx={{ width: '100%', mb: 2 }}>
          
          <LossesTableToolbar
            numSelected={selected.length}
            local={local}
            makeWithdrawal={handleWithdrawal}
            changeLossesStatus={handleChangeLossesStatus}
            selectedStatus={selectedStatus}
          >
            {children}
          </LossesTableToolbar>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <LossesTableHead
                numSelected={selected.length}
                sort={sort}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={
                  rows.filter(
                    (row) =>
                      row.status !== 'withdrawn'
                  ).length
                }
              />
              <TableBody>
                {rows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          onClick={(event) => handleClick(event, row.id, row.status)}
                          color="primary"
                          checked={isItemSelected}
                          disabled={
                            ['withdrawn', 'cancelled'].includes(
                              row.status
                            ) || inactiveClientIds.includes(row.ClientId)
                              || (!!selectedStatus && selectedStatus !== row.status)
                          }
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      {headCells.slice(0).map((headCell, idx) => (
                        <TableCell
                          key={`tc-${idx}`}
                          align={headCell.type === 'number' ? 'right' : 'left'}
                        >
                          {handleCellFormat(headCell, row)}
                        </TableCell>
                      ))}
                      <TableCell align="right" scope="row">
                        {row.status === 'withdrawn' && (
                          <Button
                            variant="contained"
                            color="primary"
                            size="medium"
                            sx={{ ml: 2 }}
                            onClick={() => getDocument(row.id)}
                          >
                            Documento de Retiro
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={totalRowCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Ajustar contenido"
        />
      </div>
    </>
  );
}

import {
  BooleanInput,
  DateField,
  DateTimeInput,
  Edit,
  NumberField,
  NumberInput,
  SimpleForm,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import { transformInput } from '../../../lib/utils';

export const ChargeRateValueEdit = () => (
  <Edit transform={transformInput}>
    <SimpleShowLayout>
      <TextField source="id" />
      <NumberField source="ChargeRateId" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </SimpleShowLayout>
    <SimpleForm>
      <NumberInput source="value" />
      <DateTimeInput source="startDate" />
      <DateTimeInput source="endDate" />
      <BooleanInput source="isScalonated" />
    </SimpleForm>
  </Edit>
);

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const SignOut = () => {
  const { signOut } = useAuthenticator();
  const navigate = useNavigate();

  return (
    <Button
      variant="contained"
      className="Button"
      onClick={() => {
        navigate('/');
        signOut();
      }}
    >
      Cerrar Sesión
    </Button>
  );
};

export default SignOut;

import * as React from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';

export const Dashboard = () => {
  return (
    <Card>
      <img src="/logo192.png" alt="random" />
      <CardHeader title="Bienvenido al panel de Admin Nomad!" />
      <CardContent>
        <br />
        La siguiente página consiste en un panel de admin para la plataforma
        nomad, este permite tanto leer, crear, editar y borrar recursos de la
        plataforma. Cualquier sugerencia o funcionalidad que le pueda faltar al
        panel para una mejor administración de la plataforma porfavor dejar una
        Issue en el siguiente link de{' '}
        <span>
          <a href="https://github.com/NomadDevelopment/platform-admin/issues">
            issues
          </a>
        </span>
        .
        <br />
        <br />
        A continuación un listado de funcionalidades que se pueden implementar
        de manera más sencilla en caso de ser necesarias: <br />
        <ul>
          <li>Agregar filtros por tipo de dato</li>
          <li>Añadir más campos que estén disponibles en la plataforma</li>
          <li>
            Anidar recursos en una misma vista para que estos se vean de manera
            más "lógica" con respecto al negocio
          </li>
        </ul>
      </CardContent>
    </Card>
  );
};

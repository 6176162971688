import {
  BooleanField,
  Datagrid,
  DateField,
  EmailField,
  List,
  NumberField,
  ReferenceField,
  TextField,
} from 'react-admin';
import { JsonField } from 'react-admin-json-view';
import { AdminPagination } from '../AdminLayout';

export const LocalList = () => (
  <List pagination={<AdminPagination />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <EmailField source="email" />
      <JsonField
        source="address"
        jsonString={false} // Set to true if the value is a string, default: false
        reactJsonOptions={{
          // Props passed to react-json-view
          name: null,
          collapsed: true,
          enableClipboard: false,
          displayDataTypes: true,
        }}
      />
      <NumberField source="latitude" />
      <NumberField source="longitude" />
      <NumberField source="pickingRate" />
      <ReferenceField reference="communes" source="CommuneId">
        <TextField source="name" />
      </ReferenceField>
      <BooleanField source="allowsStacking" />
      <TextField source="pedidosYaToken" />
      <TextField source="stockSourceId" />
      <TextField source="phone" />
      <BooleanField source="active" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);

import {
  ArrayInput,
  BooleanInput,
  DateField,
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  SimpleShowLayout,
  TextField,
  TextInput,
} from 'react-admin';
import { transformInput } from '../../../lib/utils';
import { JsonInput } from 'react-admin-json-view';

export const OpeningHourEdit = () => (
  <Edit transform={transformInput}>
    <SimpleShowLayout>
      <TextField source="id" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </SimpleShowLayout>
    <SimpleForm>
      <ReferenceInput source="LocalId" reference="locals" perPage={1000} filter={{ active: true }}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="openTime" />
      <TextInput source="closeTime" />
      <JsonInput
        source="limitTimes"
        defaultValue={{
          express: '',
          pickup: '',
          courier: '',
          ['same-day']: '',
        }}
        reactJsonOptions={{
          // Props passed to react-json-view
          name: null,
          collapsed: false,
          enableClipboard: false,
          displayDataTypes: true,
        }}
      />
      <JsonInput
        source="traditionalLimitTimes"
        defaultValue={{
          express: '',
          pickup: '',
          courier: '',
          ['same-day']: '',
        }}
        reactJsonOptions={{
          // Props passed to react-json-view
          name: null,
          collapsed: false,
          enableClipboard: false,
          displayDataTypes: true,
        }}
      />
      <ArrayInput
        source="days"
        defaultValue={[false, false, false, false, false, false, false]}
      >
        <SimpleFormIterator>
          <BooleanInput source="" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);

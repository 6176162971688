import {
  BooleanField,
  DateField,
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import { transformInput } from '../../../lib/utils';

export const ChargeRateEdit = () => (
  <Edit transform={transformInput}>
    <SimpleShowLayout>
      <TextField source="id" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </SimpleShowLayout>
    <SimpleForm>
      <ReferenceInput
        source="ClientId"
        reference="clients"
        sort={{ field: 'name', order: 'ASC' }}
        perPage={10000}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        source="OrderTypeId"
        reference="order-types"
        filter={{ id: [1,2,3,4,6] }}
        sort={{ field: 'name', order: 'ASC' }}
        perPage={10000}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <SelectInput
        source="type"
        choices={[
          { id: 'Order', name: 'Order' },
          { id: 'ShipmentPackage', name: 'ShipmentPackage' },
          { id: 'OrderDetail', name: 'OrderDetail' },
        ]}
        defaultValue={'Order'}
      />
      <BooleanField source="isDefault" />
    </SimpleForm>
  </Edit>
);

import {
  DateField,
  Edit,
  NumberInput,
  SimpleForm,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import { transformInput } from '../../../lib/utils';

export const PointOfSaleProductEdit = () => (
  <Edit transform={transformInput}>
    <SimpleShowLayout>
      <TextField source="id" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </SimpleShowLayout>
    <SimpleForm>
      <NumberInput source="PointOfSaleId" label="PointOfSale Id" />
      <NumberInput source="ProductId" label="Product Id" />
    </SimpleForm>
  </Edit>
);

import { Dispatch } from 'react';

// Interfaces
import { ActionInterface, ContextInterface } from '../interfaces';

export default function GetGlobalContext(
  dispatch: Dispatch<ActionInterface>
): ContextInterface {
  const globalContext: ContextInterface = {
    startLoading: () => {
      dispatch({ type: 'START_LOADING' });
    },
    finishLoading: () => {
      dispatch({ type: 'FINISH_LOADING' });
    },
  };

  return globalContext;
}

import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

interface BackProps {
  home?: boolean;
}

export default function Back({ home }: BackProps) {
  return (
    <div>
      <Link to={home ? '/' : '..'}>
        <Button variant="text">
          <div className="ButtonBack">
            <ChevronLeftIcon />
            Volver
          </div>
        </Button>
      </Link>
    </div>
  );
}

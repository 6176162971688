import {
  Datagrid,
  DateField,
  List,
  NumberField,
  TextField,
  TextInput,
} from 'react-admin';
import { AdminPagination } from '../AdminLayout';

const countryFilters = [<TextInput label="Name" source="name"></TextInput>];

export const CountryList = () => (
  <List filters={countryFilters} pagination={<AdminPagination />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="phoneCode" />
      <TextField source="code" />
      <NumberField source="messageFee" />
      <NumberField source="uberDirectFeeBasePrice" />
      <NumberField source="uberDirectRegularMaxPrice" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);

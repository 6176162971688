import { IModel } from '../../interfaces';

export const posProducts: IModel = {
  name: 'PointOfSaleProducts',
  path: 'api/point-of-sale-products',
  attributes: [
    {
      name: 'PointOfSaleId',
      type: 'number',
      required: true,
    },
    {
      name: 'ProductId',
      type: 'number',
      required: true,
    },
  ],
};

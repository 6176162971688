import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
  TextInput,
} from 'react-admin';
import { AdminPagination } from '../AdminLayout';

const connectionFilters = [<TextInput source="model"></TextInput>];

export const ConnectionList = () => (
  <List filters={connectionFilters} pagination={<AdminPagination />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="model" />
      <ReferenceField source="ConnectionTypeId" reference="connection-types">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="postAlias" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);

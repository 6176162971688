import { useContext, useEffect, useState } from 'react';
import { IClient } from '../../interfaces';
import { GlobalContext } from '../../store';
import CreatePointOfSale from './CreatePointOfSale';
import Files from './Files';
import { capitalize } from '../../lib/utils';
import CreateUser from './CreateUser';
import ClientLogo from './ClientLogo';
import Tokens from './Tokens';
import PublishInventory from './PublishInventory';
import LoadProducts from './LoadProducts';
import VTEXLocal from './VTEXLocal';
import ZapietKey from './ZapietKey';
import CreateClient from './CreateClient';
import OrderReprocess from './OrderReprocess';

const Onboarding = () => {
  const { api } = useContext(GlobalContext);
  const [form, setForm] = useState<
    | 'client'
    | 'pointOfSale'
    | 'files'
    | 'user'
    | 'logo'
    | 'tokens'
    | 'publish-inventory'
    | 'load-products'
    | 'vtex-local'
    | 'zapiet-key'
    | 'order-reprocess'
  >('user');

  const [clients, setClients] = useState<IClient[]>([]);
  const [client, setClient] = useState<IClient | null>(null);

  // Context
  const { context } = useContext(GlobalContext);
  const { startLoading, finishLoading } = context;

  // Load Clients on page load
  useEffect(() => {
    // Set clients and default client
    startLoading();
    api
      .getClients({ active: true })
      .then((response) => {
        setClients(response);
      })
      .finally(() => finishLoading());
  }, []);

  const renderForm = () => {
    if (form === 'user') return <CreateUser />;
    if (form === 'client') return <CreateClient />;
    if (!client) return;
    switch (form) {
      case 'pointOfSale':
        return <CreatePointOfSale client={client} />;
      case 'files':
        return <Files client={client} />;
      case 'logo':
        return <ClientLogo client={client} />;
      case 'tokens':
        return <Tokens client={client} />;
      case 'publish-inventory':
        return <PublishInventory client={client} />;
      case 'load-products':
        return <LoadProducts client={client} />;
      case 'vtex-local':
        return <VTEXLocal client={client} />;
      case 'zapiet-key':
        return <ZapietKey client={client} />;
      case 'order-reprocess':
        return <OrderReprocess client={client} />;
    }
  };

  return (
    <div className="Onboarding">
      <div className="Title">Onboarding</div>
      <form className="OnboardingForm">
        <div className="InputWrapper">
          <div className="InputTitle">Elige la acción</div>
          <select
            name="form"
            onChange={(e) =>
              setForm(
                e.target.value as
                  | 'client'
                  | 'pointOfSale'
                  | 'files'
                  | 'tokens'
                  | 'publish-inventory'
                  | 'load-products'
                  | 'vtex-local'
                  | 'zapiet-key'
                  | 'order-reprocess'
              )
            }
          >
            <option value="user">Crear Usuario</option>
            <option value="client">Crear Cliente</option>
            <option value="pointOfSale">Crear POS</option>
            <option value="files">Header y Footer</option>
            <option value="logo">Logo Cliente</option>
            <option value="tokens">Configuración Tokens</option>
            <option value="publish-inventory">Publicar inventario</option>
            <option value="load-products">Cargar productos</option>
            <option value="vtex-local">VTEX Onboarding Local</option>
            <option value="zapiet-key">Generar llave Zapiet</option>
            <option value="order-reprocess">Reprocesar Orden</option>
          </select>
        </div>
        {!['user', 'client'].includes(form) && (
          <div className="InputWrapper">
            <div className="InputTitle">Cliente</div>
            <select
              name="client"
              onChange={(e) => setClient(clients[parseInt(e.target.value, 10)])}
            >
              <option value="">Selecciona un cliente</option>
              {clients.map((clientOption, idx) => (
                <option value={idx} key={clientOption.id}>
                  {capitalize(clientOption.name)}
                </option>
              ))}
            </select>
          </div>
        )}
        {renderForm()}
      </form>
    </div>
  );
};

export default Onboarding;

import {
  BooleanInput,
  Create,
  NumberInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

export const ClientCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="prefix" />
      <TextInput source="email" />
      <BooleanInput source="canChangeStock" />
      <BooleanInput source="makesDocument" />
      <BooleanInput source="hasNomadFulfillment" />
      <NumberInput source="targetDaysSales" />
      <TextInput source="wmsId" label="WMS ID" />
      <NumberInput source="withdrawalLimit" defaultValue={0} />
      <span>Maximum withdrawals per month</span>
      <NumberInput source="pickupWithdrawalWindow" defaultValue={30} />
      <TextInput source="nid" label="NID" />
      <BooleanInput source="usesWhatsapp" />
      <BooleanInput source="hasB2B" />
      <NumberInput source="tarifierFee" />
      <NumberInput source="centralizationFee" />
      <NumberInput source="packagingFee" />
      <NumberInput source="freeShipmentPrice" />
      <BooleanInput source="hasDynamicPromise" />
      <BooleanInput source="active" defaultValue={true} />
    </SimpleForm>
  </Create>
);

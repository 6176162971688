import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { IClient } from '../../interfaces';
import { GlobalContext } from '../../store';

interface IStatusToggleButtonsProps {
  onChange: (newValue: string[]) => void;
}

export default function ClientSearch({ onChange }: IStatusToggleButtonsProps) {
  const { api } = useContext(GlobalContext);
  const [value, setValue] = useState<string>('all');
  const [clients, setClients] = useState<IClient[]>([]);

  async function fetchClients() {
    const clients = await api.getClients();
    setClients(clients);
  }
  useEffect(() => {
    fetchClients();
  }, []);

  const handleChange = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value;
    setValue(newValue);
    if (newValue === 'all') {
      onChange(clients.map((client) => client.id as unknown as string));
      return;
    }
    onChange([newValue]);
  };

  return (
    <>
      {clients.length > 0 && (
        <FormControl variant="outlined" sx={{ m: 1, padding: 2, width: 250 }}>
          <Select value={value} onChange={handleChange} label="Cliente">
            <MenuItem value="all">Todos los Clientes</MenuItem>
            {clients.map((client) => (
              <MenuItem key={client.id} value={client.id as unknown as string}>
                {client.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
}

import {
  ArrayInput,
  BooleanInput,
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';
import { JsonInput } from 'react-admin-json-view';

export const OpeningHourCreate = () => (
  <Create>
    <SimpleForm>
      <ReferenceInput source="LocalId" reference="locals" perPage={1000} filter={{ active: true }}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="openTime" />
      <TextInput source="closeTime" />
      <JsonInput
        source="limitTimes"
        defaultValue={{
          express: '',
          pickup: '',
          courier: '',
          ['same-day']: '',
        }}
        reactJsonOptions={{
          // Props passed to react-json-view
          name: null,
          collapsed: false,
          enableClipboard: false,
          displayDataTypes: true,
        }}
      />
      <JsonInput
        source="traditionalLimitTimes"
        defaultValue={{
          express: '',
          pickup: '',
          courier: '',
          ['same-day']: '',
        }}
        reactJsonOptions={{
          // Props passed to react-json-view
          name: null,
          collapsed: false,
          enableClipboard: false,
          displayDataTypes: true,
        }}
      />
      <ArrayInput
        source="days"
        defaultValue={[false, false, false, false, false, false, false]}
      >
        <SimpleFormIterator>
          <BooleanInput source="" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);

import { useContext, useEffect, useState, useRef } from 'react';
import {
  IClient,
  IIntegration,
  IPointOfSaleOnboardingBody,
  ICountry,
  IOrderType,
} from '../../interfaces';
import { capitalize, alert } from '../../lib/utils';
import { GlobalContext } from '../../store';

interface PointOfSaleInputsProps {
  integration: string | null;

  // Shopify
  shopifyTokenRef: React.RefObject<HTMLInputElement>;
  shopifyStoreNameRef: React.RefObject<HTMLInputElement>;
  shopifyWebhookSecretRef: React.RefObject<HTMLInputElement>;

  // Woocommerce
  woocommerceTokenRef: React.RefObject<HTMLInputElement>;
  woocommerceSecretRef: React.RefObject<HTMLInputElement>;
  woocommerceStoreUrlRef: React.RefObject<HTMLInputElement>;
  woocommerceWebhookSecretRef: React.RefObject<HTMLInputElement>;

  // Justo
  justoTokenRef: React.RefObject<HTMLInputElement>;
  justoWebhookSecretRef: React.RefObject<HTMLInputElement>;

  // Magento
  magentoStoreNameRef: React.RefObject<HTMLInputElement>;
  magentoUserNameRef: React.RefObject<HTMLInputElement>;
  magentoPasswordRef: React.RefObject<HTMLInputElement>;
  magentoWebhookSecretRef: React.RefObject<HTMLInputElement>;

  // VTEX
  vtexAccountRef: React.RefObject<HTMLInputElement>;
  vtexAppKeyRef: React.RefObject<HTMLInputElement>;
  vtexAppSecretRef: React.RefObject<HTMLInputElement>;
  vtexWebhookSecretRef: React.RefObject<HTMLInputElement>;
}

const PointOfSaleInputs = (
  props: PointOfSaleInputsProps
) => {
  const {
    integration,
    shopifyTokenRef,
    shopifyStoreNameRef,
    shopifyWebhookSecretRef,
    woocommerceTokenRef,
    woocommerceSecretRef,
    woocommerceStoreUrlRef,
    woocommerceWebhookSecretRef,
    justoTokenRef,
    justoWebhookSecretRef,
    magentoStoreNameRef,
    magentoUserNameRef,
    magentoPasswordRef,
    magentoWebhookSecretRef,
    vtexAccountRef,
    vtexAppKeyRef,
    vtexAppSecretRef,
    vtexWebhookSecretRef,
  } = props;

  if (integration === 'shopify') {
    return (
      <>
        <div className="InputWrapper">
          <div className="InputTitle">Token</div>
          <input
            type="text"
            key="shopify-token"
            ref={shopifyTokenRef}
            placeholder="shpat_..."
          />
          <div className="InputDescription">Comienza "shpat_..."</div>
        </div>
        <div className="InputWrapper">
          <div className="InputTitle">Webhook Secret</div>
          <input
            type="text"
            key="shopify-webhook-secret"
            ref={shopifyWebhookSecretRef}
          />
        </div>
        <div className="InputWrapper">
          <div className="InputTitle">Nombre de la tienda</div>
          <input
            type="text"
            key="shopify-store-name"
            ref={shopifyStoreNameRef}
            placeholder="mi-tienda"
          />
          <div className="InputDescription">
            Si el URL de la tienda es "https://
            <strong>mi-tienda</strong>
            .myshopify.com", el valor que debes agregar es "
            <strong>mi-tienda</strong>"
          </div>
        </div>
      </>
    );
  } else if (integration === 'woocommerce') {
    return (
      <>
        <div className="InputWrapper">
          <div className="InputTitle">Token</div>
          <input
            type="text"
            key="woocommerce-token"
            ref={woocommerceTokenRef}
            placeholder="ck_..."
          />
          <div className="InputDescription">Comienza "ck_..."</div>
        </div>
        <div className="InputWrapper">
          <div className="InputTitle">Clave Secreta</div>
          <input
            type="text"
            key="woocommerce-secret"
            ref={woocommerceSecretRef}
            placeholder="cs_..."
          />
          <div className="InputDescription">Comienza "cs_..."</div>
        </div>
        <div className="InputWrapper">
          <div className="InputTitle">URL tienda</div>
          <input
            type="text"
            key="woocommerce-store"
            ref={woocommerceStoreUrlRef}
            placeholder="https://mitienda.com"
          />
          <div className="InputDescription">
            La URL de la tienda: "https://mitienda.com"
            <br />
            <strong>Importante</strong>: no puede contener un "/" al final
          </div>
        </div>
        <div className="InputWrapper">
          <div className="InputTitle">Webhook Secret</div>
          <input
            type="text"
            key="woocommerce-webhook-secret"
            ref={woocommerceWebhookSecretRef}
          />
        </div>
      </>
    );
  } else if (integration === 'justo') {
    return (
      <>
        <div className="InputWrapper">
          <div className="InputTitle">Token</div>
          <input type="text" key="justo-token" ref={justoTokenRef} />
        </div>
        <div className="InputWrapper">
          <div className="InputTitle">Webhook Secret</div>
          <input
            type="text"
            key="justo-webhook-secret"
            ref={justoWebhookSecretRef}
          />
        </div>
      </>
    );
  } else if (integration === 'magento') {
    return (
      <>
        <div className="InputWrapper">
          <div className="InputTitle">Nombre de la tienda</div>
          <input
            type="text"
            key="magento-store-name"
            ref={magentoStoreNameRef}
          />
        </div>
        <div className="InputWrapper">
          <div className="InputTitle">Nombre de Usuario</div>
          <input type="text" key="magento-user-name" ref={magentoUserNameRef} />
        </div>
        <div className="InputWrapper">
          <div className="InputTitle">Contraseña</div>
          <input type="text" key="magento-password" ref={magentoPasswordRef} />
        </div>
        <div className="InputWrapper">
          <div className="InputTitle">Webhook Secret</div>
          <input
            type="text"
            key="magento-webhook-secret"
            ref={magentoWebhookSecretRef}
          />
        </div>
      </>
    );
  } else if (integration === 'vtex') {
    return (
      <>
        <div className="InputWrapper">
          <div className="InputTitle">Nombre de la cuenta</div>
          <input type="text" key="vtex-account" ref={vtexAccountRef} />
        </div>
        <div className="InputWrapper">
          <div className="InputTitle">App Key</div>
          <input type="text" key="vtex-app-key" ref={vtexAppKeyRef} />
        </div>
        <div className="InputWrapper">
          <div className="InputTitle">App Secret</div>
          <input type="text" key="vtex-app-secret" ref={vtexAppSecretRef} />
        </div>
        <div className="InputWrapper">
          <div className="InputTitle">Webhook Secret</div>
          <input
            type="text"
            key="vtex-webhook-secret"
            ref={vtexWebhookSecretRef}
          />
        </div>
      </>
    );
  } else if (integration === 'ubereats') {
    return (
      <>
        <div className="InputWrapper">Configurar POS Locals posteriormente</div>
      </>
    );
  }

  return <></>;
};

const Credentials = ({ client }: { client: IClient }) => {
  const { api, functionsApi } = useContext(GlobalContext);
  const [integrations, setIntegrations] = useState<IIntegration[]>([]);
  const [integration, setIntegration] = useState<IIntegration | null>(null);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [country, setCountry] = useState<ICountry | null>(null);
  const [orderTypes, setOrderTypes] = useState<IOrderType[]>([]);
  const [orderType, setOrderType] = useState<IOrderType | null>(null);

  const resetButtonRef = useRef<HTMLInputElement>(null);

  // Shopify inputs
  const shopifyTokenRef = useRef<HTMLInputElement>(null);
  const shopifyStoreNameRef = useRef<HTMLInputElement>(null);
  const shopifyWebhookSecretRef = useRef<HTMLInputElement>(null);

  // Woocommerce inputs
  const woocommerceTokenRef = useRef<HTMLInputElement>(null);
  const woocommerceSecretRef = useRef<HTMLInputElement>(null);
  const woocommerceStoreUrlRef = useRef<HTMLInputElement>(null);
  const woocommerceWebhookSecretRef = useRef<HTMLInputElement>(null);

  // Justo inputs
  const justoTokenRef = useRef<HTMLInputElement>(null);
  const justoWebhookSecretRef = useRef<HTMLInputElement>(null);

  // Magento inputs
  const magentoStoreNameRef = useRef<HTMLInputElement>(null);
  const magentoUserNameRef = useRef<HTMLInputElement>(null);
  const magentoPasswordRef = useRef<HTMLInputElement>(null);
  const magentoWebhookSecretRef = useRef<HTMLInputElement>(null);

  // VTEX inputs
  const vtexAccountRef = useRef<HTMLInputElement>(null);
  const vtexAppKeyRef = useRef<HTMLInputElement>(null);
  const vtexAppSecretRef = useRef<HTMLInputElement>(null);
  const vtexWebhookSecretRef = useRef<HTMLInputElement>(null);

  // Context
  const { context } = useContext(GlobalContext);
  const { startLoading, finishLoading } = context;

  // Load Data on page load
  useEffect(() => {
    async function loadData() {
      startLoading();
      const integrationsResponse = await api.getIntegrations();
      setIntegrations(integrationsResponse);
      const countriesResponse = await api.getCountries();
      setCountries(countriesResponse);
      const orderTypesResponse = await api.getOrderTypes({filter: {
        name: ['B2B', 'B2C'],
      }});
      setOrderTypes(orderTypesResponse);
      finishLoading();
    }
    loadData();
  }, []);

  // Handle submit based on integration
  async function handleSubmit() {
    if (!client || !integration) {
      alert('error', 'Formulario incompleto');
      return;
    }
    const data: IPointOfSaleOnboardingBody = {
      clientId: client.id,
      integrationId: integration.id,
      countryId: country?.id,
      orderTypeId: orderType?.id,
    };
    switch (integration.name) {
      case 'shopify':
        data.token = shopifyTokenRef.current?.value;
        data.webhookSecret = shopifyWebhookSecretRef.current?.value;
        data.store = shopifyStoreNameRef.current?.value;
        break;
      case 'woocommerce':
        data.token = woocommerceTokenRef.current?.value;
        data.secret = woocommerceSecretRef.current?.value;
        data.store = woocommerceStoreUrlRef.current?.value;
        data.webhookSecret = woocommerceWebhookSecretRef.current?.value;
        break;
      case 'justo':
        data.token = justoTokenRef.current?.value;
        data.webhookSecret = justoWebhookSecretRef.current?.value;
        break;
      case 'magento':
        data.store = magentoStoreNameRef.current?.value;
        data.user = magentoUserNameRef.current?.value;
        data.password = magentoPasswordRef.current?.value;
        data.webhookSecret = magentoWebhookSecretRef.current?.value;
        break;
      case 'vtex':
        data.account = vtexAccountRef.current?.value;
        data.token = vtexAppKeyRef.current?.value;
        data.secret = vtexAppSecretRef.current?.value;
        data.webhookSecret = vtexWebhookSecretRef.current?.value;
        break;
      case 'ubereats':
        break;
      default:
        return;
    }
    try {
      startLoading();
      await functionsApi.onboarding({ type: 'pointOfSale', data });
      finishLoading();
      alert('success', 'El POS fue activado correctamente');
      resetButtonRef.current?.click();
    } catch (error) {
      finishLoading();
      alert('error', `El POS no pudo ser activado: ${error}`);
    }
  }

  return (
    <>
      <div className="InputWrapper">
        <div className="InputTitle">Integración</div>
        <select
          name="integration"
          onChange={(e) =>
            setIntegration(integrations[parseInt(e.target.value, 10)])
          }
        >
          <option value="">Seleccionar...</option>
          {integrations.map((integrationOption, idx) => (
            <option value={idx} key={integrationOption.id}>
              {capitalize(integrationOption.name)}
            </option>
          ))}
        </select>
      </div>
      <div className="InputWrapper">
        <div className="InputTitle">País</div>
        <select
          name="country"
          onChange={(e) => setCountry(countries[parseInt(e.target.value, 10)])}
        >
          <option value="">Seleccionar...</option>
          {countries.map((countryOption, idx) => (
            <option value={idx} key={countryOption.id}>
              {capitalize(countryOption.name)}
            </option>
          ))}
        </select>
      </div>
      <div className="InputWrapper">
        <div className="InputTitle">Tipo de Orden</div>
        <select
          name="orderType"
          onChange={(e) => setOrderType(orderTypes[parseInt(e.target.value, 10)])}
        >
          <option value="">Seleccionar...</option>
          {orderTypes.map((orderTypeOption, idx) => (
            <option value={idx} key={orderTypeOption.id}>
              {capitalize(orderTypeOption.name)}
            </option>
          ))}
        </select>
      </div>
      <PointOfSaleInputs
        integration={integration?.name || null}
        shopifyTokenRef={shopifyTokenRef}
        shopifyStoreNameRef={shopifyStoreNameRef}
        shopifyWebhookSecretRef={shopifyWebhookSecretRef}
        woocommerceTokenRef={woocommerceTokenRef}
        woocommerceSecretRef={woocommerceSecretRef}
        woocommerceStoreUrlRef={woocommerceStoreUrlRef}
        woocommerceWebhookSecretRef={woocommerceWebhookSecretRef}
        justoTokenRef={justoTokenRef}
        justoWebhookSecretRef={justoWebhookSecretRef}
        magentoStoreNameRef={magentoStoreNameRef}
        magentoUserNameRef={magentoUserNameRef}
        magentoPasswordRef={magentoPasswordRef}
        magentoWebhookSecretRef={magentoWebhookSecretRef}
        vtexAccountRef={vtexAccountRef}
        vtexAppKeyRef={vtexAppKeyRef}
        vtexAppSecretRef={vtexAppSecretRef}
        vtexWebhookSecretRef={vtexWebhookSecretRef}
      />
      <input type="reset" ref={resetButtonRef} hidden />
      <div
        className={`Button ${
          !client || !integration || !country ? 'disabled' : ''
        }`}
        onClick={handleSubmit}
      >
        Enviar
      </div>
    </>
  );
};

export default Credentials;

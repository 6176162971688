import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { FormControl, TextField } from '@mui/material';
import Loading from '../Loading';
import { IOrderDetail } from '../../interfaces';
import { Delete } from '@mui/icons-material';

interface OrderDetailNoteProps {
  orderDetail: IOrderDetail | null;
  open: boolean;
  setOpen: (open: boolean) => void;
  onChange: (
    key: string,
    id: string | number | symbol,
    value: number | string
  ) => void;
}

export default function OrderDetailNote({
  orderDetail,
  open,
  setOpen,
  onChange,
}: OrderDetailNoteProps) {
  const [note, setNote] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
    setNote('');
    setLoading(false);
  };

  const handleSubmit = () => {
    setLoading(true);
    onChange(
      (orderDetail as IOrderDetail).ReactComponentId,
      'note',
      note as string
    );
    handleClose();
  };

  const handleDelete = () => {
    setNote('');
    handleSubmit();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="OrderDetailNote">
        <Typography variant="h6" component="h2">
          SKU Producto: {orderDetail?.sku}
        </Typography>
        <Typography variant="h6" component="h2">
          Nombre Producto: {orderDetail?.name}
        </Typography>
        <FormControl className="ModalForm">
          <TextField
            type="text"
            label="Nota"
            onChange={(event) => setNote(event.target.value.substring(0, 255))}
            defaultValue={orderDetail?.note}
          />
          <>
            {loading ? (
              <div>
                <Loading />
              </div>
            ) : (
              <div className="Row Center EditButtons">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Guardar
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleDelete}
                  startIcon={<Delete />}
                >
                  Borrar
                </Button>
                <Button variant="contained" color="info" onClick={handleClose}>
                  Cancelar
                </Button>
              </div>
            )}
          </>
        </FormControl>
      </div>
    </Modal>
  );
}

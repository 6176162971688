import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IProductLocation } from '../../interfaces/IProductLocation';
import { FormControl, FormLabel, Select, TextField } from '@mui/material';
import { useContext } from 'react';
import { GlobalContext } from '../../store';
import Loading from '../Loading';
import { alert } from '../../lib/utils';

interface GenerateMoveProps {
  productLocation: IProductLocation | null;
  open: boolean;
  setOpen: (open: boolean) => void;
  onRefresh: () => void;
}

export default function GenerateMove({
  productLocation,
  open,
  setOpen,
  onRefresh,
}: GenerateMoveProps) {
  const { api } = useContext(GlobalContext);
  const [quantity, setQuantity] = React.useState<number | string>('');
  const [reason, setReason] = React.useState<string>('');
  const [type, setType] = React.useState<string>('');
  const [comment, setComment] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
    setQuantity('');
    setType('');
    setReason('');
    setComment('');
    setLoading(false);
  };

  const handleSubmit = async () => {
    if (!quantity || !reason || !productLocation) {
      return;
    }
    try {
      setLoading(true);
      await api.generateMove({
        ProductLocationId: productLocation?.id,
        quantity: quantity as number,
        type,
        reason,
        comment,
      });
      onRefresh();
    } catch (error) {
      if (error instanceof Error) {
        alert('error', error.message);
      }
    } finally {
      handleClose();
      setLoading(false);
    }
  };

  const handleQuantityChange = (value: string) => {
    if (productLocation && parseInt(value) > productLocation?.onHand && type !== 'add') {
      setQuantity(productLocation?.onHand);
    } else if (parseInt(value) < 0) {
      setQuantity(0);
    } else {
      setQuantity(value === '' ? '' : parseInt(value));
    }
  };

  const getReasonOptions = () => {
    switch (type) {
      case 'add':
        return [
          { value: '', label: 'Seleccionar Razon' },
          { value: 'mistake', label: 'Error de bodega' },
          { value: 'loss-reversed', label: 'Revertido por mala merma' },
          { value: 'reversed', label: 'Revertido por mal ajuste' },
          { value: 'other', label: 'Otro' },
        ];
      case 'remove':
        return [
          { value: '', label: 'Seleccionar Razon' },
          { value: 'mistake', label: 'Error de bodega' },
          { value: 'reversed', label: 'Revertido por mal ajuste' },
          { value: 'other', label: 'Otro' },
        ];
      case 'loss':
        return [
          { value: '', label: 'Seleccionar Razon' },
          { value: 'expired', label: 'Vencido' },
          { value: 'damaged', label: 'Dañado' },
          { value: 'lost', label: 'Perdido' },
          { value: 'other', label: 'Otro' },
        ];
      default:
        return [
          { value: '', label: 'Seleccionar Razon' },
        ];
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="Loss">
        <Typography variant="h6" component="h2">
          Nombre de Ubicacion: {productLocation?.locationName}
        </Typography>
        <Typography variant="h6" component="h2">
          SKU Producto: {productLocation?.sku}
        </Typography>
        <Typography variant="h6" component="h2">
          Nombre Producto: {productLocation?.productName}
        </Typography>
        <FormControl className="ModalForm">
          <FormLabel>Ingresar informacion de perdida</FormLabel>
          <div className="Quantity">
            <TextField
              type="number"
              label="Cantidad"
              value={quantity}
              onChange={(event) => handleQuantityChange(event.target.value)}
            />
            <TextField
              label="Disponible"
              value={productLocation?.onHand}
              disabled={true}
            />
          </div>
          <Select
            native
            value={type}
            onChange={(event) => setType(event.target.value)}
          >
            <option value={''}>Seleccionar Tipo</option>
            <option value={'add'}>Añadir Stock</option>
            <option value={'remove'}>Remover Stock</option>
            <option value={'loss'}>Merma</option>
          </Select>
          <Select
            native
            value={reason}
            onChange={(event) => setReason(event.target.value)}
            disabled={!type}
          >
            {getReasonOptions().map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
          <TextField
            type="text"
            label="Comentario"
            onChange={(event) => setComment(event.target.value)}
          />
          <>
            {loading ? (
              <div>
                <Loading />
              </div>
            ) : (
              <Button type="submit" onClick={handleSubmit}>
                Enviar
              </Button>
            )}
          </>
        </FormControl>
      </div>
    </Modal>
  );
}

import {
  Create,
  DateInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { transformInput } from '../../../lib/utils';

export const ShipmentCreate = () => (
  <Create transform={transformInput}>
    <SimpleForm>
      <NumberInput source="OrderId" />
      <ReferenceInput source="CourierId" reference="couriers">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="externalId" />
      <TextInput source="trackingUrl" />
      <DateInput source="eta" />
      <NumberInput source="fee" />
      <NumberInput source="messageFee" />
      <p>Campos opcionales</p>
      <TextInput source="receiverInfo.name" />
      <TextInput source="receiverInfo.identifier" />
      <TextInput source="receiverInfo.image" />
      <TextInput source="receiverInfo.relationship" />
    </SimpleForm>
  </Create>
);

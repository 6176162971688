import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useEffect, useState } from 'react';
import { IOrderTypeFiltersKey, orderTypeMappingFilters } from '../../lib/orders';
import { IOrderType } from '../../interfaces';

interface IOrderTypeToggleButtonsProps {
  onChange: (newValue: string[]) => void;
  orderTypes: IOrderType[];
}

export default function OrderTypeToggleButtons({
  onChange,
  orderTypes,
}: IOrderTypeToggleButtonsProps) {
  const [value, setValue] = useState<string>('all');

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: 'all' | 'B2C' | 'B2B' | 'TO' | 'WO'
  ) => {
    setValue(newValue);
    onChange(orderTypeMappingFilters[newValue]);
  };

  // Set default value
  useEffect(() => {
    onChange(orderTypeMappingFilters[value as IOrderTypeFiltersKey]);
  }, [orderTypes]);

  return (
    <ToggleButtonGroup
      color="primary"
      value={value}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
    >
      <ToggleButton value="all">Todos</ToggleButton>
      <ToggleButton value="B2C">B2C</ToggleButton>
      <ToggleButton value="B2B">B2B</ToggleButton>
      <ToggleButton value="TO">TO</ToggleButton>
      <ToggleButton value="WO">WO</ToggleButton>
    </ToggleButtonGroup>
  );
}

import {
  DateField,
  Edit,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from 'react-admin';
import { transformInput } from '../../../lib/utils';


export const ShipmentEdit = () => {

  return (
    <Edit transform={transformInput}>
      <SimpleShowLayout>
        <TextField source="id" />
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
        <TextField source="status" />
        <DateField source="eta" />
      </SimpleShowLayout>
      <SimpleForm>
        <NumberInput source="OrderId" />
        <ReferenceInput source="CourierId" reference="couriers">
        <SelectInput
            optionText="name"
          />
        </ReferenceInput>
        <TextInput source="externalId" />
        <TextInput source="trackingUrl" />      
        <NumberInput source="fee" />
        <NumberInput source="messageFee" />
        <p>Campos opcionales</p>
        <TextInput source="receiverInfo.name" />
        <TextInput source="receiverInfo.identifier" />
        <TextInput source="receiverInfo.image" />
        <TextInput source="receiverInfo.relationship" />
        <ReferenceInput source="JourneyId" reference="journeys">
          <SelectInput
              optionText="id"
            />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

import '@aws-amplify/ui-react/styles.css';
import AdminRoutes from './Routes';

// Store
import StoreWrapper from './store/Wrapper';
import { useEffect, useState } from 'react';
import Api from './lib/api';
import FunctionsApi from './lib/functionsApi';
import { Auth } from 'aws-amplify';
import PageLoader from './components/PageLoader';
import { IUser } from './interfaces';
import AccessDenied from './components/AccessDenied';

const App = () => {
  const [api, setApi] = useState<Api>();
  const [functionsApi, setFunctionsApi] = useState<FunctionsApi>();
  const [user, setUser] = useState<IUser>();
  const [accessDenied, setAccessDenied] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      const session = await Auth.currentSession();
      const token = session.getAccessToken().getJwtToken();
      const newApi = new Api(token);
      const newFunctionsApi = new FunctionsApi(token);
      const newUser = await newApi.getUser();
      if (typeof newUser === 'string' && newUser.includes('Access denied')) {
        setAccessDenied(true);
      } else if (typeof newUser !== 'string') {
        setApi(newApi);
        setFunctionsApi(newFunctionsApi);
        setUser(newUser);
      }
    })();
  }, []);

  if (accessDenied) {
    return <AccessDenied />;
  }

  if (!(api && user && functionsApi)) {
    return <PageLoader />;
  }

  return (
    <StoreWrapper api={api} functionsApi={functionsApi} user={user}>
      <AdminRoutes />
    </StoreWrapper>
  );
};

export default App;

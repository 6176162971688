import {
  ArrayInput,
  Create,
  DateTimeInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';

export const ReceptionCreate = () => (
  <Create>
    <SimpleForm>
      <DateTimeInput source="date" />
      <SelectInput
        source="dayTime"
        choices={[
          { id: 'am', name: 'AM' },
          { id: 'pm', name: 'PM' },
        ]}
      />
      <TextInput source="documentNumber" />
      <TextInput source="status" />
      <ArrayInput source="documentUrl" label="Document URLs">
        <SimpleFormIterator inline>
          <TextInput source="" />
        </SimpleFormIterator>
      </ArrayInput>
      <ReferenceInput
        source="ClientId"
        reference="clients"
        sort={{ field: 'name', order: 'ASC' }}
        perPage={1000}
        alwaysOn
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      ,
      <ReferenceInput source="LocalId" reference="locals" filter={{ active: true }}>
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

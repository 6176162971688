import { Heading, useTheme, Flex } from '@aws-amplify/ui-react';

const LoginHeader = () => {
  const { tokens } = useTheme();

  return (
    <Flex justifyContent="center" padding={`0 0 ${tokens.space.medium}`}>
      <Heading level={4} padding={`${tokens.space.xl} ${tokens.space.xl} 0`}>
        Bienvenido
      </Heading>
    </Flex>
  );
};

export default LoginHeader;

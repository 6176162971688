import { Create, NumberInput, SimpleForm, TextInput } from 'react-admin';
import { transformInput } from '../../../lib/utils';

export const SizeCreate = () => (
  <Create transform={transformInput}>
    <SimpleForm>
      <TextInput source="name" />
      <NumberInput source="length" />
      <NumberInput source="depth" />
      <NumberInput source="height" />
      <NumberInput source="weight" />
    </SimpleForm>
  </Create>
);

import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { IClient, ILocal } from '../../interfaces';
import { alert, capitalize } from '../../lib/utils';
import { GlobalContext } from '../../store';

const PublishInventory = ({ client }: { client: IClient }) => {
  const [locals, setLocals] = useState<ILocal[]>([]);
  const [local, setLocal] = useState<ILocal>();

  // Context
  const { context, api } = useContext(GlobalContext);
  const { startLoading, finishLoading } = context;

  // Load Locals
  useEffect(() => {
    // Set clients and default client
    startLoading();
    api
      .getLocals({ filter: { active: true }, rowsPerPage: 1000 })
      .then((response) => {
        setLocals(response);
      })
      .finally(() => finishLoading());
  }, []);

  // Handle submit
  async function handleSubmit() {
    if (!client || !local) {
      alert('error', 'Formulario incompleto');
      return;
    }

    try {
      startLoading();
      await api.publishInventory(client.id, local.id);
      finishLoading();
      alert('success', 'Se ha publicado el inventario correctamente');
    } catch (error) {
      finishLoading();
      if (axios.isAxiosError(error)) {
        alert(
          'error',
          `Ocurrió un error: "${
            (error.response?.data as { message: string }).message
          }"`
        );
      } else {
        alert('error', 'Ocurrió un error: error desconocido');
      }
    }
  }

  return (
    <>
      <div className="InputWrapper">
        <div className="InputTitle">Local</div>
        <select
          name="client"
          onChange={(e) => setLocal(locals[parseInt(e.target.value, 10)])}
        >
          <option value="">Selecciona un local</option>
          {locals.map((local, idx) => (
            <option value={idx} key={local.id}>
              {capitalize(local.name)}
            </option>
          ))}
        </select>
      </div>
      <div
        className={`Button ${!client || !local ? 'disabled' : ''}`}
        onClick={handleSubmit}
      >
        Enviar
      </div>
    </>
  );
};

export default PublishInventory;

import { Create, DateInput, NumberInput, SimpleForm } from 'react-admin';

export const ReceptionDetailCreate = () => (
  <Create>
    <SimpleForm>
      <NumberInput source="amount" />
      <NumberInput source="price" />
      <NumberInput source="received" />
      <NumberInput source="rejected" />
      <DateInput source="expirationDate" />
      <NumberInput source="ReceptionId" label="Reception Id" />
      <NumberInput source="ProductId" label="Product Id" />
    </SimpleForm>
  </Create>
);

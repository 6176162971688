import {
  DateField,
  Edit,
  NumberInput,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  FormDataConsumer,
} from 'react-admin';
import { JsonInput } from 'react-admin-json-view';
import { transformInput } from '../../../lib/utils';
import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../../store';
import { ICountry } from '../../../interfaces';

export const LocalEdit = () => {
  const { api } = useContext(GlobalContext);
  const [regionId, setRegionId] = useState<number | null>(null);
  const [countryId, setCountryId] = useState<number | null>(null);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [intialValuesAlreadySet, setIntialValuesAlreadySet] =
    useState<boolean>(true);

  function setInitialValues(newRegionId: number) {
    if (intialValuesAlreadySet && countries.length > 0) {
      for (let i = 0; i < countries.length; i += 1) {
        const checkCountry = countries[i];
        for (let j = 0; j < checkCountry.Regions.length; j += 1) {
          const checkRegion = checkCountry.Regions[j];
          if (checkRegion?.id === newRegionId) {
            setCountryId(checkCountry.id);
          }
        }
      }
      setRegionId(newRegionId);
      setIntialValuesAlreadySet(false);
    }
  }

  useEffect(() => {
    api.getCountries().then((newCountries) => {
      setCountries(newCountries);
    });
  }, []);

  return (
    <Edit transform={transformInput}>
      <SimpleShowLayout>
        <TextField source="id" />
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
      </SimpleShowLayout>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="email" />
        <JsonInput
          source="address"
          jsonString={false} // Set to true if the value is a string, default: false
          defaultValue={{
            address1: '',
            address2: '',
            commune: '',
          }}
          reactJsonOptions={{
            // Props passed to react-json-view
            name: null,
            collapsed: true,
            enableClipboard: false,
            displayDataTypes: true,
          }}
        />
        <NumberInput source="latitude" />
        <NumberInput source="longitude" />
        <NumberInput source="pickingRate" />
        <BooleanInput source="allowsStacking" />
        <TextInput source="pedidosYaToken" />
        <TextInput source="stockSourceId" />
        <TextInput source="phone" />
        {countryId !== null && (
          <ReferenceInput
            source="CountryId"
            reference="countries"
            sort={{ field: 'name', order: 'ASC' }}
            perPage={100}
          >
            <SelectInput
              defaultValue={countryId}
              onChange={(e) => {
                setCountryId(e.target.value);
                setRegionId(null);
              }}
              optionText="name"
            />
          </ReferenceInput>
        )}
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.Commune.RegionId !== null &&
            (setInitialValues(formData.Commune.RegionId),
            (
              <>
                {countryId !== null && (
                  <ReferenceInput
                    source="RegionId"
                    reference="regions"
                    sort={{ field: 'name', order: 'ASC' }}
                    filter={{ CountryId: countryId }}
                    perPage={10000}
                    {...rest}
                  >
                    <SelectInput
                      defaultValue={regionId}
                      onChange={(e) => {
                        setRegionId(e.target.value);
                      }}
                      optionText="name"
                    />
                  </ReferenceInput>
                )}
                <ReferenceInput
                  source="CommuneId"
                  reference="communes"
                  sort={{ field: 'name', order: 'ASC' }}
                  filter={{ RegionId: regionId }}
                  perPage={10000}
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
              </>
            ))
          }
        </FormDataConsumer>
        <BooleanInput source="active" />
      </SimpleForm>
    </Edit>
  );
};

import {
  Datagrid,
  DateField,
  List,
  NumberField,
  TextField,
  TextInput,
} from 'react-admin';
import { AdminPagination } from '../AdminLayout';

const shipmentFilters = [
  <TextInput source="name"></TextInput>,
];

export const SizeList = () => (
  <List filters={shipmentFilters} pagination={<AdminPagination />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <NumberField source="length" />
      <NumberField source="depth" />
      <NumberField source="height" />
      <NumberField source="weight" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);

import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
} from 'react-admin';
import { AdminPagination } from '../AdminLayout';
import { IOpeningHour } from '../../../interfaces';
import { Chip } from '@mui/material';

const openingHoursFilters = [
  <ReferenceInput source="LocalId" reference="locals" perPage={1000} filter={{ active: true }} alwaysOn>
    <SelectInput optionText="name" />
  </ReferenceInput>,
];

export const OpeningHourList = () => (
  <List filters={openingHoursFilters} pagination={<AdminPagination />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField source="LocalId" reference="locals">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="openingString" label="Days" />
      <FunctionField
        label="Limit times"
        render={(r: IOpeningHour) => (
          <>
            {Object.entries(r.limitTimes).map(([k, v], i) => (
              <Chip key={i} label={`${k}: ${v}`} sx={{ margin: 0.1 }} />
            ))}
          </>
        )}
      />
      <FunctionField
        label="Traditional Limit times"
        render={(r: IOpeningHour) => (
          <>
            {Object.entries(r.traditionalLimitTimes).map(([k, v], i) => (
              <Chip key={i} label={`${k}: ${v}`} sx={{ margin: 0.1 }} />
            ))}
          </>
        )}
      />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);

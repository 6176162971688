import {
  BooleanField,
  Datagrid,
  DateField,
  List,
  NumberField,
  NumberInput,
  TextField,
} from 'react-admin';
import { AdminPagination } from '../AdminLayout';

const chargeRateValueFilters = [
  <NumberInput source="ChargeRateId" alwaysOn></NumberInput>,
];

export const ChargeRateValueList = () => (
  <List filters={chargeRateValueFilters} pagination={<AdminPagination />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <NumberField source="ChargeRateId" />
      <NumberField source="value" />
      <DateField source="startDate" />
      <DateField source="endDate" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <BooleanField source="isScalonated" />
    </Datagrid>
  </List>
);
